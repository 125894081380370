import { SearchBox, Tabs, Tags } from "@clc-v2/uis"
import { useEffect, useRef, useState } from "react"
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { libraryService } from "../../../services/library.service";
import { LibraryVideoWithTag } from "@clc-v2/utilities";
import { VideoCardContainer } from "../../../components/common/video-card-container";
import { LibraryType, LibraryVideoTag } from "@prisma/client";
import { useDraggable } from "react-use-draggable-scroll";

export const LibraryListLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [url_search_params, set_url_search_params] = useSearchParams();
    const { tags } = useAppSelector((state) => state.library);
    const dispatch = useAppDispatch();
    const [search_word, set_search_word] = useState<string>('');
    const [active_tag, set_active_tag] = useState<LibraryVideoTag>();
    const [active_tab, set_active_tab] = useState<string>("webinars");
    const [loading_featured_videos, set_loading_featured_videos] = useState<boolean>(true);
    const [featured_videos, set_featured_videos] = useState<Array<LibraryVideoWithTag & { library: { type: LibraryType } }>>([]);
    const ref = useRef<HTMLElement>() as React.MutableRefObject<HTMLInputElement>;
    const { events } = useDraggable(ref);
    // const update_dashboard_library = (dashboard_library: LibraryType) => {
    //     if (user) {
    //         accountService.updateProfile(user.id, { dashboard_library }).then((_user) => {
    //             dispatch(
    //                 set_user(_user)
    //             )
    //         }).catch((err_msg: string) => {
    //             console.error(err_msg);
    //         });
    //     }
    // }
    useEffect(() => {
        set_loading_featured_videos(true);
        libraryService.getFeaturedVideos().then((_featured_videos) => {
            set_featured_videos(_featured_videos);
        }).catch((err_msg: string) => {
            console.error(err_msg);
        }).finally(() => {
            set_loading_featured_videos(false);
        })
    }, []);
    useEffect(() => {
        const tmp = url_search_params.get('search_word');
        if (tmp) {
            set_search_word(tmp);
        }
    }, [url_search_params.get('search_word')]);
    useEffect(() => {
        if (location.pathname.includes('webinars')) {
            set_active_tab('webinars');
        } else if (location.pathname.includes('lessons')) {
            set_active_tab('lessons');
        } else if (location.pathname.includes('online-sessions')) {
            set_active_tab('online-sessions');
        } else if (location.pathname.includes('quizzes')) {
            set_active_tab('quizzes');
        }
    }, [location.pathname]);
    useEffect(() => {
        const tmp = url_search_params.get('active_tag');
        if (tmp) {
            set_active_tag((tags ?? []).filter(({ id }) => id === tmp)[0]);
        }
    }, [url_search_params.get('active_tag')]);
    return (
        <div className="w-full font-inter">
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-12 mb-4 md:mb-12">
                <div className="col-span-1 py-4 md:py-10">
                    <span className="text-primary-golden text-base font-light leading-[19px] tracking-[2px] uppercase font-inter">STRATEGY RECORDINGS</span>
                    <h1 className="text-white text-5xl font-medium leading-[57px] py-3">Video Library</h1>
                    {/* <p className="text-secondary-grey text-sm font-medium">Search from our library of lessons covering everything from playing and poker to interactions and winning.</p> */}
                    <div className="py-10">
                        <SearchBox
                            search_word={search_word}
                            placeHolder="What are you looking for..."
                            onChangeHandler={(_search_word: string) => new Promise((resolve, reject) => {
                                set_url_search_params((_prev) => {
                                    _prev.set('search_word', _search_word);
                                    return _prev;
                                });
                                setTimeout(() => {
                                    resolve(true);
                                }, 1000);
                            })}
                        />
                    </div>
                    <span className="uppercase text-white text-xs font-light leading-[14px] tracking-[2px] font-inter">Lesson Topics</span>
                    <div className="h-16 overflow-auto thin-scrollbar mt-4" ref={ref} {...events}>
                        {(!tags && (tags ?? []).length === 0) ? (
                            <div className="text-white">No Tags</div>
                        ) : <Tags active_tag={active_tag} tags={tags ?? []} onTagHandler={(_tag) => {
                            if (_tag === active_tag) {
                                set_active_tag(undefined);
                                set_url_search_params((_prev) => {
                                    _prev.delete('active_tag');
                                    return _prev;
                                });
                            } else {
                                set_active_tag(_tag);
                                set_url_search_params((_prev) => {
                                    _prev.set('active_tag', _tag.id);
                                    return _prev;
                                });
                            }
                        }} />}
                    </div>
                </div>
                <div className="col-span-1 md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-12">
                    {loading_featured_videos ? (
                        <div className="text-white">Loading</div>
                    ) : featured_videos.length > 0 ? featured_videos.filter(
                        (video) => true || active_tag === undefined || video.library_video_and_video_tag.filter(({ video_tag_id }) => video_tag_id === active_tag?.id).length > 0
                    ).map((featured_video) => (
                        <div className="col-span-1 flex items-center" key={featured_video.id}>
                            <VideoCardContainer
                                video_card_size="lg"
                                video={featured_video}
                                on_click={() => {
                                    navigate(`/library/detail/webinar?video_id=${featured_video.id}`);
                                }}
                            />
                        </div>
                    )) : (
                        <div className="col-span-2 flex justify-center items-center text-white">No Featured Videos</div>
                    )}
                </div>
            </div>
            <Tabs
                activeValue={active_tab}
                onTabChangeHandler={(new_tab) => new Promise((resolve, reject) => {
                    set_active_tab(new_tab);
                    navigate(`/library/list/${new_tab}${location.search}`);
                    resolve(true);
                })}
                options={[
                    { label: "Webinars", value: "webinars", inner_value: LibraryType.webinar },
                    { label: "Lessons", value: "lessons", inner_value: LibraryType.lesson },
                    { label: "Online Sessions", value: "online-sessions", inner_value: LibraryType.online_session },
                    { label: "Quizzes", value: "quizzes", description: "Join the Chip Leader private Discord channel to discuss the Quizzes and much more!" },
                ]}
            // activeRadioValue={user?.dashboard_library}
            // onClickRadioBoxHandler={(newValue) => new Promise((resolve, reject) => {
            //     update_dashboard_library(newValue as LibraryType)
            //     resolve(true);
            // })}
            />
            <div className="w-full h-10"></div>
            <Outlet />
        </div>
    )
}