import { Account, User } from '@prisma/client';
import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment-timezone';

export interface AuthState {
    user?: User;
    accounts?: Array<Account>;
    accounts_updated_at?: string;
    invited?: User;
    invited_updated_at?: string;
}

// export function isAuthState(obj: any): obj is AuthState {
//     return typeof obj === 'object' &&
//         obj.hasOwnProperty('user') &&
//         obj.hasOwnProperty('accounts') &&
//         obj.hasOwnProperty('accounts_updated_at') &&
//         obj.hasOwnProperty('invited') &&
//         obj.hasOwnProperty('invited_updated_at');
// }

const defaultState: AuthState = {
    accounts: undefined,
    accounts_updated_at: undefined,
    invited: undefined,
    invited_updated_at: undefined,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: defaultState,
    reducers: {
        set_user: (state: AuthState, action: { payload: User }) => {
            state.user = action.payload;
        },
        set_accounts: (state: AuthState, action: { payload: Account[] }) => {
            state.accounts = action.payload;
            state.accounts_updated_at = moment().format('YYYY-MM-DD HH:mm:ss');
        },
        delete_account: (state: AuthState, action: { payload: string }) => {
            state.accounts = state.accounts ? state.accounts.filter(({ id }) => id !== action.payload) : state.accounts;
            state.accounts_updated_at = moment().format('YYYY-MM-DD HH:mm:ss');
        },
        set_invited: (state: AuthState, action: { payload: User }) => {
            state.invited = action.payload;
            state.invited_updated_at = moment().format('YYYY-MM-DD HH:mm:ss');
        },
        update_last_claimed_daily_20_questions: (state: AuthState, action: {
            payload: {
                last_claimed_daily_20_questions: string,
                user_last_claimed_daily_20_questions: string
            }
        }) => {
            if (state.user) {
                state.user = {
                    ...state.user,
                    ...action.payload
                }
            }
        },
        update_last_claimed_weekly_new_hands: (state: AuthState, action: {
            payload: {
                last_claimed_weekly_new_hands: string;
                user_last_claimed_weekly_new_hands: string;
            }
        }) => {
            if (state.user) {
                state.user = {
                    ...state.user,
                    ...action.payload
                }
            }
        },
        update_discord_joint_claim: (state: AuthState, action: {
            payload: {
                discord_joint_claimed: boolean;
            }
        }) => {
            if (state.user) {
                state.user = {
                    ...state.user,
                    ...action.payload
                }
            }
        },
        clear_auth: (state: AuthState) => {
            state = defaultState;
        }
    },
})

export const {
    set_user,
    set_accounts,
    delete_account,
    set_invited,
    update_last_claimed_daily_20_questions,
    update_last_claimed_weekly_new_hands,
    update_discord_joint_claim,
    clear_auth,
} = authSlice.actions
export const authReducer = authSlice.reducer