import { CardVideo } from "@clc-v2/uis"
import { LibraryVideoWithTag } from "@clc-v2/utilities"
import { useAppDispatch, useAppSelector } from "../../stores/hooks"
import { libraryService } from "../../services/library.service"
import { update_library_video_watched } from "../../stores/library"
import { LibraryType } from ".prisma/client"
import { pointSystemService } from "../../services/point-system.service"
import { toast } from "react-toastify"
import { set_lifetime_point } from "../../stores/performance"

export const VideoCardContainer = (props: {
    video_card_size: "md" | "lg";
    hide_label?: boolean;
    video: LibraryVideoWithTag & { library: { type?: LibraryType } };
    on_click?: () => void;
}) => {
    const { tags, library_video_watched } = useAppSelector((state) => state.library);
    const dispatch = useAppDispatch();
    const on_change_watched_handler = (watched: boolean) => {
        libraryService.updateWatched(props.video.id, watched).then((_updated) => {
            dispatch(
                update_library_video_watched(_updated)
            )
        }).catch((err_msg: string) => {
            console.error(err_msg);
        });
    }
    /**
     * @deprecated
     */
    // const on_claim_reward = () => {
    //     pointSystemService.claimLibraryVideoWatchOnce(props.video.id).then((_watched) => {
    //         dispatch(
    //             update_library_video_watched(_watched)
    //         )
    //         if (lifetime_point) {
    //             dispatch(
    //                 set_lifetime_point({
    //                     ...lifetime_point,
    //                     chips: lifetime_point.chips + props.video.reward_chip
    //                 })
    //             )
    //         }
    //     }).catch((err_msg: string) => {
    //         alert(err_msg);
    //     }).finally(() => {

    //     });
    // }
    return (
        <CardVideo
            size={props.video_card_size}
            type={props.hide_label ? undefined : props.video.library.type}
            length={props.video.video_duration}
            tags={(tags ?? []).filter((_tag) => props.video.library_video_and_video_tag.map((video_tag) => video_tag.video_tag_id).includes(_tag.id))}
            title={props.video.title}
            videoThumbnail={props.video.video_thumbnail ?? "https://api.api-ninjas.com/v1/randomimage"}
            watched={(library_video_watched ?? []).filter(({ library_video_id, watched }) => library_video_id === props.video.id && watched).length > 0}
            watched_once={(library_video_watched ?? []).filter(({ library_video_id, watched_once }) => library_video_id === props.video.id && watched_once).length > 0}
            // last_claimed={(library_video_watched ?? []).filter(({ library_video_id }) => library_video_id === props.video.id)?.[0]?.last_claimed}
            difficulty={props.video.difficulty}
            on_change_watched={(watched: boolean) => on_change_watched_handler(watched)}
            on_click={props.on_click}
        // on_claim={on_claim_reward}
        />
    )
}