import { useState } from "react";
import { Topbar } from "@clc-v2/uis";
import { Outlet } from "react-router-dom";

export const Layout1 = () => {
    const [opened, set_opened] = useState<boolean>(false);
    return (
        <div className="w-full min-h-screen py-6 px-10 bg-black-dark font-inter">
            <div className="fixed left-0 top-6 w-full px-10 z-50">
                <Topbar
                    hideMenu
                    centerLogo
                    openHandler={() => set_opened((_prev) => !_prev)}
                    opened={opened}
                />
            </div>
            <div className="mt-20" onClick={() => set_opened(false)}>
                <Outlet />
            </div>
            {/* <Footer opened={false} set_opened={() => { }} /> */}
        </div>
    )
}