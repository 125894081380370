import { LibraryAuthor, LibraryVideo, LibraryVideoTag, LibraryVideoWatched } from '@prisma/client';
import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment';
import { ILibraryVideoWithTagWithAuthor, LibraryAuthorWithUser } from '@clc-v2/utilities';

export interface LibraryState {
    webinars?: ILibraryVideoWithTagWithAuthor[];
    webinars_updated_at?: string;
    lessons?: ILibraryVideoWithTagWithAuthor[];
    lessons_updated_at?: string;
    online_sessions?: ILibraryVideoWithTagWithAuthor[];
    online_sessions_updated_at?: string;
    quizzes?: ILibraryVideoWithTagWithAuthor[];
    quizzes_updated_at?: string;
    tags?: LibraryVideoTag[];
    tags_updated_at?: number;
    library_video_watched?: LibraryVideoWatched[];
    library_authors?: LibraryAuthorWithUser[];
}

// export function isLibraryState(obj: any): obj is LibraryState {
//     return typeof obj === 'object' &&
//         obj.hasOwnProperty('webinars') &&
//         obj.hasOwnProperty('webinars_updated_at') &&
//         obj.hasOwnProperty('lessons') &&
//         obj.hasOwnProperty('lessons_updated_at') &&
//         obj.hasOwnProperty('tags') &&
//         obj.hasOwnProperty('tags_updated_at') &&
//         obj.hasOwnProperty('library_video_watched') &&
//         obj.hasOwnProperty('library_authors');
// }

const defaultState: LibraryState = {
    webinars: undefined,
    webinars_updated_at: undefined,
    lessons: undefined,
    lessons_updated_at: undefined,
    online_sessions: undefined,
    online_sessions_updated_at: undefined,
    quizzes: undefined,
    quizzes_updated_at: undefined,
    tags: undefined,
    tags_updated_at: undefined,
    library_video_watched: undefined,
    library_authors: undefined,
}

export const librarySlice = createSlice({
    name: 'library',
    initialState: defaultState,
    reducers: {
        set_webinars: (state: LibraryState, action: { payload: ILibraryVideoWithTagWithAuthor[] }) => {
            state.webinars = action.payload;
            state.webinars_updated_at = moment().format("YYYY-MM-DD HH:mm:ss");
        },
        set_lessons: (state: LibraryState, action: { payload: ILibraryVideoWithTagWithAuthor[] }) => {
            state.lessons = action.payload;
            state.lessons_updated_at = moment().format("YYYY-MM-DD HH:mm:ss");
        },
        set_online_sessions: (state: LibraryState, action: { payload: ILibraryVideoWithTagWithAuthor[] }) => {
            state.online_sessions = action.payload;
            state.online_sessions_updated_at = moment().format("YYYY-MM-DD HH:mm:ss");
        },
        set_quizzes: (state: LibraryState, action: { payload: ILibraryVideoWithTagWithAuthor[] }) => {
            state.quizzes = action.payload;
            state.quizzes_updated_at = moment().format("YYYY-MM-DD HH:mm:ss");
        },
        set_tags: (state: LibraryState, action: { payload: LibraryVideoTag[] }) => {
            state.tags = action.payload;
            state.tags_updated_at = moment().unix();
        },
        set_library_video_watched: (state: LibraryState, action: { payload: LibraryVideoWatched[] }) => {
            state.library_video_watched = action.payload;
        },
        update_library_video_watched: (state: LibraryState, action: { payload: LibraryVideoWatched }) => {
            if ((state.library_video_watched ?? []).filter((_a) => _a.library_video_id === action.payload.library_video_id).length > 0) {
                state.library_video_watched = (state.library_video_watched ?? []).map((_a) => {
                    if (_a.library_video_id === action.payload.library_video_id) {
                        return action.payload;
                    } else {
                        return _a;
                    }
                })
            } else {
                state.library_video_watched = [...(state.library_video_watched ?? []), action.payload]
            }
        },
        set_library_authors: (state: LibraryState, action: { payload: LibraryAuthorWithUser[] }) => {
            state.library_authors = action.payload;
        },
        clear_library_data: (state: LibraryState) => {
            state = defaultState;
        },
    },
})

export const {
    set_webinars,
    set_lessons,
    set_online_sessions,
    set_quizzes,
    set_tags,
    set_library_video_watched,
    update_library_video_watched,
    set_library_authors,
    clear_library_data,
} = librarySlice.actions
export const libraryReducer = librarySlice.reducer