import { Badge, Button, CalendarPractices, Card, CardArticle, CardCategory, CardEvent, CardVideo, Divider, MarqueeText } from "@clc-v2/uis";
import { useAppDispatch, useAppSelector } from "../stores/hooks";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { IcBookmark, IcFly, IcGraph, IcInfo, IcLeft, IcRight } from "@clc-v2/icons";
import { ProgressChartContainer } from "../components/common/progress-chart-container";
import { LeaderboardContainer } from "../components/common/leaderboard-container";
import { MyPerformanceCardContainer } from "../components/common/my-performance-card-container";
import { historyService } from "../services/history.service";
import { set_new_hand_numbers_of_this_week, set_unchecked_hand_numbers_this_week, set_unplayed_hand_numbers, set_unplayed_hand_numbers_this_week } from "../stores/dashboard";
import { useNavigate } from "react-router-dom";
import { topicLessonQuestionService } from "../services/topic-lesson-question.service";
import { accountService } from "../services/account.service";
import { set_user, update_last_claimed_weekly_new_hands } from "../stores/auth";
import { set_played_history } from "../stores/history";
import lodash from "lodash";
import Products from "../../assets/products.png";
import Lessons from "../../assets/lessons.png";
import Graphs from "../../assets/graph.png";
import Store from "../../assets/store.png";
import ConvertChips from "../../assets/convert-chips.png";
import MyPerformance from "../../assets/my-performance.png";
import { libraryService } from "../services/library.service";
import { LatestArticles, LibraryType, LibraryVideo } from "@prisma/client";
import { ILibraryVideoWithTagWithAuthor, expired } from "@clc-v2/utilities";
import Slider from "react-slick";
import { dataService } from "../services/data.service";
import { toast } from "react-toastify";
import Modal from "react-responsive-modal";
import { set_library_authors, set_tags } from "../stores/library";
import { Tooltip } from 'react-tooltip';
import { pointSystemService } from "../services/point-system.service";
import { LearnMore } from "../components/common/learn-more";

export const Dashboard = () => {
	const { user } = useAppSelector((state) => state.auth);
	// const { subscription } = useAppSelector((state) => state.payment);
	const { prize_slice_texts } = useAppSelector((state) => state.data);
	const { today_played_ai_hand_numbers, unplayed_hand_numbers, unplayed_hand_numbers_updated_at, unplayed_hand_numbers_this_week, unchecked_hand_numbers_this_week, new_hand_numbers_of_this_week } = useAppSelector((state) => state.dashboard);
	const { played_history } = useAppSelector((state) => state.history);
	const { library_authors, tags, tags_updated_at } = useAppSelector((state) => state.library);
	const { count_daily_questions } = useAppSelector((state) => state.data);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [error, set_error] = useState<string>('');
	const [error_new_handed, set_error_new_handed] = useState<string>('');
	const [open_empty_new_hands, set_open_empty_new_hands] = useState<boolean>(false);
	const [active_month, set_active_month] = useState<number>(-1);
	const [active_year, set_active_year] = useState<number>(-1);
	const slider_ref = useRef<Slider>(null);
	const [loading_featured_videos, set_loading_featured_videos] = useState<boolean>(true);
	const [featured_videos, set_featured_videos] = useState<ILibraryVideoWithTagWithAuthor[]>([]);
	const [loading_latest_videos, set_loading_latest_videos] = useState<boolean>(false);
	const [latest_videos, set_latest_videos] = useState<ILibraryVideoWithTagWithAuthor[]>([]);
	// const [latest_webinar_videos, set_latest_webinar_videos] = useState<ILibraryVideoWithTagWithAuthor[]>([]);
	// const [latest_lesson_videos, set_latest_lesson_videos] = useState<ILibraryVideoWithTagWithAuthor[]>([]);
	// const [latest_online_session_videos, set_latest_online_session_videos] = useState<ILibraryVideoWithTagWithAuthor[]>([]);
	const [loading_latest_articles, set_loading_latest_articles] = useState<boolean>(false);
	const [latest_articles, set_latest_articles] = useState<LatestArticles[]>([]);
	const changeMonthHandler = (param: 'prev' | 'next') => {
		return new Promise<boolean>((resolve, reject) => {
			const currentTime = moment().year(active_year).month(active_month);
			if (param === 'prev') {
				const resTime = currentTime.subtract(1, 'month')
				set_active_month(resTime.month());
				set_active_year(resTime.year());
			} else {
				const resTime = currentTime.add(1, 'month')
				set_active_month(resTime.month());
				set_active_year(resTime.year());
			}
			resolve(true);
		})
	}
	const updateLastCheckedAtNewHands = () => {
		accountService.updateLastCheckedAtNewHands().then((_user) => {
			dispatch(
				set_user(_user)
			)
			dispatch(
				set_unchecked_hand_numbers_this_week([])
			)
		}).catch((err_msg: string) => {
			console.error(err_msg);
		});
	}
	/**
	 * @deprecated
	 */
	// const claimDaily20QuestionsReward = lodash.debounce(() => {
	// 	pointSystemService.claimDaily20QuestionsReward().then((data) => {
	// 		dispatch(
	// 			update_last_claimed_daily_20_questions(data)
	// 		)
	// 	}).catch((err_msg: string) => {
	// 		alert(err_msg);
	// 	});
	// }, 500);
	/**
	 * @deprecated
	 */
	// const claimWeeklyNewHands = lodash.debounce(() => {
	// 	pointSystemService.claimWeeklyNewHandsReward().then((data) => {
	// 		dispatch(
	// 			update_last_claimed_weekly_new_hands(data)
	// 		)
	// 	}).catch((err_msg: string) => {
	// 		alert(err_msg);
	// 	});
	// });
	useEffect(() => {
		set_active_month(moment().month());
		set_active_year(moment().year());
	}, []);
	useEffect(() => {
		if (active_year >= 0 && active_month >= 0) {
			historyService.getEarningHistory(
				moment().year(active_year).month(active_month).date(1).format('YYYY-MM-DD'),
				moment().year(active_year).month(active_month + 1).date(1).subtract(1, 'days').format('YYYY-MM-DD'),
			).then((_earning_history) => {
				const _history: Record<string, number[]> = {};
				for (let i = 0; i < _earning_history.length; i++) {
					const date = moment.utc(_earning_history[i].date).local().date();
					if (_history[date]) {
						_history[date].push(_earning_history[i].hand_number)
					} else {
						_history[date] = [_earning_history[i].hand_number]
					}
				}
				dispatch(
					set_played_history({
						history: _history,
						month: active_month,
						year: active_year,
					})
				)
			}).catch((err_msg: string) => {
				console.error(err_msg);
			});
		}
	}, [active_year, active_month]);
	useEffect(() => {
		set_loading_featured_videos(true);
		libraryService.getFeaturedVideos().then((_featured_videos) => {
			set_featured_videos(_featured_videos);
		}).catch((err_msg: string) => {
			console.error(err_msg);
		}).finally(() => {
			set_loading_featured_videos(false);
		})
	}, []);
	useEffect(() => {
		set_loading_latest_videos(true);
		libraryService.getDashboardLatestVideos().then((_latest_videos) => {
			set_latest_videos(_latest_videos);
			// if (_latest_videos[0].library.type === LibraryType.webinar) {
			// 	set_latest_webinar_videos(_latest_videos);
			// } else if (_latest_videos[0].library.type === LibraryType.lesson) {
			// 	set_latest_lesson_videos(_latest_videos);
			// } else if (_latest_videos[0].library.type === LibraryType.online_session) {
			// 	set_latest_online_session_videos(_latest_videos);
			// }
			// for (let i = 0; i < _latest_videos.length; i++) {
			// 	if (_latest_videos[i].library.type === LibraryType.webinar) {
			// 		set_latest_webinar(_latest_videos[i]);
			// 	} else if (_latest_videos[i].library.type === LibraryType.lesson) {
			// 		set_latest_lesson(_latest_videos[i]);
			// 	} else if (_latest_videos[i].library.type === LibraryType.online_session) {
			// 		set_latest_online_session(_latest_videos[i]);
			// 	}
			// }
		}).catch((err_msg: string) => {
			console.error(err_msg);
		}).finally(() => {
			set_loading_latest_videos(false);
		})
	}, []);
	useEffect(() => {
		set_loading_latest_articles(true);
		dataService.getLatestArticles().then((_latest_articles) => {
			set_latest_articles(_latest_articles);
		}).catch((err_msg: string) => {
			console.error(err_msg);
		}).finally(() => {
			set_loading_latest_articles(false);
		})
	}, []);
	useEffect(() => {
		libraryService.getLibraryAuthors().then((_authors) => {
			dispatch(
				set_library_authors(_authors)
			)
		}).catch((err_msg: string) => {
			console.error(err_msg);
		});
	}, []);
	useEffect(() => {
		if (tags_updated_at === undefined || expired(tags_updated_at, 0)) {
			libraryService.getVideoTags().then((_tags) => {
				dispatch(
					set_tags(_tags)
				)
			}).catch((err_msg: string) => {
				console.error(err_msg);
			});
		}
	}, [tags_updated_at]);
	return (
		<div className="w-full min-h-[calc(100vh-80px)] bg-black-dark text-white font-inter">
			<div className="container mx-auto py-6 flex flex-col items-center pt-10">
				{/* <p className="text-primary-golden text-base font-inter font-light">CLC Pocker</p> */}
				<h1 className="text-white text-5xl font-medium font-inter leading-[57px] text-center">Welcome, {user?.user_name}!</h1>

				<div className="w-fit lg:w-full grid grid-cols-1 lg:grid-cols-3 items-center gap-12 mt-10">
					<MyPerformanceCardContainer />
					<div className="w-full flex justify-center items-center gap-6">
						{(() => {
							const remain_cnt_of_questions_for_today = Math.max((count_daily_questions ?? parseInt(process.env.NX_DAILY_QUESTIONS ?? '0')) - (today_played_ai_hand_numbers ?? []).length, 0)
							return (
								<div className="flex flex-col gap-4">
									<Card className="w-40" size="medium">
										<div className="flex flex-col gap-2">
											<div className="text-white text-5xl font-medium font-inter leading-[57px] relative mx-auto">
												{remain_cnt_of_questions_for_today}
												{remain_cnt_of_questions_for_today === (count_daily_questions ?? parseInt(process.env.NX_DAILY_QUESTIONS ?? '0')) &&
													<Badge className="w-10 h-4 absolute -top-1.5 -right-6 transform flex justify-center items-center">
														<p className='font-inter font-bold text-black text-[10px] leading-[12px] tracking-[1px]'>NEW</p>
													</Badge>
												}
												{/* Move to increase the point automatically */}
												{/* {(remain_cnt_of_questions_for_today === 0 && user && (
													user?.user_last_claimed_daily_20_questions === null || (
														user?.user_last_claimed_daily_20_questions && moment(user.user_last_claimed_daily_20_questions).format('YYYY-MM-DD') !== moment().local().format('YYYY-MM-DD')
													))
												) &&
													<Badge className="w-10 h-4 absolute -top-1.5 -right-6 transform flex justify-center items-center">
														<p className='font-inter font-bold text-black text-[10px] leading-[12px] tracking-[1px] cursor-pointer' onClick={claimDaily20QuestionsReward}>Claim</p>
													</Badge>
												} */}
											</div>
											<p className="text-text-op70 font-light text-xs font-inter text-center leading-4 trakcing-[2px]">QUESTIONS<br />FOR TODAY</p>
										</div>
									</Card>
									<Button className="w-full text-xs font-light font-inter" type="primary" onClick={() => {
										navigate('/learning/learn-by-ai');
									}}>
										START NOW
									</Button>
								</div>
							)
						})()}
						{(() => {
							return (
								<div className="flex flex-col gap-4">
									<Card className="w-40" size="medium">
										<div className="flex flex-col gap-2" onClick={updateLastCheckedAtNewHands}>
											<div className="text-white text-5xl font-medium font-inter leading-[57px] relative mx-auto">
												{(unplayed_hand_numbers ?? []).length}
												{(unchecked_hand_numbers_this_week ?? []).length > 0 ? (
													<Badge className="px-1 h-4 absolute -top-1.5 -right-4 flex justify-center items-center cursor-pointer">
														<p className='font-inter font-bold text-black text-[10px] leading-[12px] tracking-[1px]'>{`+${(unchecked_hand_numbers_this_week || []).length}`}</p>
													</Badge>
												) : null}
												{/* Move to increase the point automatically */}
												{/* {(
													(unplayed_hand_numbers_this_week ?? []).length === 0 && (new_hand_numbers_of_this_week ?? []).length > 0 && user && (
														user?.user_last_claimed_weekly_new_hands === null || (
															user?.user_last_claimed_weekly_new_hands && moment(user.user_last_claimed_weekly_new_hands).format('YYYY-MM-DD') !== moment().local().startOf('isoWeek').format('YYYY-MM-DD')
														)
													)
												) && (
														<Badge className="w-10 h-4 absolute -top-1.5 -right-6 transform flex justify-center items-center">
															<p className='font-inter font-bold text-black text-[10px] leading-[12px] tracking-[1px] cursor-pointer' onClick={claimWeeklyNewHands}>Claim</p>
														</Badge>
													)} */}
											</div>
											<p className="text-text-op70 font-light text-xs font-inter text-center leading-4 trakcing-[2px]">UNPLAYED<br />NEW HANDS</p>
										</div>
									</Card>

									<Button className="w-full text-xs font-light font-inter" type="primary" onClick={() => {
										if ((unplayed_hand_numbers ?? []).length > 0) {
											updateLastCheckedAtNewHands();
											navigate('/learning/unplayed-new-handed');
										} else {
											set_open_empty_new_hands(true);
										}
									}}>
										PLAY NOW
									</Button>
								</div>
							)
						})()}
					</div>
					<CalendarPractices
						month={active_month}
						year={active_year}
						nextMonthHandler={() => changeMonthHandler('next')}
						prevMonthHandler={() => changeMonthHandler('prev')}
						todayHands={{
							played: (active_year >= 0 && active_month >= 0) ? lodash.flattenDeep<number>(
								lodash.values(((played_history ?? {})[active_year] ?? {})[active_month] ?? {})
							).length : 0
						}}
						played_history={((played_history ?? {})[active_year] ?? {})[active_month] ?? {}}
					/>
				</div>
			</div>

			<Divider />

			<div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 py-6">
				<div className="col-span-1 flex justify-between items-start">
					<div className="w-full max-w-[326px] flex flex-col gap-6 mx-auto md:mx-0">
						<div className="w-full flex items-start gap-2 p-2">
							<span className="text-white uppercase font-inter font-light text-xs leading-[14px] tracking-[2px]">Newest</span>
							<span className="text-primary-golden uppercase font-inter font-light text-xs leading-[14px] tracking-[2px]">Webinar</span>
						</div>
						{
							(loading_featured_videos) ? (
								<div className="h-80 flex justify-center font-inter font-medium text-md items-center">Loading</div>
							) : (
								<div>
									{featured_videos.length === 1 ? (
										<CardEvent
											date={featured_videos[0].date}
											title={featured_videos[0].title}
											hosts={(library_authors ?? []).filter((author) => [
												featured_videos[0].library.library_author_id_1,
												featured_videos[0].library.library_author_id_2,
												featured_videos[0].library.library_author_id_3,
												featured_videos[0].library.library_author_id_4,
												featured_videos[0].library.library_author_id_5,
											].includes(author.id))}
											onClick={() => navigate(`/library/detail/${featured_videos[0].library.type}?video_id=${featured_videos[0].id}`)}
										/>
									) : (
										<Slider
											ref={slider_ref}
											infinite
											arrows={false}
											autoplay
											autoplaySpeed={5000}
											className="relative"
											prevArrow={
												<button
													onClick={() => slider_ref.current?.slickPrev()}
													className="z-30 absolute top-1/2 left-4 text-white bg-color-5 hover:bg-primary-golden rounded-full w-6 h-6 flex justify-center items-center">
													<IcLeft />
												</button>
											}
											nextArrow={
												<button
													onClick={() => slider_ref.current?.slickNext()}
													className="z-30 absolute top-1/2 right-4 text-white bg-color-5 hover:bg-primary-golden rounded-full w-6 h-6 flex justify-center items-center">
													<IcRight />
												</button>
											}
										>
											{featured_videos.map((featured_video, id) => (
												<CardEvent
													key={id}
													date={featured_video.date}
													title={featured_video.title}
													hosts={(library_authors ?? []).filter((author) => [
														featured_video.library.library_author_id_1,
														featured_video.library.library_author_id_2,
														featured_video.library.library_author_id_3,
														featured_video.library.library_author_id_4,
														featured_video.library.library_author_id_5,
													].includes(author.id))}
													onClick={() => navigate(`/library/detail/${featured_video.library.type}?video_id=${featured_video.id}`)}
												/>
											))}
										</Slider>
									)}
								</div>
							)
						}
						<a href="https://docs.google.com/forms/d/e/1FAIpQLSdp79Dcwol6-toFa8mo1XEeT0RpO2IAISngyVgd69ITRSfGag/viewform" target="_blank">
							<div className="w-full flex items-end gap-6 cursor-pointer rounded-large border-color-3 border-[1px] p-6 transition-all duration-200 hover:bg-primary-golden group">
								<div className="flex flex-col gap-4">
									<p className="text-primary-golden group-hover:text-black font-light text-[12px] leading-[14px] tracking-[2px] uppercase font-inter ">Submit your idea</p>
									<p className="text-secondary-grey group-hover:text-black font-normal text-[11px] leading-[14px] font-inter line-clamp-3">What topic would you like to see covered in a future webinar</p>
								</div>
								<div className="grow-0 shrink-0">
									<IcRight size={12} color='rgba(232, 186, 115, 1)' />
								</div>
							</div>
						</a>
					</div>
					<div className="hidden md:block grow-0 shrink-0 w-[2px] h-[400px] bg-gradient-to-b from-color-4 via-color-3 to-color-4 ml-10" />
				</div>
				<div className="col-span-1 md:col-span-2 flex flex-col gap-2 justify-between">
					<LeaderboardContainer periods={["daily", "weekly", "monthly", "yearly"]} />

					<div className="flex items-center font-inter align-middle px-1 mb-5">
						<a className="cursor-pointer" data-tooltip-id="prize-icon"><IcInfo size={20} /></a>
						<Tooltip id="prize-icon">
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<span>***TO QUALIFY FOR PRIZES - READ HERE***</span>
								<span>*Starting Nov '23, to qualify for any of the prizes listed above, members must be a paying subscriber and one of the following:</span>
								<span>- Monthly subscriber of 4 consecutive months or longer</span>
								<span>- Monthly subscriber after purchasing "The Closer" (Non-Trial Members Only)</span>
								<span>- 6-month or 1-year subscriber</span>
								<span>*If any member wins the 1st prize, they are ineligible from winning another 1st prize for 2 months.</span>
							</div>
						</Tooltip>
						<div className="items-center align-middle">
							<span className="uppercase px-2 font-light text-xs leading-[14px] tracking-[2px]">Prizes</span>
						</div>
						<MarqueeText contents={(prize_slice_texts ?? []).map(({ text }) => text.toUpperCase())} />
					</div>

				</div>
			</div>

			<Divider />

			<div className="container mx-auto mt-6">
				<ProgressChartContainer />
			</div>

			<Divider />

			<div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 py-6">
				<div className="col-span-1">
					<div className="flex items-start gap-2 h-16">
						<span className="text-white text-xs leading-[14px] tracking-[2px] uppercase">Additional</span>
						<span className="text-primary-golden text-xs leading-[14px] tracking-[2px] uppercase">Training</span>
					</div>
					<CardCategory
						bannerImages={[Products]}
						description=""
						icon={<IcBookmark />}
						label=""
						title="Optimize Your Mind is included in your subscription"
						onClickHandler={() => { window.open('https://members.chipleader.com/', '_blank') }}
					/>
				</div>
				<div className="col-span-1" onClick={() => navigate('/learning/pick-your-path')}>
					<div className="flex items-start gap-2 h-16 cursor-pointer" >
						<span className="text-white text-xs leading-[14px] tracking-[2px] uppercase">Pick</span>
						<span className="text-primary-golden text-xs leading-[14px] tracking-[2px] uppercase">Your Path</span>
					</div>
					<CardCategory
						bannerImages={[Lessons]}
						description="Practice the Topics of Your Choice"
						icon={<IcFly />}
						// label="LESSON LIBRARY"
						title="Practice the Lesson of Your Choice"
						onClickHandler={() => { }}
					/>
				</div>
				<div className="col-span-1" onClick={() => {
					navigate('/performance/me')
				}}>
					<div className="flex items-start gap-2 h-16">
						<span className="text-white text-xs font-light font-inter leading-[14px] tracking-[2px] uppercase">My</span>
						<span className="text-primary-golden text-xs font-light font-inter leading-[14px] tracking-[2px] uppercase">Performance</span>
					</div>
					<CardCategory
						bannerImages={[MyPerformance]}
						// description="Use Range Builder to customize your ranges"
						icon={<IcBookmark />}
						// label="FEATURE"
						title="Track your progress and plug your leaks"
						onClickHandler={() => { }}
					/>
				</div>
			</div>
			{
				(loading_latest_videos) ? (
					<div className="container mx-auto flex justify-center items-center py-6">
						<span>Loading</span>
					</div>
				) : (latest_videos.length) > 0 ? (
					<>
						<Divider />
						<div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 py-6">
							{
								latest_videos.length > 0 && (
									<>
										<div className="col-span-1 flex flex-col">
											<div className="flex flex-col my-8">
												<span className="text-primary-golden text-[10px] font-light font-inter leading-[12px] tracking-[2px] uppercase cursor-pointer" onClick={() => navigate('/library/list')}>Video Library</span>
												<h2 className="text-white text-[32px] font-medium font-inter leading-[38px] tracking-[-0.2px]">Watch Our<br />Latest Lessons</h2>
											</div>
											<Button type="primary" onClick={() => navigate('/library/list/lessons')} >
												<p className='font-inter font-light font-xs tracking-widest'>SEE ALL</p>
											</Button>
										</div>
										<div className="col-span-1 md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-12">
											{latest_videos.map((latest_video, id) => (<div className="col-span-1" key={id}>
												<CardVideo
													size="lg"
													type={latest_video.library.type}
													length={latest_video.video_duration}
													tags={(tags ?? []).filter((tag) => latest_video.library_video_and_video_tag.map(({ video_tag_id }) => video_tag_id).includes(tag.id))}
													title={latest_video.title}
													videoThumbnail={latest_video.video_thumbnail}
													on_click={() => navigate(`/library/detail/${latest_video.library.type}?video_id=${latest_video.id}`)}
												/>
											</div>))}
										</div>
									</>
								)
							}
							{/* {
								latest_webinar_videos.length > 0 && (
									<>
										<div className="col-span-1 flex flex-col">
											<div className="flex flex-col my-8">
												<span className="text-primary-golden uppercase text-xs font-light cursor-pointer font-inter" onClick={() => navigate('/library/list')}>Video Library</span>
												<h2 className="text-white text-3xl font-medium font-inter">Watch Our<br />Latest Webinars</h2>
											</div>
											<button className="w-fit px-4 py-2 border border-solid border-primary-golden rounded-full uppercase font-inter" onClick={() => navigate('/library/list/webinars')}>See All Videos</button>
										</div>
										<div className="col-span-1 md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-12">
											{latest_webinar_videos.map((latest_video, id) => (<div className="col-span-1" key={id}>
												<CardVideo
													type={latest_video.library.type}
													length={latest_video.video_duration}
													tags={(tags ?? []).filter((tag) => latest_video.library_video_and_video_tag.map(({ video_tag_id }) => video_tag_id).includes(tag.id))}
													title={latest_video.title}
													videoThumbnail={latest_video.video_thumbnail}
													on_click={() => navigate(`/library/detail/${latest_video.library.type}?video_id=${latest_video.id}`)}
												/>
											</div>))}
										</div>
									</>
								)
							} */}
							{/* {
								latest_lesson_videos.length > 0 && (
									<>
										<div className="col-span-1 flex flex-col">
											<div className="flex flex-col my-8">
												<span className="text-primary-golden uppercase text-xs font-light cursor-pointer font-inter" onClick={() => navigate('/library/list')}>Video Library</span>
												<h2 className="text-white text-3xl font-medium font-inter">Watch Our<br />Latest Lessons</h2>
											</div>
											<button className="w-fit px-4 py-2 border border-solid border-primary-golden rounded-full uppercase font-inter" onClick={() => navigate('/library/list/lessons')}>See All Videos</button>
										</div>
										<div className="col-span-1 md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-12">
											{latest_lesson_videos.map((latest_video, id) => (<div className="col-span-1" key={id}>
												<CardVideo
													type={latest_video.library.type}
													length={latest_video.video_duration}
													tags={(tags ?? []).filter((tag) => latest_video.library_video_and_video_tag.map(({ video_tag_id }) => video_tag_id).includes(tag.id))}
													title={latest_video.title}
													videoThumbnail={latest_video.video_thumbnail}
													on_click={() => navigate(`/library/detail/${latest_video.library.type}?video_id=${latest_video.id}`)}
												/>
											</div>))}
										</div>
									</>
								)
							} */}
							{/* {
								latest_online_session_videos.length > 0 && (
									<>
										<div className="col-span-1 flex flex-col">
											<div className="flex flex-col my-8">
												<span className="text-primary-golden uppercase text-xs font-light cursor-pointer font-inter" onClick={() => navigate('/library/list')}>Video Library</span>
												<h2 className="text-white text-3xl font-medium font-inter">Watch Our<br />Latest Online Sessions</h2>
											</div>
											<button className="w-fit px-4 py-2 border border-solid border-primary-golden rounded-full uppercase font-inter" onClick={() => navigate('/library/list/online-sessions')}>See All Videos</button>
										</div>
										<div className="col-span-1 md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-12">
											{latest_online_session_videos.map((latest_video, id) => (<div className="col-span-1" key={id}>
												<CardVideo
													type={latest_video.library.type}
													length={latest_video.video_duration}
													tags={(tags ?? []).filter((tag) => latest_video.library_video_and_video_tag.map(({ video_tag_id }) => video_tag_id).includes(tag.id))}
													title={latest_video.title}
													videoThumbnail={latest_video.video_thumbnail}
													on_click={() => navigate(`/library/detail/${latest_video.library.type}?video_id=${latest_video.id}`)}
												/>
											</div>))}
										</div>
									</>
								)
							} */}
						</div>
					</>
				) : null
			}
			<Divider />
			<div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 py-6">
				<div className="col-span-1">
					<div className="flex items-start gap-2 h-16">
						<span className="text-white text-xs font-light font-inter leading-[14px] tracking-[2px] uppercase">Chip Leader</span>
						<span className="text-primary-golden text-xs font-light font-inter leading-[14px] tracking-[2px] uppercase">Merch</span>
					</div>
					<CardCategory
						bannerImages={[Store]}
						description=""
						icon={<IcFly />}
						label=""
						title="Shop Custom Gear for Your Next Deep Run"
						onClickHandler={() => { window.open('https://chip-leader-coaching-store.myshopify.com/', '_blank') }}
					/>
				</div>
				<div className="col-span-1" onClick={() => {
					navigate('/account')
				}}>
					<div className="flex items-start gap-2 h-16">
						<span className="text-white text-xs font-light font-inter leading-[14px] tracking-[2px] uppercase">Weekly</span>
						<span className="text-primary-golden text-xs font-light font-inter leading-[14px] tracking-[2px] uppercase">Quizzes</span>
					</div>
					<CardCategory
						bannerImages={[ConvertChips]}
						// description="Customize your learning"
						icon={<IcFly />}
						// label="FEATURE"
						title="Join the discussion on Discord or just check out the videos"
						// upcoming
						onClickHandler={() => { }}
					/>
				</div>
				<div className="col-span-1" onClick={() => window.open('https://clcpoker.com/charts/', '_blank')}>
					<div className="flex items-start gap-2 h-16 cursor-pointer">
						<span className="text-white text-xs leading-[14px] tracking-[2px] uppercase">GTO</span>
						<span className="text-primary-golden text-xs leading-[14px] tracking-[2px] uppercase">RANGES</span>
					</div>
					<CardCategory
						bannerImages={[Graphs]}
						description="See the default GTO ranges and use Range Builder to customize your ranges"
						icon={<IcGraph />}
						// label="GRAPHS"
						title="Range Builder"
						onClickHandler={() => { }}
					/>
				</div>
			</div>
			<Divider />
			<div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 py-6">
				<div className="col-span-1 flex flex-col">
					<div className="flex flex-col my-8">
						<span className="text-primary-golden uppercase text-[10px] leading-[12px] tracking-[2px] font-light">CLC</span>
						<h2 className="text-white text-3xl leading-[38px] tracking-[-0.2px] font-medium">Strategy Articles</h2>
					</div>
					<Button type="primary" onClick={() => window.open('https://clcpoker.com/blog/', '_blank')} >
						<p className='font-inter font-light font-xs tracking-widest'>SEE ALL</p>
					</Button>
				</div>
				<div className="col-span-1 md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-12">
					{
						(loading_latest_articles) ? (
							<div className="col-span-1 md:col-span-2 flex justify-center items-center h-96">Loading</div>
						) : latest_articles.map((latest_article, id) => (
							<CardArticle
								key={id}
								bannerImages={[latest_article.image]}
								date={latest_article.date}
								title={latest_article.title}
								readNowHandler={() => new Promise((resolve, reject) => {
									window.open(latest_article.link, '_blank');
									resolve(true)
								})}
							/>
						))
					}
				</div>
			</div>
			<div className="container mx-auto">
				<LearnMore />
			</div>
			<Modal center showCloseIcon={false} closeOnOverlayClick={true} classNames={{
				modal: "!bg-primary-carbon border border-primary-golden w-96"
			}} open={open_empty_new_hands} onClose={() => set_open_empty_new_hands(false)}>
				<h2 className="text-white text-center">You have completed the new hands.</h2>
			</Modal>
		</div >
	)
}