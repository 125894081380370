import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment-timezone';

export interface DashboardState {
    // accessToken: string;
    today_played_ai_hand_numbers?: Array<number>;
    today_played_ai_hand_numbers_updated_at?: number;
    unplayed_hand_numbers?: Array<number>;
    unplayed_hand_numbers_updated_at?: number;
    unplayed_hand_numbers_this_week?: Array<number>;
    unchecked_hand_numbers_this_week?: Array<number>;
    new_hand_numbers_of_this_week?: Array<number>;
    this_week_played_hand_numbers?: Array<number>;
    this_week_played_hand_numbers_updated_at?: number;
}

// export function isDashboardState(obj: any): obj is DashboardState {
//     return typeof obj === 'object' &&
//         obj.hasOwnProperty('today_played_ai_hand_numbers') &&
//         obj.hasOwnProperty('today') &&
//         obj.hasOwnProperty('unplayed_hand_numbers') &&
//         obj.hasOwnProperty('unplayed_hand_numbers_this_week') &&
//         obj.hasOwnProperty('unplayed_hand_numbers_updated_at');
// }

const defaultState: DashboardState = {
    today_played_ai_hand_numbers: undefined,
    today_played_ai_hand_numbers_updated_at: undefined,
    unplayed_hand_numbers: undefined,
    unplayed_hand_numbers_updated_at: undefined,
    unplayed_hand_numbers_this_week: undefined,
    unchecked_hand_numbers_this_week: undefined,
    new_hand_numbers_of_this_week: undefined,
    this_week_played_hand_numbers: undefined,
    this_week_played_hand_numbers_updated_at: undefined,
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: defaultState,
    reducers: {
        add_ai_hand_number: (state: DashboardState, action: {
            payload: number;
        }) => {
            if (state.today_played_ai_hand_numbers !== undefined) {
                state.today_played_ai_hand_numbers.push(action.payload);
            } else {
                state.today_played_ai_hand_numbers = [action.payload];
            }
            state.today_played_ai_hand_numbers_updated_at = moment().unix();
        },
        set_today_played_ai_hand_numbers: (state: DashboardState, action: {
            payload: number[]
        }) => {
            state.today_played_ai_hand_numbers = action.payload;
            state.today_played_ai_hand_numbers_updated_at = moment().unix();
        },
        remove_unplayed_hand_number: (state: DashboardState, action: {
            payload: number;
        }) => {
            state.unplayed_hand_numbers = (state.unplayed_hand_numbers ?? []).filter((hand_number) => hand_number !== action.payload);
            state.unplayed_hand_numbers_this_week = [];
            state.unplayed_hand_numbers_updated_at = moment().unix();
        },
        set_unplayed_hand_numbers: (state: DashboardState, action: {
            payload: number[]
        }) => {
            state.unplayed_hand_numbers = action.payload;
            state.unplayed_hand_numbers_updated_at = moment().unix();
        },
        set_unplayed_hand_numbers_this_week: (state: DashboardState, action: {
            payload: number[]
        }) => {
            state.unplayed_hand_numbers_this_week = action.payload;
        },
        set_unchecked_hand_numbers_this_week: (state: DashboardState, action: {
            payload: number[]
        }) => {
            state.unchecked_hand_numbers_this_week = action.payload;
        },
        set_new_hand_numbers_of_this_week: (state: DashboardState, action: {
            payload: number[]
        }) => {
            state.new_hand_numbers_of_this_week = action.payload;
        },
        set_this_week_played_hand_numbers: (state: DashboardState, action: {
            payload: number[]
        }) => {
            state.this_week_played_hand_numbers = action.payload;
            state.this_week_played_hand_numbers_updated_at = moment().unix();
        },
        add_this_week_played_hand_numbers: (state: DashboardState, action: {
            payload: number
        }) => {
            if (state.this_week_played_hand_numbers !== undefined) {
                state.this_week_played_hand_numbers.push(action.payload);
            } else {
                state.this_week_played_hand_numbers = [action.payload];
            }
            state.this_week_played_hand_numbers_updated_at = moment().unix();
        }
    },
});

export const {
    add_ai_hand_number,
    set_today_played_ai_hand_numbers,
    remove_unplayed_hand_number,
    set_unplayed_hand_numbers,
    set_unplayed_hand_numbers_this_week,
    set_unchecked_hand_numbers_this_week,
    set_new_hand_numbers_of_this_week,
    set_this_week_played_hand_numbers,
    add_this_week_played_hand_numbers,
} = dashboardSlice.actions
export const dashboardReducer = dashboardSlice.reducer