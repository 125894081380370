import { DailyHistory, EarningHistory } from "@prisma/client";
import axios, { AxiosError } from "axios";
import { API_BACKEND_ENDPOINT } from "../config";
import moment from "moment";

class HistoryService {
    getEarningHistory(from: string, to: string, answer_type?: string) {
        return new Promise<Array<EarningHistory>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/history/earning-history?from=${from}&to=${to}&answer_type=${answer_type}`).then((res: { data: Array<EarningHistory> }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        })
    }
    getMonthlyProgress() {
        const from: string = moment().date(1).format('YYYY-MM-DD'), to: string = moment().add(1, 'month').date(1).subtract(1, 'day').format('YYYY-MM-DD');
        return new Promise<DailyHistory[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/history/daily-history?from=${from}&to=${to}`).then((res: { data: DailyHistory[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    getWeeklyProgress() {
        const from: string = moment().weekday(0).format('YYYY-MM-DD'), to: string = moment().weekday(6).format('YYYY-MM-DD');
        return new Promise<DailyHistory[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/history/daily-history?from=${from}&to=${to}`).then((res: { data: DailyHistory[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
}

export const historyService = new HistoryService();