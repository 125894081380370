import { PokerPlayer } from "@clc-v2/uis";
import { Question } from "@prisma/client";
import { useEffect, useState } from "react";
import { learningService } from "../../services/learning.service";
import { topicLessonQuestionService } from "../../services/topic-lesson-question.service";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useLocation, useSearchParams } from "react-router-dom";
import { remove_unplayed_hand_number } from "../../stores/dashboard";
import classNames from "classnames";
import { accountService } from "../../services/account.service";
import { set_user } from "../../stores/auth";

export const LearnByHandNumber = () => {
	const [searchParams] = useSearchParams();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.auth);
	const { glossaries } = useAppSelector((state) => state.topicLessonQuestion);
	const [hand_number, set_hand_number] = useState<number>(1);
	const [question, set_question] = useState<Question | null>({
		answer_correct: '',
		answer_wrong: [],
		explanation_correct: '',
		explanation_wrong: '',
		hand_history: '',
		hand_number: -1,
		id_v1: '',
		lesson_uid: '',
		new_handed_at: '',
		question: '',
		reward_chip: 0,
		reward_ticket: 0,
		additional_chip: 0,
		subtract_chip: 0,
		video_explanation: '',
		video_link: '',
		blocked_at: null,
	});
	const [error, set_error] = useState<string>('');
	const [link_copied, set_link_copied] = useState<boolean>(false);
	const fetch_question = () => {
		topicLessonQuestionService.getQuestionsByHandNumber(hand_number).then((_question) => {
			set_question(_question);
			set_error('');
		}).catch((err_msg: string) => {
			set_error(err_msg);
			set_question(null);
		})
	}
	const answer_question_handler = (_hand_number: number, correct: boolean) => {
		learningService.answerQuestion(_hand_number, correct, 'by-hand-number').then((success) => {
			dispatch(
				remove_unplayed_hand_number(_hand_number)
			)
		}).catch((err_msg: string) => {
			console.error(err_msg);
		});
	}
	const playing_card_change_handler = (_playing_card: string) => {
		accountService.updatePlayingCard(_playing_card).then((_user) => {
			dispatch(
				set_user(_user)
			)
		}).catch((err_msg: string) => {
			console.error(err_msg);
		});
	}
	useEffect(() => {
		const str_hand_number = searchParams.get('number');
		if (str_hand_number) {
			try {
				set_hand_number(parseInt(str_hand_number));
			} catch (err) {
				console.error(err);
			}
		}
	}, [searchParams.get('number')]);
	useEffect(() => {
		if (link_copied) {
			setTimeout(() => {
				set_link_copied(false)
			}, 2000);
		}
	}, [link_copied]);
	return (
		<div className="container mx-auto flex flex-col items-center gap-4 font-inter">
			<div className="flex justify-center items-center flex-wrap gap-4">
				<span className="text-white font-bold">Enter the hand number you want to study</span>
				<input className="px-4 py-2 bg-secondary-coal text-white border-none outline-none" type="number" value={hand_number} onChange={(e) => set_hand_number(parseInt(e.target.value))} onKeyDown={(e) => e.code.includes('Enter') && fetch_question()} />
				<button className="w-32 bg-primary-golden text-white px-4 py-1 rounded" onClick={fetch_question}>Play</button>
				<CopyToClipboard text={`${window.location.protocol}://${window.location.host}${location.pathname}?number=${hand_number}`} onCopy={() => set_link_copied(true)}>
					{link_copied ? <span className="text-primary-golden">Copied</span> : <span className="text-white">Copy Link</span>}
				</CopyToClipboard>
			</div>
			<span className="text-white">{error}</span>
			<div className={classNames(
				"flex justify-center items-center",
				{
					"min-h-[calc(100vh-80px-48px-72px)]": !error,
					"min-h-[calc(100vh-80px-48px-96px)]": error
				}
			)}>
				{question !== null && (
					<PokerPlayer
						index={question.hand_number}
						data={question}
						dictionary={glossaries ?? {}}
						playing_card={user?.playing_card}
						handle_answer_question={answer_question_handler}
						hide_next_question_btn
						mastered={false}
						handle_change_playing_card={playing_card_change_handler}
					/>
				)}
			</div>
		</div>
	)
}