import { NavLink } from "react-router-dom";
import { useAppSelector } from "../stores/hooks";
import { IcClaiLogo } from "@clc-v2/icons";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { accountService } from "../services/account.service";
import { useMediaQuery } from "react-responsive";
import { IntroVideo } from "../components/common/intro-video";

export const Intro = () => {
    const user = useAppSelector((state) => state.auth.user);
    // const [open_intro_video, set_open_intro_video] = useState<boolean>(false);
    const vimeo_ref = useRef<HTMLIFrameElement>(null);
    const md = useMediaQuery({ query: '(min-width: 768px)' })
    const lg = useMediaQuery({ query: '(min-width: 1024px)' })
    const xl = useMediaQuery({ query: '(min-width: 1280px)' })
    useEffect(() => {
        accountService.updateIntroSeen().then((res) => {
            console.log(res)
        }).catch((err_msg: string) => {
            console.error(err_msg);
        });
    }, []);
    return (
        <div className="min-h-[calc(100vh-80px-48px)] flex flex-col justify-center items-center">
            <div className="fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 font-inter z-10">
                <IcClaiLogo size={xl ? 411 : lg ? 350 : md ? 240 : 200} />
            </div>
            <div className={classNames(
                // 'absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2',
                'w-full max-h-[calc(100vh-112px)] overflow-auto',
                'flex flex-col justify-center items-center',
                'rounded-large',
                'font-inter',
                'relative z-20'
            )}>
                <h1 className='text-lg md:text-3xl text-white font-inter font-medium text-center'>Congratulations! You are in!</h1>
                <h3 className='text-base md:text-xl text-white font-inter font-medium text-center'>Watch the video tutorial below how you can get started with CLAI!</h3>
                <div className="w-full md:w-[768px] h-[350px] md:h-[460px] my-4">
                    <IntroVideo />
                </div>
                <div className='w-full flex justify-center items-center'>
                    {/* <button className={classNames(
                        'h-10',
                        'px-4 flex items-center',
                        'rounded-full',
                        'text-sm text-white font-inter font-light uppercase text-right',
                        'border border-solid border-primary-golden'
                    )} onClick={() => set_open_intro_video(true)}>
                        Watch The Tutorial
                    </button> */}
                    <NavLink to={`/dashboard`} className={classNames(
                        'text-sm text-white font-inter font-light uppercase text-left px-4 md:px-0'
                    )}>
                        Skip To The Dashboard
                    </NavLink>
                </div>
                {/* <Modal classNames={{
                    modal: "!bg-primary-carbon border border-primary-golden w-fit"
                }} center open={open_intro_video} onClose={() => set_open_intro_video(false)}>
                    <div className="w-72 md:w-96 h-64">
                        <iframe
                            src={embedVideo("https://vimeo.com/950552705?share=copy")}
                            width="100%"
                            height="100%"
                            style={{ backgroundColor: '#000' }}
                            allowFullScreen
                        />
                    </div>
                </Modal> */}
            </div>
        </div>
    )
}