import { Button, ClcSpinner, PokerPlayer, Progressbar } from "@clc-v2/uis"
import { Question } from "@prisma/client";
import { Line } from "rc-progress";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { topicLessonQuestionService } from "../../services/topic-lesson-question.service";
import { learningService } from "../../services/learning.service";
import { _PlayPage as PlayPage } from "./_play_page";
import Modal from "react-responsive-modal";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import lodash from "lodash";
import { set_mastered_lessons } from "../../stores/topic-lesson-question";
import { expired } from "@clc-v2/utilities";

export const LearnLesson = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { topics } = useAppSelector((state) => state.topicLessonQuestion);
    const { mastered_lessons, mastered_lessons_updated_at } = useAppSelector((state) => state.topicLessonQuestion);
    const [loading, set_loading] = useState<boolean>(false);
    const [loading_progress, set_loading_progress] = useState<number>(0);
    const [questions, set_questions] = useState<Question[]>([]);
    const [open_continue_modal_with_data, set_open_continue_modal_with_data] = useState<{
        open: boolean;
        correct: number;
        wrong: number;
        next_lesson_uid: string;
    }>({
        open: false,
        correct: 0,
        wrong: 0,
        next_lesson_uid: ''
    });
    const [mastered, set_mastered] = useState<boolean>(false);
    const last_question_checker = (cnt_correct: number, cnt_wrong: number) => {
        const lesson_uid = searchParams.get('lesson_uid');
        if (lesson_uid) {
            const lessons = lodash.flatMap(
                (topics ?? []).map(
                    (topic) => topic.lessons.map((lesson) => ({ lesson_uid: lesson.uid, topic_uid: topic.uid, order: lesson.order })).sort((a, b) => a.order > b.order ? 1 : -1)
                )
            );
            const index = lessons.map(({ lesson_uid }) => lesson_uid).indexOf(lesson_uid);
            if (index < lessons.length - 1) {
                if (lessons[index].topic_uid === lessons[index + 1].topic_uid) {
                    set_open_continue_modal_with_data({
                        open: true,
                        correct: cnt_correct,
                        wrong: cnt_wrong,
                        next_lesson_uid: lessons[index + 1].lesson_uid
                    });
                } else {
                    set_open_continue_modal_with_data({
                        open: true,
                        correct: cnt_correct,
                        wrong: cnt_wrong,
                        next_lesson_uid: ''
                    });
                }
            } else {
                set_open_continue_modal_with_data({
                    open: true,
                    correct: cnt_correct,
                    wrong: cnt_wrong,
                    next_lesson_uid: ''
                });
            }
        }
    }
    const next_lesson_handler = () => {
        set_open_continue_modal_with_data({
            open: false,
            correct: 0,
            wrong: 0,
            next_lesson_uid: ''
        });
        navigate({
            search: `lesson_uid=${open_continue_modal_with_data.next_lesson_uid}`
        });
    }
    useEffect(() => {
        const lesson_uid = searchParams.get('lesson_uid');
        if (lesson_uid) {
            set_loading(true);
            set_loading_progress(0);
            let timer = setInterval(() => {
                set_loading_progress((_prev) => _prev < 70 ? _prev + 1 : 70)
            }, 100);
            topicLessonQuestionService.getAllQuestionsOfLesson(lesson_uid).then((_questions) => {
                set_questions(_questions.sort((a, b) => a.hand_number > b.hand_number ? 1 : -1));
                set_loading_progress(80);
                clearInterval(timer);
                timer = setInterval(() => {
                    set_loading_progress((_prev) => _prev < 100 ? _prev + 1 : 100)
                }, 100)
            }).catch((err_msg: string) => {
            }).finally(() => {
                setTimeout(() => {
                    set_loading(false);
                    clearInterval(timer);
                }, 1000);
            });
        }
    }, [searchParams.get('lesson_uid')]);
    useEffect(() => {
        if (
            mastered_lessons_updated_at === undefined || expired(mastered_lessons_updated_at, 0)
        ) {
            topicLessonQuestionService.getMasteredLessons().then((_mastered_lessons) => {
                dispatch(
                    set_mastered_lessons(_mastered_lessons.map(({ lesson_uid }) => lesson_uid))
                );
            }).catch((err_msg: string) => {
                console.error(err_msg);
            });
        }
    }, [mastered_lessons_updated_at]);
    return (
        <div className="container mx-auto pt-10 relative font-inter min-h-[calc(100vh-80px-48px)] flex justify-center items-center">
            {loading ? (
                <div className='w-full h-[calc(100vh-192px)] text-white flex flex-col justify-center items-center gap-6'>
                    <ClcSpinner text="Loading" />
                    <Line percent={loading_progress} strokeWidth={1} strokeColor="#D3D3D3" trailColor="D9D9D9" />
                </div>
            ) : questions.length > 0 ? (
                <PlayPage questions={questions} last_question_checker={last_question_checker} mastered_uids={mastered_lessons ?? []} />
            ) : (
                <div className="text-white">No Questions</div>
            )}
            <Modal
                center
                showCloseIcon={false}
                closeOnOverlayClick={false}
                classNames={{
                    modal: "!bg-primary-carbon border border-primary-golden w-96"
                }}
                open={open_continue_modal_with_data.open}
                onClose={() => set_open_continue_modal_with_data({ open: false, correct: 0, wrong: 0, next_lesson_uid: '' })}
            >
                <h2 className="text-white">You finished all question of this lesson.</h2>
                <div className="text-white mb-4">
                    <span className="text-green">{open_continue_modal_with_data.correct} Correct</span> | <span className="text-red-500">{open_continue_modal_with_data.wrong} Wrong</span>
                </div>
                <hr className="my-4 border-gray-500" />
                <div className="flex flex-col gap-4">
                    {open_continue_modal_with_data.next_lesson_uid && <Button className="w-full" type="primary" onClick={next_lesson_handler}>Next Lesson</Button>}
                    <Button className="w-full" type="secondary" onClick={() => navigate("/learning/pick-your-path")}>Go to Paths</Button>
                </div>
            </Modal>
        </div>
    )
}