import { CardCourse } from "@clc-v2/uis"
import { Outlet } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../stores/hooks"
import { useEffect, useState } from "react";
import { set_library_authors, set_library_video_watched, set_tags } from "../../stores/library";
import { libraryService } from "../../services/library.service";
import { LearnMore } from "../../components/common/learn-more";

export const LibraryLayout = () => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.auth);
    const [loading_authors, set_loading_authors] = useState<boolean>(true);
    useEffect(() => {
        set_loading_authors(true);
        libraryService.getLibraryAuthors().then((_authors) => {
            dispatch(
                set_library_authors(_authors)
            )
        }).catch((err_msg: string) => {
            console.error(err_msg);
        }).finally(() => {
            set_loading_authors(false);
        });
        libraryService.getVideoTags().then((_tags) => {
            dispatch(
                set_tags(_tags)
            )
        }).catch((err_msg: string) => {
            console.error(err_msg);
        });
    }, []);
    useEffect(() => {
        if (user) {
            libraryService.getWatchedVideos(user.id).then((_library_video_watched) => {
                dispatch(
                    set_library_video_watched(_library_video_watched)
                );
            }).catch((err_msg: string) => {
                console.error(err_msg);
            });
        }
    }, [user]);
    return (
        <div className="container mx-auto font-inter">
            {loading_authors ? (
                <div>Loading</div>
            ) : (
                <Outlet />
            )}
            <LearnMore />
        </div>
    )
}