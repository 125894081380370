import { CardLesson1, CardPerformance, LeaderboardGroup, MarqueeText, Switch, Tabs } from "@clc-v2/uis";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import { useEffect, useState } from "react";
import { ProgressChartContainer } from "../../components/common/progress-chart-container";
import { topicLessonQuestionService } from "../../services/topic-lesson-question.service";
import { set_topics } from "../../stores/topic-lesson-question";
import moment from "moment";
import { set_lesson_progress_status } from "../../stores/performance";
import { LessonWithCntOfQuestions, expired } from "@clc-v2/utilities";
import { LeaderboardContainer } from "../../components/common/leaderboard-container";
import { MyPerformanceCardContainer } from "../../components/common/my-performance-card-container";
import { CACHE_TIME } from "../../config";
import { useNavigate } from "react-router-dom";
import { LearnMore } from "../../components/common/learn-more";

export const MyPerformance = () => {
    const { topics, topics_updated_at } = useAppSelector((state) => state.topicLessonQuestion);
    const { lessons_progress_status } = useAppSelector((state) => state.performance);
    const { prize_slice_texts } = useAppSelector((state) => state.data);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading2, set_loading2] = useState<boolean>(true);
    const [topic_active_tab, set_topic_active_tab] = useState<string>('');
    const [loading3, set_loading3] = useState<boolean>(true);
    const [active_lessons, set_active_lessons] = useState<Array<LessonWithCntOfQuestions>>([]);
    const [error, set_error] = useState<string>('');
    useEffect(() => {
        set_loading2(true);
        if (
            topics_updated_at === undefined || expired(topics_updated_at, 0)
        ) {
            topicLessonQuestionService.getAllTopics().then((_topics) => {
                dispatch(
                    set_topics(_topics)
                );
                if (_topics.length > 0) {
                    set_topic_active_tab(_topics[0].name);
                    set_active_lessons([..._topics[0].lessons].sort((a, b) => a.order - b.order));
                }
            }).catch((err_msg: string) => {

            }).finally(() => {
                setTimeout(() => {
                    set_loading2(false);
                }, 100);
            });
        } else {
            if ((topics ?? []).length > 0) {
                set_topic_active_tab((topics ?? [])[0].name);
                set_active_lessons([...(topics ?? [])[0].lessons].sort((a, b) => a.order - b.order));
            }
            set_loading2(false);
        }
    }, [topics_updated_at]);
    useEffect(() => {
        const lesson_uids_require_update = active_lessons.filter(
            ({ uid }) => lessons_progress_status === undefined || !(lessons_progress_status?.[uid]?.last_updated_at && moment(lessons_progress_status[uid].last_updated_at).diff(moment(), 'minutes') > CACHE_TIME)
        ).map(({ uid }) => uid);
        if (lesson_uids_require_update.length > 0) {
            set_loading3(true);
            topicLessonQuestionService.getLessonProgressStatus(lesson_uids_require_update).then((lesson_progress_status) => {
                dispatch(
                    set_lesson_progress_status(lesson_progress_status)
                );
            }).catch((err_msg: string) => {
                set_error(err_msg);
            }).finally(() => {
                setTimeout(() => {
                    set_loading3(false);
                }, 100);
            });
        } else {
            set_loading3(false);
        }
    }, [active_lessons, lessons_progress_status]);
    return (
        <div className="w-full min-h-[calc(100vh-80px)] bg-black-dark text-white font-inter">
            <div className="mx-auto container grid grid-cols-1 lg:grid-cols-2 pt-10">
                <div className="flex flex-col items-center lg:items-start gap-10 py-10">
                    <div className="w-full flex flex-col gap-3">
                        <span className="text-primary-golden text-base leading-[19px] tracking-[2px] font-light font-inter uppercase">Track Your Progress</span>
                        <h1 className="text-white text-5xl font-medium leading-[57px] font-inter">My Performance</h1>
                        {/* <p className="text-secondary-grey text-sm font-normal leading-[21px] font-inter">Learn about your performance</p> */}
                    </div>
                    <div className="w-full max-w-[472px]">
                        <MyPerformanceCardContainer />
                    </div>
                </div>
                <LeaderboardContainer periods={["weekly", "monthly", "yearly", "lifetime"]} />
            </div>
            <div className="relative h-5 my-4">
                <div className="absolute top-0 -left-4 md:-left-10 w-screen z-30">
                    <MarqueeText
                        contents={(prize_slice_texts ?? []).map(({ text }) => text.toUpperCase())}
                    // contents={[
                    //     "1st - $50 CLC Merch Store voucher & private Coaching Session",
                    //     "2nd & 3rd - $25 CLC Merch Store voucher",
                    //     "4th & 5th - $20 CLC Merch Store voucher"
                    // ]}
                    />
                </div>
            </div>
            {loading2 ? (
                <div className="mx-auto container">Loading</div>
            ) : (
                <div className="mx-auto container">
                    <Tabs
                        activeValue={topic_active_tab}
                        options={(topics ?? []).map((topic) => ({
                            label: topic.name, value: topic.name, description: `${topic.name} has ${topic.lessons.length} lessons.`
                        }))}
                        onTabChangeHandler={(newTab) => new Promise((resolve, reject) => {
                            set_topic_active_tab(newTab);
                            set_active_lessons([...(topics ?? []).filter((topic) => topic.name === newTab)[0].lessons].sort((a, b) => a.order - b.order));
                            resolve(true);
                        })}
                    />
                    {loading3 ? (
                        <div className="w-full h-96 flex justify-center items-center">Loading</div>
                    ) : (
                        error ? (
                            <div className="w-full h-96 flex justify-center items-center text-red-500">{error}</div>
                        ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-6 lg:gap-12 pt-10">
                                {active_lessons.map((lesson, id) => (
                                    <CardLesson1
                                        key={id}
                                        description={lesson.description}
                                        passedLessonCount={(lessons_progress_status ?? {})[lesson.uid]?.passed_hand_numbers?.length ?? 0}
                                        title={`${id + 1}. ${lesson.name}`}
                                        topic={topic_active_tab}
                                        totalLessonCount={lesson._count.questions}
                                        onClickHandler={() => new Promise((resolve, reject) => {
                                            navigate(`/learning/learn-by-lesson?lesson_uid=${lesson.uid}`);
                                            resolve(true);
                                        })}
                                    />
                                ))}
                            </div>
                        )
                    )}
                </div>
            )}
            <div className="mx-auto container mt-10">
                <ProgressChartContainer />
            </div>
            <div className="mx-auto container">
                <LearnMore />
            </div>
        </div>
    )
}