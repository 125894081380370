import { IcCheck, IcDiscord, IcGoogle, IcPlay2 } from "@clc-v2/icons";
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import { Button, CardCbPrice, InputIcon, Tabs } from "@clc-v2/uis";
import { useEffect, useState } from "react";
import { accountService } from "../services/account.service";
import { delete_account, set_accounts, set_invited, set_user } from "../stores/auth";
import classNames from "classnames";
import { Account as IAccount } from "@prisma/client";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { paymentService } from "../services/payment.service";
import { set_item_prices, set_payment_source } from "../stores/payment";
import { AVATARS, embedVideo, ICard, UTMConfigs } from "@clc-v2/utilities";
import { CACHE_TIME, NX_AUTH_DOMAIN, NX_DISCORD_INVITE_LINK, NX_DISCORD_VIP_ROLE, NX_DOMAIN, NX_MICRO_FRONT_DOMAIN } from "../config";
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { toast } from "react-toastify";
import lodash from "lodash";
import Avatar from "react-avatar";
import { Modal } from 'react-responsive-modal';
import CopyToClipboard from "react-copy-to-clipboard";
import { dataService } from "../services/data.service";
import { set_clc_avatars } from "../stores/data";
import { LearnMore } from "../components/common/learn-more";
import { DiscordTutorialVideo } from "../components/common/discord-tutorial-video";

const CONNECTED_ACCOUNTS = "connected-accounts";
const INVITED_BY = "invited_by";
const SECURITY = "security";
const PAYMENT = "payment";

const CLIENT_ID = process.env.NX_DISCORD_CLIENT_ID; // "1204359619745415288";
// const REDIRECT_URI = "http://localhost:4001/api/auth/connect-discord";
const REDIRECT_URI = `${NX_MICRO_FRONT_DOMAIN}/discord-oauth`;
const SCOPE = "identify guilds.join";

export const Account = () => {
    const [url_search_params, set_url_search_params] = useSearchParams();
    const { user, accounts, accounts_updated_at, invited, invited_updated_at } = useAppSelector((state) => state.auth);
    const { item_prices, subscription, payment_source, cb_instance } = useAppSelector((state) => state.payment);
    const { clc_avatars } = useAppSelector((state) => state.data);
    const dispatch = useAppDispatch();
    const [updating_profile, set_updating_profile] = useState<boolean>(false);
    const [open_edit_profile, set_open_edit_profile] = useState<boolean>(false);
    const [user_name, set_user_name] = useState<string>('');
    const [given_name, set_given_name] = useState<string>('');
    const [family_name, set_family_name] = useState<string>('');
    const [email, set_email] = useState<string>('');
    const [picture, set_picture] = useState<{
        path: string;
        thumbnail_path: string;
    }>({
        path: '',
        thumbnail_path: ''
    });
    const [active_profile_tab, set_active_profile_tab] = useState<string>('CONNECTED_ACCOUNTS');
    const [available_accounts] = useState<string[]>([
        'google',
        'discord'
    ]);
    const [loading_vip_role, set_loading_vip_role] = useState<boolean>(false);
    const [utm_configs, set_utm_configs] = useState<UTMConfigs>({});
    const [old_password, set_old_password] = useState<string>('');
    const [new_password, set_new_password] = useState<string>('');
    const [confirm_password, set_confirm_password] = useState<string>('');
    const [err_password, set_err_password] = useState<string>('');
    const [open_discord_tutorial, set_open_discord_tutorial] = useState<boolean>(false);
    const update_profile = () => {
        if (user) {
            set_updating_profile(true);
            accountService.updateProfile(user.id, {
                user_name: user_name,
                given_name: given_name,
                family_name: family_name,
                picture: picture.path,
                thumbnail_picture: picture.thumbnail_path,
            }).then((_user) => {
                dispatch(
                    set_user(_user)
                )
            }).catch((err_msg: string) => {
                console.error(err_msg);
            }).finally(() => {
                set_updating_profile(false);
            });
        }
    }
    const verify_email = () => {
        accountService.verifyEmail().then((success) => {
            if (success) {
                alert(`Sent Verificatin Link to email ${user?.email}`);
            }
        }).catch((err_msg: string) => {
            console.error(err_msg);
        })
    }
    const get_connected_accounts = (callback?: () => void) => {
        accountService.getConnectedAccounts().then((_accounts) => {
            dispatch(
                set_accounts(_accounts)
            )
        }).catch((err_msg: string) => {
            console.error(err_msg);
        }).finally(() => {
            callback?.();
        });
    }
    const disconnect_account = (account_id: string) => {
        accountService.disconnectAccount(account_id).then((success) => {
            dispatch(
                delete_account(account_id)
            )
        }).catch((err_msg: string) => {
            console.error(err_msg);
        });
    }
    const google_login_handler = useGoogleLogin({
        flow: 'implicit',
        onSuccess: async (codeResponse: TokenResponse) => {
            accountService.connectGoogleAccount(codeResponse).then(() => {
                get_connected_accounts();
            }).catch((err_msg: string) => {
                console.error(err_msg);
            });
        },
        onError: errorResponse => console.log(errorResponse),
    });
    const discord_login_handler = () => {
        const width = 600;
        const height = 600;
        const left = window.screenX + (window.outerWidth - width) / 2;
        const top = window.screenY + (window.outerHeight - height) / 2;
        const popup = window.open(
            `https://discord.com/api/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE}`,
            '_blank',
            `width=${width},height=${height},left=${left},top=${top}`
        );
        const receiveMessage = (event: MessageEvent<{ success: true, data: IAccount } | { success: false, message: string }>) => {
            console.log(event)
            if (event.origin === NX_MICRO_FRONT_DOMAIN) {
                if (event.data.success) {
                    const discord_account = event.data.data;
                    const existing_index = (accounts ?? []).findIndex((el) => el.id === discord_account.id);
                    if (existing_index !== -1) {
                        dispatch(
                            set_accounts((accounts ?? []).map((_account) => _account.id === discord_account.id ? discord_account : _account))
                        )
                    } else {
                        dispatch(
                            set_accounts([...(accounts ?? []), discord_account])
                        )
                    }
                    if (popup) {
                        popup.close();
                        window.removeEventListener('message', receiveMessage);
                    }
                } else {
                    toast.error(event.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            }
        };
        window.addEventListener('message', receiveMessage);
    }
    const connect_account = (account: string) => {
        switch (account) {
            case "google":
                google_login_handler();
                break;
            case "discord":
                discord_login_handler();
                break;
        }
    }
    const get_invited_by = () => {
        accountService.getInvited().then((_invited) => {
            if (_invited) {
                dispatch(
                    set_invited(_invited)
                )
            }
        }).catch((err_msg: string) => {
            console.error(err_msg);
        });
    }
    const get_payment_source = (customer_id: string) => {
        paymentService.getPaymentSource(customer_id).then((_payment_source) => {
            dispatch(
                set_payment_source(_payment_source)
            )
        }).catch((err_msg: string) => {
            console.error(err_msg);
        });
    }
    const manage_subscription = () => {
        if (cb_instance) {
            cb_instance.createChargebeePortal().open({
                loaded: () => {

                },
                close: () => {
                    // updateUserProfile();
                },
                paymentSourceAdd: (status: any) => {
                    console.log("payment source add" + status);
                },
                paymentSourceUpdate: (status: any) => {
                    console.log("payment source update" + status);
                },
                paymentSourceRemove: (status: any) => {
                    console.log("payment source removed");
                },
            });
        } else {
            console.error("CB Instance does not exist");
        }
    }
    const update_password = () => {
        set_err_password('');
        if (old_password !== '' && new_password === confirm_password) {
            accountService.updatePassword(old_password, new_password).then((success) => {
                if (success) {
                    set_old_password('');
                    set_new_password('');
                    set_confirm_password('');
                }
            }).catch((err_msg: string) => {
                set_err_password(err_msg);
            })
        } else {
            if (old_password === '') {
                alert('Old password is required');
            } else {
                alert('Not matching confirm password');
            }
        }
    }
    const requestCLCVIP = lodash.debounce(() => {
        set_loading_vip_role(true);
        accountService.updateRole().then((res) => {
            console.log(res);
        }).catch((err_msg: string) => {
            console.error(err_msg);
        }).finally(() => {
            setTimeout(() => {
                accountService.selfAccount(user?.id ?? '').then((_user) => {
                    dispatch(
                        set_user(_user)
                    )
                    set_loading_vip_role(false);
                }).catch((err_msg: string) => {
                    console.error(err_msg);
                    set_loading_vip_role(false);
                })
            }, 3000);
        });
    }, 300);
    /**
     * @deprecated
     */
    // const claimDiscordJoint = lodash.debounce(() => {
    //     pointSystemService.claimDiscordJointReward().then((res) => {
    //         dispatch(
    //             update_discord_joint_claim(res)
    //         )
    //     }).catch((err_msg: string) => {
    //         console.error(err_msg);
    //     });
    // }, 300);
    useEffect(() => {
        if (accounts_updated_at === undefined || moment(accounts_updated_at).diff(moment(), 'minutes') < CACHE_TIME) {
            get_connected_accounts();
        }
        if (invited_updated_at === undefined || moment(invited_updated_at).diff(moment(), 'minutes') < CACHE_TIME) {
            get_invited_by();
        }
    }, []);
    useEffect(() => {
        paymentService.getPriceItems().then((_price_items) => {
            dispatch(
                set_item_prices(_price_items)
            );
        }).catch((err_msg: string) => {
            console.error(err_msg);
        });
        dataService.getCLCAvatars().then((_clc_avatars) => {
            dispatch(
                set_clc_avatars(_clc_avatars)
            )
        }).catch((err_msg: string) => {
            console.error(err_msg);
        });
        dataService.getUtmConfigs().then((_utm_configs) => {
            set_utm_configs(_utm_configs);
        }).catch((err_msg: string) => {
            console.error(err_msg);
        });
    }, []);
    useEffect(() => {
        if (user) {
            set_user_name(user.user_name);
        }
    }, [user?.user_name, open_edit_profile]);
    useEffect(() => {
        if (user) {
            set_given_name(user.given_name ?? '');
        }
    }, [user?.given_name, open_edit_profile]);
    useEffect(() => {
        if (user) {
            set_family_name(user.family_name ?? '');
        }
    }, [user?.family_name, open_edit_profile]);
    useEffect(() => {
        if (user) {
            set_email(user.email);
        }
    }, [user?.email, open_edit_profile]);
    useEffect(() => {
        if (user) {
            set_picture({
                path: user.picture,
                thumbnail_path: user.thumbnail_picture,
            });
        }
    }, [user?.picture, open_edit_profile]);
    useEffect(() => {
        if (subscription?.customer_id) {
            get_payment_source(subscription.customer_id);
        }
    }, [subscription?.customer_id]);
    useEffect(() => {
        const active_tab = url_search_params.get('active_tab');
        if (active_tab && [CONNECTED_ACCOUNTS, INVITED_BY, SECURITY, PAYMENT].includes(active_tab)) {
            set_active_profile_tab(active_tab);
        } else {
            set_active_profile_tab(CONNECTED_ACCOUNTS);
        }
    }, [url_search_params.get('active_tab')]);
    useEffect(() => {
        let timer: NodeJS.Timer;
        if (active_profile_tab === CONNECTED_ACCOUNTS) {
            const updateAccount = () => {
                get_connected_accounts(() => {
                    accountService.getDiscordStatus().then((_user) => {
                        dispatch(
                            set_user(_user)
                        )
                    }).catch((err_msg: string) => {
                        console.error(err_msg);
                    }).finally(() => {
                        timer = setTimeout(updateAccount, 5000);
                    });
                });
            }
            setTimeout(updateAccount, 5000);
        }
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        }
    }, [active_profile_tab]);
    return (
        <div className="container mx-auto text-white pt-10 font-inter">
            <div className="grid grid-cols-5 gap-4">
                <div className="col-span-5 md:col-span-2 flex flex-wrap items-start gap-6 relative">
                    <Button className="absolute right-0 top-0" type="primary" onClick={() => set_open_edit_profile(true)}>
                        Edit
                    </Button>
                    <div className="grow-0 shrink-0 w-32 h-32 relative">
                        <Avatar title="Update Profile" className='cursor-pointer' size="128" name={`${user?.given_name} ${user?.family_name}`} src={user?.picture} round onClick={() => set_open_edit_profile(true)} />{/* should display high-resolution avatar */}
                        {/* <button className="absolute right-2 bottom-2">
                            <IcEdit />
                        </button> */}
                    </div>
                    <div className="w-full grow shrink flex flex-col gap-2">
                        <span className="text-secondary-grey">User Name</span>
                        <span className="w-full text-primary-golden text-3xl line-clamp-2 break-all" title={user?.user_name}>{user?.user_name}</span>
                        <span className="text-secondary-grey">Name</span>
                        <span className="text-primary-golden text-3xl">{user?.given_name} {user?.family_name}</span>
                        <span className="text-secondary-grey">Email</span>
                        <div className="flex items-center gap-2">
                            <span className="text-primary-golden text-xl">{user?.email}</span>
                            {user?.email_verified && <IcCheck />}
                        </div>
                        {!user?.email_verified && <span className="hidden text-white text-xs cursor-pointer" onClick={verify_email}>Verify</span>}
                        {user?.last_login_at && <span className="text-secondary-grey">Last Loged In At {moment(user.last_login_at).format('YYYY-MM-DD HH:mm:ss')}</span>}
                    </div>
                </div>
                <div className="col-span-5 md:col-span-3 ">
                    <Tabs
                        activeValue={active_profile_tab}
                        options={[
                            { label: "Connected Accounts", value: CONNECTED_ACCOUNTS },
                            { label: "Invited By", value: INVITED_BY },
                            { label: "Security", value: SECURITY },
                            { label: "Subscription", value: PAYMENT },
                        ]}
                        onTabChangeHandler={(newTab) => new Promise<boolean>((resolve, reject) => {
                            set_active_profile_tab(newTab);
                            set_url_search_params((_prev) => {
                                _prev.set('active_tab', newTab);
                                return _prev;
                            })
                            resolve(true);
                        })}
                    />
                    {active_profile_tab === CONNECTED_ACCOUNTS && (
                        <div className="w-full flex flex-col gap-4 px-2 py-4">
                            {available_accounts.map((account) => {
                                const connected: IAccount | undefined = accounts ? accounts.filter((_account) => _account.provider === account)?.[0] : accounts;
                                return (
                                    <div className="w-full flex justify-between items-center" key={account}>
                                        <div className="w-6 flex justify-center items-center">
                                            {account === 'google' && <IcGoogle />}
                                            {account === 'discord' && <IcDiscord />}
                                        </div>
                                        <div className="shrink grow ml-4 relative flex items-center gap-6">
                                            <span className={classNames({
                                                "text-primary-golden": connected,
                                                "text-secondary-grey": !connected,
                                            }, "capitalize")}>{account}</span>
                                            {account === 'discord' && (
                                                <button title="Watch the tutorial on how to join the CLAI Discord channel." onClick={() => set_open_discord_tutorial(true)}><IcPlay2 /></button>
                                            )}
                                            {/* Move to increase the point automatically */}
                                            {/* {account === 'discord' && user?.discord_joint && (
                                                <button className={classNames(
                                                    'px-2 py-1 rounded-md z-20',
                                                    {
                                                        'bg-primary-golden': !user?.discord_joint_claimed,
                                                        'bg-green': user?.discord_joint_claimed
                                                    }
                                                )} onClick={(e) => {
                                                    e.stopPropagation();
                                                    claimDiscordJoint();
                                                }}>
                                                    {user?.discord_joint_claimed ? (
                                                        <span className='text-yellow-300'>Claimed</span>
                                                    ) : (
                                                        <span className='text-red-500-'>Claim</span>
                                                    )}
                                                </button>
                                            )} */}
                                        </div>
                                        {(account === 'discord' && connected) ? (
                                            <div className="mr-4 ml-auto">
                                                {user?.discord_joint ? (
                                                    user.discord_role === NX_DISCORD_VIP_ROLE ? (
                                                        <button title="You have joined the CLC private channel">Welcome to the CLC private channel!</button>
                                                    ) : (
                                                        <button disabled={loading_vip_role} title="If you have an active subscription, you will be invited to the CLC private channel." onClick={requestCLCVIP}>Access to the CLC private channel</button>
                                                    )
                                                ) : (
                                                    <button onClick={() => window.open(NX_DISCORD_INVITE_LINK, '_blank')}>Join the CLC Server</button>
                                                )}
                                            </div>
                                        ) : null}
                                        {connected ? <span className={classNames({
                                            "text-primary-golden": !connected,
                                            "text-secondary-grey": connected,
                                        }, "cursor-pointer")} onClick={() => disconnect_account(connected.id)}>Disconnect</span> : <span className={classNames({
                                            "text-primary-golden": !connected,
                                            "text-secondary-grey": connected,
                                        }, "cursor-pointer")} onClick={() => connect_account(account)}>Connect</span>}
                                    </div>
                                )
                            })}
                        </div>
                    )}
                    {active_profile_tab === INVITED_BY && (
                        <div className="px-2 py-4">
                            {invited?.email ? <p className="text-white py-6">You are invited by {invited.email}</p> : null}
                            <div className="flex items-start gap-4">
                                <CopyToClipboard text={`${NX_AUTH_DOMAIN}/auth?tab=register${utm_configs.utm_source ? `&utm_source=${utm_configs.utm_source}` : ''}${utm_configs.utm_source ? `&utm_medium=${utm_configs.utm_medium}` : ''}${utm_configs.utm_campaign ? `&utm_campaign=${utm_configs.utm_campaign}` : ''}${utm_configs.utm_content ? `&utm_content=${utm_configs.utm_content}` : ''}${utm_configs.utm_term ? `&utm_term=${utm_configs.utm_term}` : ''}&referer_email=${user?.email}`} onCopy={() => toast.success('Copied Invite Link successfully')}>
                                    <button className="flex items-center gap-2 px-4 py-2 rounded-full border">
                                        <span>Copy Your Invite Link</span>
                                    </button>
                                </CopyToClipboard>
                                {/* <div className="flex flex-wrap gap-4">
                                    <CopyToClipboard text={`${NX_AUTH_DOMAIN}/auth?tab=register&utm_source=linkedin&utm_medium=linkedin&utm_campaign=linkedin&utm_content=linkedin&utm_term=linkedin&referer_email=${user?.email}`} onCopy={() => toast.success('Copied Invite Link successfully')}>
                                        <button className="flex items-center gap-2 px-4 py-2 rounded-full border">
                                            <span>LinkedIn</span>
                                            <IcLinkedin />
                                        </button>
                                    </CopyToClipboard>

                                    <CopyToClipboard text={`${NX_AUTH_DOMAIN}/auth?tab=register&utm_source=twitter&utm_medium=twitter&utm_campaign=twitter&utm_content=twitter&utm_term=twitter&referer_email=${user?.email}`} onCopy={() => toast.success('Copied Invite Link successfully')}>
                                        <button className="flex items-center gap-2 px-4 py-2 rounded-full border">
                                            <span>Twitter</span>
                                            <IcTwitter />
                                        </button>
                                    </CopyToClipboard>
                                </div> */}
                            </div>
                            <p className="px-4 py-2">
                                Invite your friends and family to earn bonus points and enjoy one month free!
                            </p>
                        </div>
                    )}
                    {active_profile_tab === SECURITY && (
                        <div className="w-full flex flex-col gap-2 px-2 py-4">
                            <div className="flex items-center gap-4">
                                <span className="w-40">Old Password</span>
                                <InputIcon className="shrink grow" type="password" value={old_password} onChange={(e) => set_old_password(e.target.value)} placeholder="Old Password" surfix={<IcCheck />} />
                            </div>
                            <div className="flex items-center gap-4">
                                <span className="w-40">New Password</span>
                                <InputIcon className="shrink grow" type="password" value={new_password} onChange={(e) => set_new_password(e.target.value)} placeholder="New Password" surfix={<IcCheck />} />
                            </div>
                            <div className="flex items-center gap-4">
                                <span className="w-40">Confirm Password</span>
                                <InputIcon className="shrink grow" type="password" value={confirm_password} onChange={(e) => set_confirm_password(e.target.value)} placeholder="Confirm Password" surfix={<IcCheck />} />
                            </div>
                            {err_password && <p className="w-full text-red-400 text-center">{err_password}</p>}
                            <div className="w-full flex justify-center mt-2">
                                <Button onClick={update_password} type="primary">
                                    Update Password
                                </Button>
                            </div>
                        </div>
                    )}
                    {active_profile_tab === PAYMENT && (
                        item_prices && (
                            item_prices.length > 0 ? (
                                <>
                                    < div className="w-full flex flex-col gap-4 px-2 py-4">
                                        <div className="w-80 mx-auto">
                                            {item_prices.filter((item_price) => subscription && (subscription.subscription_items.filter(({ item_price_id }) => item_price_id === item_price.id).length > 0)).map((item_price) => (
                                                <CardCbPrice
                                                    key={item_price.id}
                                                    item_price_id={item_price.id}
                                                    price={item_price.price}
                                                    currency_code={item_price.currency_code}
                                                    name={item_price.external_name}
                                                    description={item_price.description}
                                                    selected_or_upgrade={true}
                                                    scheduled={subscription?.scheduled_item_price_id === item_price.id}
                                                    status={subscription?.status}
                                                    trial_end={subscription?.trial_end}
                                                    next_billing_at={subscription?.next_billing_at}
                                                    canceled_at={subscription?.canceled_at}
                                                    cancel_schedule_created_at={subscription?.cancel_schedule_created_at}
                                                    self_description={item_price.self_description}
                                                    monthly_price={item_price.monthly_price}
                                                    monthly_price_full={item_price.monthly_price_full}
                                                    saved_money_text={item_price.saved_money_text}
                                                    bill_text={item_price.bill_text}
                                                    tip={item_price.tip}
                                                    on_reactivate={subscription ? () => paymentService.reactivateSubscription(subscription?.customer_id, subscription?.id) : undefined}
                                                />
                                            ))}
                                        </div>
                                        <div className="flex justify-center items-center gap-6">
                                            {/* {payment_source && <div>
                                                <Cards
                                                    number={(payment_source.card as unknown as ICard).masked_number}
                                                    expiry={`${(payment_source.card as unknown as ICard).expiry_month}/${(payment_source.card as unknown as ICard).expiry_year}`}
                                                    cvc={"***"}
                                                    name={(user?.given_name || user?.family_name) ? `${user?.given_name} ${user?.family_name}` : ''}
                                                    focused={"expiry"}
                                                    issuer={(payment_source.card as unknown as ICard).brand}
                                                    placeholders={{ name: "Holder Name" }}
                                                    preview
                                                />
                                            </div>} */}
                                            <Button type="primary" onClick={manage_subscription}>
                                                Manage or Cancel Subscription
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="col-span-3 flex flex-col justify-center items-center py-4">
                                    <p>CLC Plans are not registered to database</p>
                                </div>
                            )
                        )
                    )}
                </div>
            </div>
            <LearnMore />
            <Modal center showCloseIcon={true} classNames={{
                modal: "!bg-primary-carbon border border-primary-golden"
            }} open={open_edit_profile} onClose={() => set_open_edit_profile(false)}>
                <h2 className="text-white mb-4">Edit Profile</h2>
                <div className="w-full flex items-center gap-4 mt-2">
                    <label htmlFor="USER_NAME" className="text-white grow-0 shrink-0">User Name*</label>
                    <input id="USER_NAME" className="grow shrink px-4 py-2 rounded" type="text" placeholder="User Name" value={user_name} onChange={(e) => set_user_name(e.target.value)} />
                </div>
                <div className="w-full flex items-center gap-4 mt-2">
                    <label htmlFor="GIVEN_NAME" className="text-white grow-0 shrink-0">First Name*</label>
                    <input id="GIVEN_NAME" className="grow shrink px-4 py-2 rounded" type="text" placeholder="First Name(Optional)" value={given_name} onChange={(e) => set_given_name(e.target.value)} />
                </div>
                <div className="w-full flex items-center gap-4 mt-2">
                    <label htmlFor="FAMILY_NAME" className="text-white grow-0 shrink-0">Last Name*</label>
                    <input id="FAMILY_NAME" className="grow shrink px-4 py-2 rounded" type="text" placeholder="Last Name(Optional)" value={family_name} onChange={(e) => set_family_name(e.target.value)} />
                </div>
                <div className="mt-2">
                    <label htmlFor="PICTURE" className="text-white">Avatar</label>
                    <div className="max-h-[640px] grid grid-cols-8 gap-4 mt-2 overflow-auto">
                        {([...AVATARS(NX_DOMAIN).map((avatar) => ({ name: null, path: avatar, thumbnail_path: avatar })), ...(clc_avatars ?? [])]).map((avatar, id) => (
                            <div key={id} className={classNames(
                                "w-full flex justify-center items-center",
                                {
                                    "bg-primary-golden": avatar.path === picture.path
                                }
                            )} onClick={() => set_picture(avatar)}>
                                <img className="w-16 h-16 rounded-full overflow-hidden" src={avatar.thumbnail_path} alt={avatar.name ?? ''} />
                            </div>
                        ))}
                    </div>
                </div>
                <hr className="my-4 border-gray-500" />
                <div className="flex justify-between items-center">
                    <Button type="secondary" onClick={() => set_open_edit_profile(false)}>Cancel</Button>
                    <Button type="primary" onClick={update_profile}>{updating_profile ? 'Updating' : 'Update'}</Button>
                </div>
            </Modal>
            <Modal classNames={{
                modal: "!bg-primary-carbon border border-primary-golden"
            }} center open={open_discord_tutorial} onClose={() => set_open_discord_tutorial(false)} showCloseIcon={false}>
                <div className="w-full md:w-[748px] h-[350px] md:h-[460px] max-h-[calc(100vh-4.8rem)]">
                    <DiscordTutorialVideo />
                </div>
            </Modal>
        </div >
    )
}