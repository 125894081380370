import { createSlice } from '@reduxjs/toolkit'

export interface HistoryState {
    // accessToken: string;
    played_history?: {
        [year: number]: {
            [month: number]: {
                [day: number]: number[]
            }
        }
    }; // { 2023: { 12: { 31: [1,2,3,4] } } }
}

// export function isHistoryState(obj: any): obj is HistoryState {
//     return typeof obj === 'object' &&
//         obj.hasOwnProperty('played_history');
// }

const defaultState: HistoryState = {
    played_history: undefined,
}

export const historySlice = createSlice({
    name: 'history',
    initialState: defaultState,
    reducers: {
        set_played_history: (state: HistoryState, action: {
            payload: {
                year: number,
                month: number,
                history: { [day: number]: number[] },
            }
        }) => {
            state.played_history = {
                ...(state.played_history ?? {}),
                [action.payload.year]: {
                    ...(state.played_history ? (state.played_history[action.payload.year] ?? {}) : {}),
                    [action.payload.month]: {
                        ...((state.played_history ? (state.played_history[action.payload.year] ?? {}) : {})[action.payload.month] ?? {}),
                        ...action.payload.history,
                    }
                }
            }
        },
    },
})

export const {
    set_played_history
} = historySlice.actions
export const historyReducer = historySlice.reducer