import axios, { AxiosError } from "axios";
import { API_BACKEND_ENDPOINT } from "../config";
import { CBItemPrice, CBPaymentSource, CBSubscription, CBSubscriptionAndItemPrice } from "@prisma/client";
import {
    HostedPage,
} from 'chargebee-typescript/lib/resources';
import { ISubscriptionWithSubscriptionItems } from "@clc-v2/utilities";

class PaymentService {
    async manageSubscription() {
        return new Promise<string>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/cb-payment/manage-subscription`).then((res: { data: string }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getCurrentSubscription() {
        return new Promise<ISubscriptionWithSubscriptionItems>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/cb-payment/current-subscription`).then((res: { data: ISubscriptionWithSubscriptionItems }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getPriceItems() {
        return new Promise<CBItemPrice[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/cb-payment/item-prices`).then((res: { data: CBItemPrice[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async generateCheckoutUrl(item_price_id: string, user_id: string, coupon_id?: string | null) {
        return new Promise<HostedPage>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/cb-payment/generate-checkout-newurl`, {
                user_id: user_id,
                item_price_id: item_price_id,
                coupon_id: coupon_id,
            }).then((res: { data: HostedPage }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getPaymentSource(customer_id: string) {
        return new Promise<CBPaymentSource>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/cb-payment/get-primary-payment-source?customer_id=${customer_id}`).then((res: { data: CBPaymentSource }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async reactivateSubscription(customer_id: string, subscription_id: string) {
        return new Promise<number>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/cb-payment/reactivate-subscription`, {
                customer_id,
                subscription_id
            }).then((res: { data: number }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
}

export const paymentService = new PaymentService();
