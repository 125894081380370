import axios, { AxiosError } from "axios";
import { API_BACKEND_ENDPOINT } from "../config";
import { Level, LibraryVideoWatched } from "@prisma/client";

class PointSystemService {
  async getLevels() {
    return new Promise<Level[]>((resolve, reject) => {
      axios.get(`${API_BACKEND_ENDPOINT}/point-system/levels`).then((res: { data: Level[] }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  async claimLibraryVideoWatchOnce(library_video_id: string) {
    return new Promise<LibraryVideoWatched>((resolve, reject) => {
      axios.post(`${API_BACKEND_ENDPOINT}/point-system/claim-library-video-watch-once`, {
        library_video_id
      }).then((res: { data: LibraryVideoWatched }) => {
        resolve(res.data)
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    })
  }
  async claimDaily20QuestionsReward() {
    return new Promise<{
      last_claimed_daily_20_questions: string,
      user_last_claimed_daily_20_questions: string
    }>((resolve, reject) => {
      axios.post(`${API_BACKEND_ENDPOINT}/point-system/claim-daily-20-questions`).then((res: {
        data: {
          last_claimed_daily_20_questions: string,
          user_last_claimed_daily_20_questions: string
        }
      }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  async claimWeeklyNewHandsReward() {
    return new Promise<{
      last_claimed_weekly_new_hands: string;
      user_last_claimed_weekly_new_hands: string;
    }>((resolve, reject) => {
      axios.post(`${API_BACKEND_ENDPOINT}/point-system/claim-weekly-new-hands`).then((res: {
        data: {
          last_claimed_weekly_new_hands: string;
          user_last_claimed_weekly_new_hands: string;
        }
      }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  /**
   * @deprecated
   */
  // async claimDiscordJointReward() {
  //   return new Promise<{
  //     discord_joint_claimed: boolean;
  //   }>((resolve, reject) => {
  //     axios.post(`${API_BACKEND_ENDPOINT}/point-system/claim-discord-joint`).then((res: {
  //       data: {
  //         discord_joint_claimed: boolean
  //       }
  //     }) => {
  //       resolve(res.data);
  //     }).catch((err: AxiosError<{ message: string, error: string, statusCode: number; }>) => {
  //       if (err.response) {
  //         reject(err.response.data.message);
  //       } else {
  //         reject(err.message);
  //       }
  //     });
  //   });
  // }
}

export const pointSystemService = new PointSystemService();
