import axios, { type AxiosError } from 'axios';
import { API_BACKEND_ENDPOINT } from '../config';
import { Glossary, Question } from '@prisma/client';
import { TopicWithLesson } from '@clc-v2/utilities'
import { LessonProgressStatus } from '../stores/performance/interface';

class TopicLessonQuestionService {
    async getAllTopics() {
        return new Promise<Array<TopicWithLesson>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/topic-lesson-question/topic`).then((res: { data: TopicWithLesson[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getAllQuestionsOfLesson(lesson_uid: string) {
        return new Promise<Array<Question>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/topic-lesson-question/question/all-questions-of-lesson?lesson_uid=${lesson_uid}`).then((res: { data: Question[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getQuestionsByAI(cnt: number) {
        return new Promise<Array<Question>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/topic-lesson-question/question/ai-questions?cnt=${cnt}`).then((res: { data: Question[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getQuestionsUnplayedNewHanded() {
        return new Promise<Array<Question>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/topic-lesson-question/question/new-handed-full`).then((res: { data: Question[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getLessonProgressStatus(lesson_uids: string[]) {
        return new Promise<LessonProgressStatus>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/topic-lesson-question/lesson/progress-status?lesson_uids=${lesson_uids.join(',')}`).then((res: { data: LessonProgressStatus }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getNewHandedNumbers() {
        return new Promise<Array<{ hand_number: number, new_handed_at: string }>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/topic-lesson-question/question/new-handed`).then((res: { data: Array<{ hand_number: number, new_handed_at: string }> }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getNewHandedNumbersOfThisWeek() {
        return new Promise<Array<{ hand_number: number, new_handed_at: string }>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/topic-lesson-question/question/new-handed-of-this-week`).then((res: { data: Array<{ hand_number: number, new_handed_at: string }> }) => {
                resolve(res.data)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getQuestionsByHandNumber(hand_number: number) {
        return new Promise<Question>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/topic-lesson-question/question/${hand_number}`).then((res: { data: Question }) => {
                if (res.data) {
                    resolve(res.data);
                } else {
                    reject(`Not found question of hand number ${hand_number}`)
                }
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getMasteredLessons() {
        return new Promise<{ lesson_uid: string }[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/topic-lesson-question/lesson/mastered`).then((res: { data: { lesson_uid: string }[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getGlossaries() {
        return new Promise<Glossary[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/topic-lesson-question/glossary`).then((res: { data: Glossary[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
}

export const topicLessonQuestionService = new TopicLessonQuestionService();