import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';
import { AuthState, authReducer } from './auth';
import { TempState, tempReducer } from './temp';
import { TopicLessonQuestionState, topicLessonQuestionReducer } from './topic-lesson-question';
import { LibraryState, libraryReducer } from './library';
import { PerformanceState, performanceReducer } from './performance';
import { LeaderboardState, leaderboardReducer } from './leaderboard';
import { DashboardState, dashboardReducer } from './dashboard';
import { HistoryState, historyReducer } from './history';
import { PaymentState, paymentReducer } from './payment';
import { DataState, dataReducer } from './data';

export const STORE_KEY = 'clc-v2-store';

const persistConfig = {
    key: STORE_KEY,
    storage,
    stateReconciler: autoMergeLevel1,
    // whitelist: ["state_which_you_want_to_persist"],
    blacklist: ["temp", "payment"]
}

export interface GlobalState {
    auth: AuthState;
    temp: TempState;
    topicLessonQuestion: TopicLessonQuestionState;
    library: LibraryState;
    performance: PerformanceState;
    leaderboard: LeaderboardState;
    dashboard: DashboardState;
    history: HistoryState;
    payment: PaymentState;
    data: DataState;
}

const reducers = combineReducers({
    auth: authReducer,
    temp: tempReducer,
    topicLessonQuestion: topicLessonQuestionReducer,
    library: libraryReducer,
    performance: performanceReducer,
    leaderboard: leaderboardReducer,
    dashboard: dashboardReducer,
    history: historyReducer,
    payment: paymentReducer,
    data: dataReducer,
});
const persistedReducer = persistReducer<GlobalState>(persistConfig, reducers)

const errorHandlingMiddleware = (store: any) => (next: any) => (action: any) => {
    if (action.type === 'LOAD_PERSISTED_DATA') {
        const persistedData = localStorage.getItem('reduxState');
        try {
            const parsedData: GlobalState = JSON.parse(persistedData ?? '');
            // Perform type validation on parsedData
            if (false) {
                throw new Error('Invalid persisted data');
            }
            next({ type: 'PERSISTED_DATA_LOADED', payload: parsedData });
        } catch (error: any) {
            // Handle error (e.g., dispatch error action)
            console.error('Error loading persisted data:', error);
            next({ type: 'PERSISTED_DATA_LOAD_ERROR', error: error.message });
        }
    } else {
        return next(action);
    }
};

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk, errorHandlingMiddleware],
})
export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
