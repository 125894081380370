import { useEffect, useState } from "react";
import { Line } from "rc-progress";
import { _PlayPage as PlayPage } from "./_play_page";
import { Question } from "@prisma/client";
import { topicLessonQuestionService } from "../../services/topic-lesson-question.service";
import { Button, ClcSpinner } from "@clc-v2/uis";
import Modal from "react-responsive-modal";
import { useNavigate } from "react-router-dom";

export const UnplayedNewHanded = () => {
    const navigate = useNavigate();
    const [loading, set_loading] = useState<boolean>(false);
    const [loading_progress, set_loading_progress] = useState<number>(0);
    const [questions, set_questions] = useState<Question[]>([]);
    const [open_finished, set_open_finished] = useState<boolean>(false);
    useEffect(() => {
        set_loading(true);
        set_loading_progress(0);
        let timer = setInterval(() => {
            set_loading_progress((_prev) => _prev < 70 ? _prev + 1 : 70)
        }, 100);
        topicLessonQuestionService.getQuestionsUnplayedNewHanded().then((_questions) => {
            set_questions(_questions);
            set_loading_progress(80);
            clearInterval(timer);
            timer = setInterval(() => {
                set_loading_progress((_prev) => _prev < 100 ? _prev + 1 : 100)
            }, 100)
        }).catch((err_msg: string) => {
            console.error(err_msg);
        }).finally(() => {
            setTimeout(() => {
                set_loading(false);
                clearInterval(timer);
            }, 1000);
        });
    }, []);
    return (
        <div className="container mx-auto pt-10 relative font-inter min-h-[calc(100vh-80px-48px)] flex justify-center items-center">
            {loading ? (
                <div className='w-full h-[calc(100vh-192px)] text-white flex flex-col justify-center items-center gap-6'>
                    <ClcSpinner text="Loading" />
                    <Line percent={loading_progress} strokeWidth={1} strokeColor="#D3D3D3" trailColor="D9D9D9" />
                </div>
            ) : questions.length > 0 ? (
                <PlayPage questions={questions} last_question_checker={() => set_open_finished(true)} mastered_uids={[]} />
            ) : (
                <div className="text-white">No Questions</div>
            )}
            <Modal center showCloseIcon={false} closeOnOverlayClick={false} classNames={{
                modal: "!bg-primary-carbon border border-primary-golden w-96"
            }} open={open_finished} onClose={() => set_open_finished(false)} >
                <h2 className="text-white">You have completed the new hands.</h2>
                <div className="flex flex-col gap-4">
                    <Button className="w-full" type="secondary" onClick={() => navigate("/dashboard")}>
                        Go to Home
                    </Button>
                </div>
            </Modal>
        </div>
    )
}