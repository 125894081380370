import axios, { AxiosError } from "axios";
import { API_BACKEND_ENDPOINT } from "../config";

export type AnswerQuestionResponse = {
    earned_points: number;
}

class LearningService {
    answerQuestion(hand_number: number, correct: boolean, answer_type: string) {
        return new Promise<AnswerQuestionResponse>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/history/answer-question`, {
                hand_number, correct, answer_type
            }).then((res: { data: AnswerQuestionResponse }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        })
    }
}

export const learningService = new LearningService();