import { CardPerformance } from "@clc-v2/uis"
import { useAppDispatch, useAppSelector } from "../../stores/hooks"
import { useEffect, useState } from "react";
import { leaderboardService } from "../../services/leaderboard.service";
import moment from "moment";
import { set_lifetime_point } from "../../stores/performance";
import { CACHE_TIME } from "../../config";
import { useNavigate } from "react-router-dom";
import { Level } from "@prisma/client";

export const MyPerformanceCardContainer = () => {
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.auth);
    const { lifetime_point, lifetime_point_updated_at } = useAppSelector((state) => state.performance);
    const { levels, levels_updated_at } = useAppSelector((state) => state.data);
    const dispatch = useAppDispatch();
    const [my_level, set_my_level] = useState<Level>();
    const update_lifetime_point = () => {
        leaderboardService.selfLifetimePoints().then((_lifetime_point) => {
            dispatch(
                set_lifetime_point(_lifetime_point)
            )
        }).catch((err_msg: string) => {

        }).finally(() => {

        });
    }
    useEffect(() => {
        if (lifetime_point_updated_at === undefined || moment(lifetime_point_updated_at).diff(moment(), 'minutes') < CACHE_TIME) {
            update_lifetime_point();
        }
        const timer = setInterval(() => update_lifetime_point(), 5 * 60 * 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);
    useEffect(() => {
        if (levels && lifetime_point?.chips) {
            let level_index = -1;
            for (let i = 0; i < levels.length; i++) {
                const level = levels[i]
                if (level.init_point <= lifetime_point.chips) {
                    level_index = i
                }
            }
            if (level_index >= 0) {
                set_my_level(levels[level_index])
            } else {
                set_my_level(undefined)
            }
        } else {
            set_my_level(undefined)
        }
    }, [levels, lifetime_point?.chips])
    return (
        <CardPerformance
            totalRank={lifetime_point?.rank ?? 0}
            totalPoints={lifetime_point?.chips ?? 0}
            totalTickets={lifetime_point?.earned_tickets ?? 0}
            my_level={my_level}
            levels={levels}
            user={user}
            avatarClickHandler={() => navigate('/account')}
        />
    )
}