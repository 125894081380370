import { BreadCrumbs, Tabs } from "@clc-v2/uis"
import { VideoPlayer } from "../../../components/common/video-player";
import { VideoDetail } from "../../../components/common/video-detail";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { LibraryVideoWithTag } from "@clc-v2/utilities";
import { libraryService } from "../../../services/library.service";
import { LibraryAuthor, LibraryType } from "@prisma/client";

export const LibraryOnlineSessionDetail = () => {
    const [search_params] = useSearchParams();
    const navigate = useNavigate();
    const [videos, set_videos] = useState<LibraryVideoWithTag[]>([]);
    const [active_video, set_active_video] = useState<LibraryVideoWithTag>();
    const [authors, set_authors] = useState<LibraryAuthor[]>([]);
    useEffect(() => {
        const video_id = search_params.get('video_id');
        if (video_id) {
            libraryService.getGroupVideos(video_id).then(({ library_videos, library_authors: _library_authors }) => {
                set_videos(library_videos);
                set_authors(_library_authors);
            })
        }
    }, []);
    useEffect(() => {
        const video_id = search_params.get('video_id');
        if (video_id && videos.length > 0) {
            set_active_video(videos.filter((_video) => _video.id === video_id)[0]);
        }
    }, [search_params.get('video_id'), videos]);
    return (
        <div className="pt-12 font-inter">
            <BreadCrumbs menu={[
                { content: <span className="text-white text-lg font-normal font-inter leading-[27px]">All Online Sessions</span>, on_click: () => navigate(`/library/list/online-sessions`) },
                { content: <span className="text-white text-lg font-normal font-inter leading-[27px]">Overview</span> },
                { content: <span className="text-white text-lg font-normal font-inter leading-[27px]">Online Session</span> },
            ]} />
            <VideoPlayer className="mt-6 mb-12" type={LibraryType.online_session} authors={authors} videos={videos} />
            <Tabs
                activeValue={'about'}
                onTabChangeHandler={() => new Promise((resolve, reject) => {
                    resolve(true);
                })}
                options={[
                    { label: "About", value: "about" },
                ]}
            />
            {active_video ? (
                <VideoDetail className="py-12" active_video={active_video} authors={authors} />
            ) : (
                <span>Loading</span>
            )}
        </div>
    )
}