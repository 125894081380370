import { PokerPlayer, Progressbar } from "@clc-v2/uis";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { learningService } from "../../services/learning.service";
import { Question } from "@prisma/client";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import { add_ai_hand_number, add_this_week_played_hand_numbers, remove_unplayed_hand_number } from "../../stores/dashboard";
import { accountService } from "../../services/account.service";
import { set_user } from "../../stores/auth";
import { leaderboardService } from "../../services/leaderboard.service";
import { set_lifetime_point } from "../../stores/performance";
import { useBus } from "react-bus";

interface PropsPlayPage {
    questions: Question[];
    last_question_checker?: (cnt_correct: number, cnt_wrong: number) => void;
    mastered_uids: string[];
}

export const _PlayPage = (props: PropsPlayPage) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const { user } = useAppSelector((state) => state.auth);
    const { glossaries } = useAppSelector((state) => state.topicLessonQuestion);
    const bus = useBus();
    const [question_index, set_question_index] = useState<number>(0);
    const [played_hand_numbers, set_played_hand_numbers] = useState<Array<{ hand_number: number, correct: boolean }>>([]);
    const answer_question_handler = (_hand_number: number, correct: boolean) => {
        if (played_hand_numbers.filter(({ hand_number }) => hand_number === _hand_number).length > 0) {
            return;
        }
        let answer_type = 'ai'
        if (window.location.pathname.includes('learn-by-lesson')) {
            answer_type = 'lesson'
        } else if (window.location.pathname.includes('learn-by-ai')) {
            answer_type = 'ai'
        } else if (window.location.pathname.includes('unplayed-new-handed')) {
            answer_type = 'new-hands'
        } else if (window.location.pathname.includes('learn-by-hand-number')) {
            answer_type = 'by-hand-number'
        }
        learningService.answerQuestion(_hand_number, correct, answer_type).then((result) => {
            set_played_hand_numbers(
                (_prev) => _prev.map(({ hand_number }) => hand_number).includes(_hand_number) ? _prev.map((param) => param.hand_number === _hand_number ? ({ ...param, correct }) : param) : [..._prev, { hand_number: _hand_number, correct }]
            );
            if (answer_type === 'ai') {
                dispatch(
                    add_ai_hand_number(_hand_number)
                );
            } else {
                dispatch(
                    remove_unplayed_hand_number(_hand_number)
                )
            }
            dispatch(
                add_this_week_played_hand_numbers(_hand_number)
            )
            if (result.earned_points !== 0) {
                bus.emit('show-reward', result.earned_points);
                leaderboardService.selfLifetimePoints().then((_lifetime_point) => {
                    dispatch(
                        set_lifetime_point(_lifetime_point)
                    )
                }).catch((err_msg: string) => {

                }).finally(() => {

                });
            }
        }).catch((err_msg: string) => {

        }).finally(() => {

        });
    }
    const last_question_checker = () => {
        if (props.last_question_checker) {
            const cnt_correct = played_hand_numbers.filter((el) => el.correct).length;
            const cnt_wrong = played_hand_numbers.filter((el) => !el.correct).length;
            props.last_question_checker?.(cnt_correct, cnt_wrong);
        }
    }
    const playing_card_change_handler = (_playing_card: string) => {
        accountService.updatePlayingCard(_playing_card).then((_user) => {
            dispatch(
                set_user(_user)
            )
        }).catch((err_msg: string) => {
            console.error(err_msg);
        });
    }
    useEffect(() => {
        set_question_index(0);
    }, [props.questions]);
    return (
        <div className="w-full flex flex-col gap-4 md:gap-12 relative font-inter">
            <PokerPlayer
                index={question_index + 1}
                data={props.questions[question_index]}
                dictionary={glossaries ?? {}}
                handle_answer_question={answer_question_handler}
                playing_card={user?.playing_card}
                handle_next_question={props.questions.length === (question_index + 1) ? last_question_checker : () => {
                    set_question_index((_prev) => _prev + 1);
                }}
                mastered={props.mastered_uids.includes(props.questions[question_index].lesson_uid)}
                handle_change_playing_card={playing_card_change_handler}
                show_reward={["/learning/learn-by-ai", "/learning/learn-by-lesson", "/learning/unplayed-new-handed"].includes(pathname)}
            />
            <Progressbar
                index={question_index}
                hand_numbers={props.questions.map(({ hand_number }) => hand_number)}
                played_hand_numbers={played_hand_numbers}
                on_question_click_handler={(id) => {
                    set_question_index(id);
                }}
                on_history_click_handler={() => {
                    navigate("/learning/history");
                }}
            />
        </div>
    )
}