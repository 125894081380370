import { createSlice } from '@reduxjs/toolkit'
import { LessonProgressStatus, LessonProgressStatusWithUpdatedAt } from './interface';
import moment from 'moment';
import { IProgressChartDisplay, LeaderboardItemData } from '@clc-v2/uis';

export interface PerformanceState {
    lessons_progress_status?: LessonProgressStatusWithUpdatedAt;
    lifetime_point?: LeaderboardItemData;
    lifetime_point_updated_at?: number;
    progress_monthly_history?: Array<IProgressChartDisplay>;
    progress_monthly_history_updated_at?: number;
    progress_weekly_history?: Array<IProgressChartDisplay>;
    progress_weekly_history_updated_at?: number;
}

const defaultState: PerformanceState = {
    lessons_progress_status: undefined,
    lifetime_point_updated_at: undefined,
    progress_monthly_history: undefined,
    progress_monthly_history_updated_at: undefined,
    progress_weekly_history: undefined,
    progress_weekly_history_updated_at: undefined,
}

export const performanceSlice = createSlice({
    name: 'performance',
    initialState: defaultState,
    reducers: {
        set_lesson_progress_status: (state: PerformanceState, action: { payload: LessonProgressStatus }) => {
            for (const lesson_uid of Object.keys(action.payload)) {
                state.lessons_progress_status = {
                    ...state.lessons_progress_status,
                    [lesson_uid]: {
                        cnt_of_questions: action.payload[lesson_uid].cnt_of_questions,
                        passed_hand_numbers: action.payload[lesson_uid].passed_hand_numbers,
                        last_updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                    },
                };
            }
        },
        set_lifetime_point: (state: PerformanceState, action: { payload: LeaderboardItemData }) => {
            state.lifetime_point = action.payload;
            state.lifetime_point_updated_at = moment().unix();
        },
        set_progress_monthly_history: (state: PerformanceState, action: { payload: IProgressChartDisplay[] }) => {
            state.progress_monthly_history = action.payload;
            state.progress_monthly_history_updated_at = moment().unix();
        },
        set_progress_weekly_history: (state: PerformanceState, action: { payload: IProgressChartDisplay[] }) => {
            state.progress_weekly_history = action.payload;
            state.progress_weekly_history_updated_at = moment().unix();
        },
        clear_performance_data: (state: PerformanceState) => {
            state = defaultState;
        }
    },
})

export const {
    set_lesson_progress_status,
    set_lifetime_point,
    set_progress_monthly_history,
    set_progress_weekly_history,
    clear_performance_data,
} = performanceSlice.actions
export const performanceReducer = performanceSlice.reducer