
import { useEffect, useRef, useState } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { VideoCardContainer } from "./video-card-container";
import { ILibraryVideoWithTagWithAuthor } from "@clc-v2/utilities";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import { useNavigate } from "react-router-dom";
import { LibraryType } from "@prisma/client";
import classNames from "classnames";

export const VideoGroup = (props: {
    videos: ILibraryVideoWithTagWithAuthor[];
}) => {
    const { user } = useAppSelector((state) => state.auth);
    const navigate = useNavigate();
    const ref = useRef<HTMLElement>() as React.MutableRefObject<HTMLDivElement>;
    const { events } = useDraggable(ref);
    const [scrollable, set_scrollable] = useState<boolean>(false);
    useEffect(() => {
        if (ref.current.clientWidth < ref.current.scrollWidth) {
            set_scrollable(true);
        } else {
            set_scrollable(false);
        }
    }, [ref.current?.clientWidth, ref.current?.scrollWidth]);
    useEffect(() => {
        const handleWheel = (e: WheelEvent) => {
            if (ref.current) {
                // Check if the mouse is within the container
                const isMouseOverContainer = ref.current.contains(e.target as Node);

                // If the mouse is over the container, prevent default scroll behavior
                if (isMouseOverContainer) {
                    e.preventDefault();

                    // Adjust scrollLeft of the container based on the wheel delta
                    ref.current.scrollLeft += e.deltaY;
                }
            }
        };

        // Add wheel event listener
        document.addEventListener('wheel', handleWheel, { passive: false });

        return () => {
            // Clean up event listener when component unmounts
            document.removeEventListener('wheel', handleWheel);
        };
    }, []);
    return (
        <div className="relative">
            <div
                className={classNames(
                    "w-fit max-w-full overflow-auto mt-4 pb-2 thin-scrollbar",
                    {
                        "mr-52": scrollable
                    }
                )}
                ref={ref}
                {...events}
            >
                <div className="w-fit flex gap-4">
                    {props.videos.map((video) => (
                        <div
                            className="w-[264px]"
                            key={video.id}
                        >
                            <VideoCardContainer
                                video_card_size="md"
                                hide_label
                                video={video}
                                on_click={() => {
                                    navigate(`/library/detail/${LibraryType.lesson}?video_id=${video.id}`);
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
            {scrollable && (
                <div className="absolute right-0 top-0 h-full w-52 bg-gradient-to-r from-transparent to-color-1 pointer-events-none"></div>
            )}
        </div>
    )
}