import { useEffect, useState } from "react";
import { libraryService } from "../../../services/library.service";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { set_quizzes } from "../../../stores/library";
import { VideoCardContainer } from "../../../components/common/video-card-container";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LibraryType } from "@prisma/client";

export const LibraryListQuizzes = () => {
  const { quizzes } = useAppSelector((state) => state.library);
  const dispatch = useAppDispatch();
  const [url_search_params] = useSearchParams();
  const navigate = useNavigate();
  const [loading, set_loading] = useState<boolean>(true);
  const [error, set_error] = useState<string>("");
  const [active_tag_id, set_active_tag_id] = useState<string>('');
  useEffect(() => {
    set_error("");
    set_loading(true);
    const search_word = url_search_params.get('search_word') ?? '';
    const difficulty = ['beginner', 'intermediate', 'advanced'].indexOf(search_word.toLowerCase());
    console.log(difficulty)
    libraryService.getQuizzes(difficulty >= 0 ? '' : search_word).then((_quizzes) => {
      dispatch(
        set_quizzes(difficulty >= 0 ? _quizzes.filter((_quizze) => _quizze.difficulty === difficulty + 1) : _quizzes)
      );
    }).catch((err_msg: string) => {
      set_error(err_msg);
    }).finally(() => {
      set_loading(false);
    });
  }, [url_search_params.get('search_word')]);
  useEffect(() => {
    const tmp = url_search_params.get('active_tag');
    if (tmp) {
      set_active_tag_id(tmp);
    } else {
      set_active_tag_id('');
    }
  }, [url_search_params.get('active_tag')]);
  if (loading) {
    return (
      <div className="min-h-[768px] flex justify-center items-center font-inter">Loading...</div>
    )
  } else {
    return error ? (
      <div className="flex justify-center items-center font-inter">{error}</div>
    ) : (quizzes !== null && (quizzes ?? []).length === 0) ? (
      <div className="flex justify-center items-center text-white font-inter">
        Does not have any quizzes
      </div>
    ) : (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-12 gap-y-6 font-inter">
        {
          (quizzes ?? []).filter((quiz) => active_tag_id === "" || quiz.library_video_and_video_tag.filter(({ video_tag_id }) => video_tag_id === active_tag_id).length > 0).map((quiz) => (
            <VideoCardContainer
              video_card_size="md"
              hide_label
              key={quiz.id}
              video={quiz}
              on_click={() => {
                navigate(`/library/detail/${LibraryType.quiz}?video_id=${quiz.id}`);
              }}
            />
          ))
        }
      </div>
    )
  }
}