import { LibraryVideoWithTag } from "@clc-v2/utilities";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { libraryService } from "../../../services/library.service";
import { VideoCardContainer } from "../../../components/common/video-card-container";
import { LibraryType } from "@prisma/client";
import pluralize from "pluralize";

export const LibraryDetailLayout = () => {
    const location = useLocation();
    const [url_search_params] = useSearchParams();
    const navigate = useNavigate();
    const [library_type, set_library_type] = useState<LibraryType>();
    const [loading_related_videos, set_loading_related_videos] = useState<boolean>(false);
    const [related_videos, set_related_videos] = useState<Array<LibraryVideoWithTag & { library: { type?: LibraryType } }>>([]);
    useEffect(() => {
        const tmp = url_search_params.get('video_id');
        if (tmp) {
            set_loading_related_videos(true);
            libraryService.getRelatedVideos(tmp).then((_videos) => {
                set_related_videos(_videos.map((video) => ({ ...video, library: {} })));
            }).catch((err_msg: string) => {
                console.error(err_msg);
            }).finally(() => {
                set_loading_related_videos(false);
            })
        }
    }, [url_search_params.get('video_id')]);
    useEffect(() => {
        if (location.pathname.includes(LibraryType.webinar)) {
            set_library_type(LibraryType.webinar)
        } else if (location.pathname.includes(LibraryType.lesson)) {
            set_library_type(LibraryType.lesson);
        } else if (location.pathname.includes(LibraryType.online_session)) {
            set_library_type(LibraryType.online_session);
        } else if (location.pathname.includes(LibraryType.quiz)) {
            set_library_type(LibraryType.quiz);
        }
    }, [location.pathname]);
    return (
        <div className="font-inter">
            <Outlet />
            <div className="py-12 flex flex-col gap-10">
                <div className="flex flex-col gap-3">
                    <span className="uppercase text-primary-golden text-[10px] font-light leading-[12px] tracking-[2px]">Video Library</span>
                    {library_type && <h2 className="text-white capitalize text-[32px] leading-[38px] tracking-[-0.2px] font-medium font-inter">Related {pluralize(library_type.replace('_', ' '), 10)}</h2>}
                </div>
                {loading_related_videos ? (
                    <div>Loading</div>
                ) : related_videos.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                        {related_videos.map((_video) => (
                            <VideoCardContainer
                                video_card_size="lg"
                                key={_video.id}
                                video={_video}
                                on_click={() => {
                                    navigate(`/library/detail/webinar?video_id=${_video.id}`);
                                }}
                            />
                        ))}
                    </div>
                ) : (
                    <div className="w-full flex justify-center items-center">
                        <p className="text-white">No Related Videos</p>
                    </div>
                )}
            </div>
        </div>
    )
}