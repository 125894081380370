import { embedVideo } from "@clc-v2/utilities";
import { useEffect, useRef } from "react";
import { useAppSelector } from "../../stores/hooks";
import Player from '@vimeo/player';
import { dataService } from "../../services/data.service";

export const IntroVideo = () => {
  const user = useAppSelector((state) => state.auth.user);
  const vimeo_ref = useRef<HTMLIFrameElement>(null);
  useEffect(() => {
    if (vimeo_ref.current) {
      var player = new Player(vimeo_ref.current);
      player.on('play', () => {
        console.log('The video started playing');
        // You can add any additional logic here
        if (user?.id) {
          dataService.updateCountTutorialVideo(user.id).then((_count) => {
            console.log('Count of watching intro video ', _count)
          }).catch((err_msg: string) => {
            console.error(err_msg);
          })
        }
      });
      return () => {
        player.off('play');
      };
    }
  }, [vimeo_ref.current, user?.id]);
  return (
    <iframe
      ref={vimeo_ref}
      src={embedVideo("https://vimeo.com/950552705?share=copy")}
      width="100%"
      height="100%"
      style={{ backgroundColor: '#000' }}
      allowFullScreen
    />
  )
}