import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { useEffect, useState } from "react";
import { libraryService } from "../../../services/library.service";
import { set_lessons } from "../../../stores/library";
import { convertVideoListToAuthorGroup } from "@clc-v2/utilities";
import { Author } from "@clc-v2/uis";
import { VideoGroup } from "../../../components/common/video-group";

export const LibraryListLessons = () => {
    const { lessons, library_authors } = useAppSelector((state) => state.library);
    const dispatch = useAppDispatch();
    const [url_search_params] = useSearchParams();
    const [loading, set_loading] = useState<boolean>(true);
    const [error, set_error] = useState<string>("");
    const [active_tag_id, set_active_tag_id] = useState<string>('');
    useEffect(() => {
        set_error("");
        set_loading(true);
        const search_word = url_search_params.get('search_word') ?? '';
        const difficulty = ['beginner', 'intermediate', 'advanced'].indexOf(search_word.toLowerCase());
        libraryService.getLessons(difficulty >= 0 ? '' : search_word).then((_lessons) => {
            dispatch(
                set_lessons(difficulty >= 0 ? _lessons.filter((lesson) => lesson.difficulty === difficulty + 1) : _lessons)
            );
        }).catch((err_msg: string) => {
            set_error(err_msg);
        }).finally(() => {
            set_loading(false);
        });
    }, [url_search_params.get('search_word')]);
    useEffect(() => {
        const tmp = url_search_params.get('active_tag');
        if (tmp) {
            set_active_tag_id(tmp);
        } else {
            set_active_tag_id('');
        }
    }, [url_search_params.get('active_tag')]);
    if (loading) {
        return (
            <div className="min-h-[768px] flex justify-center items-center font-inter">Loading...</div>
        )
    } else {
        return error ? (
            <div className="flex justify-center items-center font-inter">{error}</div>
        ) : (lessons !== null && (lessons ?? []).length === 0) ? (
            <div className="flex justify-center items-center text-white font-inter">
                Does not have any lessons
            </div>
        ) : (
            <div className="w-full flex flex-col items-start gap-y-6 font-inter">
                {
                    convertVideoListToAuthorGroup(
                        (lessons ?? []).filter(
                            (lesson) => active_tag_id === "" || lesson.library_video_and_video_tag.filter(({ video_tag_id }) => video_tag_id === active_tag_id).length > 0
                        )
                    ).sort((group1, group2) => {
                        const group1_length = [group1.library_author_1, group1.library_author_2, group1.library_author_3, group1.library_author_4, group1.library_author_5].filter((author) => !!author).length
                        const group2_length = [group2.library_author_1, group2.library_author_2, group2.library_author_3, group2.library_author_4, group2.library_author_5].filter((author) => !!author).length
                        if (group1_length > group2_length) {
                            return 1
                        } else if (group1_length < group2_length) {
                            return -1
                        } else {
                            if (group1.library_author_1.first_name > group2.library_author_1.first_name) {
                                return 1
                            } else if (group1.library_author_1.first_name < group2.library_author_1.first_name) {
                                return -1
                            } else {
                                if ((group1.library_author_2?.first_name ?? '') > (group2.library_author_2?.first_name ?? '')) {
                                    return 1
                                } else {
                                    return -1
                                }
                            }
                        }
                    }).map((lesson_author_group, id) => {
                        return (
                            <div className="w-full" key={id}>
                                <div className="flex flex-wrap items-center gap-2">
                                    {
                                        [
                                            lesson_author_group.library_author_1, lesson_author_group.library_author_2, lesson_author_group.library_author_3, lesson_author_group.library_author_4, lesson_author_group.library_author_5
                                        ].filter((author) => author).map((author) => author && (
                                            <Author author={{
                                                family_name: author.last_name,
                                                given_name: author.first_name,
                                                picture: author.picture,
                                                thumbnail_picture: author.thumbnail_picture,
                                            }} />
                                        ))
                                    }
                                </div>
                                <VideoGroup videos={lesson_author_group.videos} />
                            </div>
                        );
                    })
                }
            </div>
        )
    }
}