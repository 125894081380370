import axios, { AxiosError } from "axios";
import { API_BACKEND_ENDPOINT } from "../config";
import { LeaderboardItemData } from "@clc-v2/uis";

class LeaderboardService {
    getTop10UsersByPeriod(period: string) {
        return new Promise<{
            top_10_users: LeaderboardItemData[],
            self_user: LeaderboardItemData,
        }>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/leaderboard/top-10-users?period=${period}`).then((res: {
                data: {
                    top_10_users: LeaderboardItemData[],
                    self_user: LeaderboardItemData,
                }
            }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        });
    }
    selfLifetimePoints() {
        return new Promise<LeaderboardItemData>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/leaderboard/lifetime-points`).then((res: { data: LeaderboardItemData }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        })
    }
}

export const leaderboardService = new LeaderboardService();