import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Providers } from './app/providers';
import { AppRoutes } from './app/app-routes';
import ScrollToTop from './app/components/scroll-to-top';
import { Helmet } from "react-helmet";

console.log("NODE ENV v2024-09-15 ", process.env.NODE_ENV)

declare global {
	interface Window {
		Chargebee: any;
	}
}

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	// <StrictMode>
	<>
		<Helmet>
			<meta charSet="utf-8" />
			<title></title>
			<meta name='description' content='' />
			<link rel="icon" href={`${process.env.NX_DOMAIN}/public/clc-logo2.0.png`} />
			<link rel="apple-touch-icon" href={`${process.env.NX_DOMAIN}/public/clc-logo2.0.png`} />
			<link rel="icon" href={`${process.env.NX_DOMAIN}/public/clc-logo2.0.png`} />
			<link rel="canonical" href="http://mysite.com/example" />
			<title>Chip Leader AI: Tournament learning tool from Chance Kornuth and Alex Foxen</title>
			<meta name="title" content='Chip Leader AI: Tournament learning tool from Chance Kornuth and Alex Foxen' />
			<meta
				name="description"
				content='Chip Leader AI uses interactive learning paths that teach you how to identify the most profitable decision in every hand.' />
			<meta name="author" content="Chip Leader Coaching" />
			<meta name="image" content={`${process.env.NX_DOMAIN}/public/clc-logo2.0.png`} />
			<meta name="keywords" content={'AI, Chipleader, LearnWithSocrates, CLC, Poker, Learning'} />

			<meta property="og:title" content='Chip Leader AI: Tournament learning tool from Chance Kornuth and Alex Foxen' />
			<meta property="og:site_name" content="AI Chipleaders" />
			<meta property="og:url" content="https://ai.chipleader.com/" />
			<meta property="article:author" content="Chip Leader Coaching" />
			<meta
				property="og:description"
				content='Chip Leader AI uses interactive learning paths that teach you how to identify the most profitable decision in every hand.'
			/>
			<meta property="og:type" content="platform" />
			<meta
				property="article:tag"
				content={'AI, Chipleader, LearnWithSocrates, CLC, Poker, Learning'}
			/>
			<meta property="og:image" content={`${process.env.NX_DOMAIN}/public/clc-logo2.0.png`} />
			<meta property="og:type" content="website" />
			<meta property="og:url" content="https://ai.chipleader.com/" />
			<meta property="og:title" content='Chip Leader AI: Tournament learning tool from Chance Kornuth and Alex Foxen' />
			<meta
				property="og:description"
				content='Chip Leader AI uses interactive learning paths that teach you how to identify the most profitable decision in every hand.'
			/>
			<meta property="og:image" content={`${process.env.NX_DOMAIN}/public/clc-logo2.0.png`} />

			<meta property="twitter:card" content="summary_large_image" />
			<meta property="twitter:url" content="https://ai.chipleader.com/" />
			<meta property="twitter:title" content='Chip Leader AI: Tournament learning tool from Chance Kornuth and Alex Foxen' />
			<meta
				property="twitter:description"
				content='Chip Leader AI uses interactive learning paths that teach you how to identify the most profitable decision in every hand.'
			/>
			<meta property="twitter:image" content={`${process.env.NX_DOMAIN}/public/clc-logo2.0.png`} />
			<script type="application/ld+json">
				{`
                    {
                        "@context": "https://schema.org/",
                        "@type": "Company",
                        "name": "Chip Leader AI: Tournament learning tool from Chance Kornuth and Alex Foxen",
                        "image": "${`${process.env.NX_DOMAIN}/public/clc-logo2.0.png`}",
                        "description": "$Chip Leader AI uses interactive learning paths that teach you how to identify the most profitable decision in every hand.",
                        "brand": {
                            "@type": "Brand",
                            "name": "Chip Leader Coaching"
                        }
                    }
                `}
			</script>
			{/* Youtube */}
			<script type="application/ld+json">
				{`
                    {
                        "@context": "https://schema.org/",
                        "@type": "Organization",
                        "name": "Youtube Channel",
                        "image": "${`${process.env.NX_DOMAIN}/public/clc-logo2.0.png`}",
                        "url": "https://www.youtube.com/c/ChipLeaderCoaching"
                    }
                `}
			</script>
			{/* Facebook */}
			<script type="application/ld+json">
				{`
                    {
                        "@context": "https://schema.org/",
                        "@type": "Organization",
                        "name": "Facebook",
                        "image": "${`${process.env.NX_DOMAIN}/public/clc-logo2.0.png`}",
                        "url": "https://m.facebook.com/chipleadercoaching/"
                    }
                `}
			</script>
			{/* Mobile Twitter */}
			<script type="application/ld+json">
				{`
                    {
                        "@context": "https://schema.org/",
                        "@type": "Organization",
                        "name": "Mobile Twitter",
                        "image": "${`${process.env.NX_DOMAIN}/public/clc-logo2.0.png`}",
                        "url": "https://twitter.com/ChipLDR/"
                    }
                `}
			</script>
			{/* TikTok */}
			<script type="application/ld+json">
				{`
                    {
                        "@context": "https://schema.org/",
                        "@type": "Organization",
                        "name": "TikTok",
                        "image": "${`${process.env.NX_DOMAIN}/public/clc-logo2.0.png`}",
                        "url": "https://www.tiktok.com/@clcpoker/"
                    }
                `}
			</script>
			{/* Instagram */}
			<script type="application/ld+json">
				{`
                    {
                        "@context": "https://schema.org/",
                        "@type": "Organization",
                        "name": "Instagram",
                        "image": "${`${process.env.NX_DOMAIN}/public/clc-logo2.0.png`}",
                        "url": "https://www.instagram.com/chipleadercoaching"
                    }
                `}
			</script>
			<link rel="canonical" href={`https://ai.chipleader.com${window.location.pathname}`} />
		</Helmet>

		<Providers>
			<BrowserRouter>
				<AppRoutes />
				<ScrollToTop />
			</BrowserRouter>
		</Providers>
	</>
	// </StrictMode>
);
