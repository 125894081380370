import { ClcLogo, SocialLinks } from "@clc-v2/uis";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../stores/hooks";
import { useEffect, useState } from "react";
import moment from "moment";
import { STORE_KEY } from "../../stores";
import { AuthCookieName, loginUrl } from "../../app-routes";
import Cookies from "js-cookie";
import storage from "redux-persist/lib/storage";
import { expired } from "@clc-v2/utilities";
import { IcHorizontalSeparator, IcVerticalSeparator } from "@clc-v2/icons";

export const Footer = (props: {
    opened: boolean;
    set_opened: (val: boolean) => void;
}) => {
    const navigate = useNavigate();
    const { until_allowed } = useAppSelector((state) => state.payment);
    const [allowed, set_allowed] = useState<boolean>(false);
    useEffect(() => {
        if (until_allowed && !expired(until_allowed, 0)) {
            set_allowed(true);
        }
    }, [until_allowed]);
    return (
        <footer className={`w-full bg-card-bg-second border border-color-3 rounded-large p-7 flex flex-col gap-10 mt-16 transform transition-all font-inter ${props.opened ? 'translate-x-80' : ''}`} onClick={() => {
            if (props.opened) {
                props.set_opened(false);
            }
        }}>
            <div className="w-full flex flex-col md:flex-row justify-between items-center md:items-start gap-6">
                <div className="w-fit flex justify-center items-center gap-4">
                    <ClcLogo className='w-36 h-16' />
                    {/* <span className='uppercase text-white'>Chip Leader AI</span> */}
                </div>
                <div className="relative xl:fixed xl:left-1/2 xl:top-4 xl:transform xl:-translate-x-1/2 text-center cursor-pointer" onClick={() => navigate('/account?active_tab=invited_by')}>
                    <p className="text-white">Invite your friends and family to earn bonus points and enjoy one month free!</p>
                </div>
                <SocialLinks
                    instagramLink="https://www.instagram.com/chipleadercoaching/"
                    // spotifyLink="https://spotify.com"
                    facebookLink="https://m.facebook.com/chipleadercoaching"
                    tiktokLink="https://www.tiktok.com/@clcpoker"
                    twitterLink="https://twitter.com/ChipLDR"
                    youtubeLink="https://www.youtube.com/c/ChipLeaderCoaching"
                />
            </div>
            <div className="w-full flex flex-col md:flex-row md:flex-wrap justify-center items-center gap-2 font-inter font-normal text-sm leading-[16px] relative">
                <NavLink className="text-white hover:text-primary-golden" to={"/dashboard"}>Home</NavLink>
                <IcHorizontalSeparator />

                {true && (
                    <>
                        <NavLink className="whitespace-nowrap text-white hover:text-primary-golden" to={"/learning/learn-by-ai"}>AI Learning</NavLink>
                        <IcVerticalSeparator />
                        <NavLink className="whitespace-nowrap text-white hover:text-primary-golden" to={"/learning/pick-your-path"}>Pick Your Path</NavLink>
                        <IcVerticalSeparator />
                        <NavLink className="whitespace-nowrap text-white hover:text-primary-golden" to={"/library/list/webinars"}>Video Library</NavLink>
                        <IcVerticalSeparator />
                        <NavLink className="whitespace-nowrap text-white hover:text-primary-golden" to={"/performance/me"}>Performance</NavLink>
                        <IcVerticalSeparator />
                        <NavLink className="whitespace-nowrap text-white hover:text-primary-golden" to={""}>CLC Store</NavLink>
                        <IcVerticalSeparator />
                        <NavLink className="whitespace-nowrap text-white hover:text-primary-golden" to={"/learning/learn-by-hand-number"}>Study By Hand Number</NavLink>
                        <IcVerticalSeparator />
                    </>
                )}

                <span className="cursor-pointer text-white hover:text-primary-golden" onClick={() => {
                    storage.removeItem(`persist:${STORE_KEY}`);
                    Cookies.remove(AuthCookieName);
                    setTimeout(() => {
                        window.location.href = loginUrl;
                    }, 500);
                }}>Log out</span>
                <div className="relative md:absolute md:left-0 md:-top-7 xl:top-2 flex items-center gap-1">
                    <span className="text-gray-500 text-lg">©</span><span className="text-gray-500">Chip Leader Coaching LLC {new Date().getFullYear()}</span>
                </div>
            </div>
        </footer>
    )
}