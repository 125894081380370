import { CardCourse } from "@clc-v2/uis";

export const LearnMore = () => {
  return (
    <div className="w-full flex flex-col items-start gap-10 mt-4 md:mt-6 py-0 md:py-6">
      <div className="">
        <p className="text-primary-golden text-[10px] font-light leading-[12px] tracking-[2px] font-inter uppercase">INQUIRIES</p>
        <h2 className="text-white font-medium text-[32px] leading-[38px] tracking-[-0.2px] mt-3">Learn More</h2>
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4">
        <CardCourse title="Private Lesson Inquiries" description="" hoverText="" btnLabel="Get a private lesson" btnOnClickHandler={() => {
          window.open('https://docs.google.com/forms/d/e/1FAIpQLSfrT9q6uHTNieYFepR8AkRbowT3P0ox61q96s1ug4h4P5VFJQ/viewform', '_blank');
        }} />
        <CardCourse title="Submit your idea" description="" hoverText="" btnLabel="Submit" btnOnClickHandler={() => {
          window.open('https://docs.google.com/forms/d/e/1FAIpQLSdp79Dcwol6-toFa8mo1XEeT0RpO2IAISngyVgd69ITRSfGag/viewform', '_blank')
        }} />
        <CardCourse title="Contact Support" description="" hoverText="For a quicker response, please contact us through our live chat support." btnLabel="Visit CLAI support" btnOnClickHandler={() => { }} />
      </div>
    </div>
  )
}
