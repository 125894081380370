import { Question } from "@prisma/client";
import { useEffect, useState } from "react";
import { topicLessonQuestionService } from "../../services/topic-lesson-question.service";
import { _PlayPage as PlayPage } from "./_play_page";
import { Line } from "rc-progress";
import { Button, ClcSpinner } from "@clc-v2/uis";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import { historyService } from "../../services/history.service";
import moment from "moment";
import { set_today_played_ai_hand_numbers } from "../../stores/dashboard";
import Modal from "react-responsive-modal";
import { useNavigate } from "react-router-dom";
import { expired } from "@clc-v2/utilities";
import { set_mastered_lessons } from "../../stores/topic-lesson-question";
import { shuffle } from "lodash";
import { useBus } from "react-bus";

export const LearnByAI = () => {
    const { today_played_ai_hand_numbers, today_played_ai_hand_numbers_updated_at } = useAppSelector((state) => state.dashboard);
    const { mastered_lessons, mastered_lessons_updated_at } = useAppSelector((state) => state.topicLessonQuestion);
    const { count_daily_questions } = useAppSelector((state) => state.data);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading, set_loading] = useState<boolean>(true);
    const [loading_progress, set_loading_progress] = useState<number>(0);
    const [questions, set_questions] = useState<Question[]>([]);
    const [open_continue_modal_with_data, set_open_continue_modal_with_data] = useState<{
        open: boolean;
        loading: boolean;
        correct: number;
        wrong: number;
    }>({
        open: false,
        loading: true,
        correct: 0,
        wrong: 0
    });
    const bus = useBus();
    const fetch_ai_questions = (cnt: number) => {
        set_loading(true);
        set_loading_progress(0);
        let timer = setInterval(() => {
            set_loading_progress((_prev) => _prev < 70 ? _prev + 1 : 70)
        }, 100);
        topicLessonQuestionService.getQuestionsByAI(cnt).then((_questions) => {
            set_questions(shuffle(_questions));
            set_loading_progress(80);
            clearInterval(timer);
            timer = setInterval(() => {
                set_loading_progress((_prev) => _prev < 100 ? _prev + 1 : 100)
            }, 100)
        }).catch((err_msg: string) => {
        }).finally(() => {
            setTimeout(() => {
                set_loading(false);
                clearInterval(timer);
            }, 1000);
        });
    }
    const last_question_checker = (cnt_correct: number, cnt_wrong: number) => {
        set_open_continue_modal_with_data({
            open: true,
            loading: true,
            correct: 0,
            wrong: 0,
        });
        historyService.getEarningHistory(
            moment().format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
            'ai'
        ).then((today_history) => {
            const result: Record<string, boolean> = {}
            for (let i = 0; i < today_history.length; i++) {
                result[today_history[i].hand_number] = today_history[i].result
            }
            let correct = 0, wrong = 0;
            for (let hand_number of Object.keys(result)) {
                if (result[hand_number]) {
                    correct += 1
                } else {
                    wrong += 1
                }
            }
            set_open_continue_modal_with_data({
                open: true,
                loading: false,
                correct,
                wrong,
            })
            dispatch(
                set_today_played_ai_hand_numbers(today_history.map(({ hand_number }) => hand_number))
            );
        }).catch((err_msg: string) => {
            // set_error(err_msg);
        });
    }
    const continue_handler = () => {
        const remain_cnt_of_questions_for_today = Math.max((count_daily_questions ?? parseInt(process.env.NX_DAILY_QUESTIONS ?? '0')) - (today_played_ai_hand_numbers ?? []).length, 0);
        fetch_ai_questions(remain_cnt_of_questions_for_today);
        set_open_continue_modal_with_data({
            open: false,
            loading: true,
            correct: 0,
            wrong: 0
        });
    }
    useEffect(() => {
        if (today_played_ai_hand_numbers_updated_at === undefined || expired(today_played_ai_hand_numbers_updated_at, 0)) {
            historyService.getEarningHistory(
                moment().format('YYYY-MM-DD'),
                moment().format('YYYY-MM-DD'),
                'ai'
            ).then((today_history) => {
                dispatch(
                    set_today_played_ai_hand_numbers(today_history.map(({ hand_number }) => hand_number))
                );
                const remain_cnt_of_questions_for_today = Math.max((count_daily_questions ?? parseInt(process.env.NX_DAILY_QUESTIONS ?? '0')) - (today_history ?? []).length, 0);
                fetch_ai_questions(remain_cnt_of_questions_for_today);
            }).catch((err_msg: string) => {
                console.error(err_msg);
            });
        } else {
            const remain_cnt_of_questions_for_today = Math.max((count_daily_questions ?? parseInt(process.env.NX_DAILY_QUESTIONS ?? '0')) - (today_played_ai_hand_numbers ?? []).length, 0);
            fetch_ai_questions(remain_cnt_of_questions_for_today);
        }
    }, []);
    useEffect(() => {
        if (
            mastered_lessons_updated_at === undefined || expired(mastered_lessons_updated_at, 0)
        ) {
            topicLessonQuestionService.getMasteredLessons().then((_mastered_lessons) => {
                dispatch(
                    set_mastered_lessons(_mastered_lessons.map(({ lesson_uid }) => lesson_uid))
                );
            }).catch((err_msg: string) => {
                console.error(err_msg);
            });
        }
    }, [mastered_lessons_updated_at]);
    useEffect(() => {
        bus.emit('ai-continue-modal', open_continue_modal_with_data.open)
    }, [open_continue_modal_with_data.open]);
    return (
        <div className="container mx-auto relative font-inter min-h-[calc(100vh-80px-48px)] flex justify-center items-center">
            {(loading) ? (
                <div className='w-full h-[calc(100vh-192px)] text-white flex flex-col justify-center items-center gap-6'>
                    <ClcSpinner text="Loading" />
                    <Line percent={loading_progress} strokeWidth={1} strokeColor="#D3D3D3" trailColor="D9D9D9" />
                </div>
            ) : questions.length > 0 ? (
                <PlayPage questions={questions} last_question_checker={last_question_checker} mastered_uids={mastered_lessons ?? []} />
            ) : (
                <div className="text-white">No Questions</div>
            )}
            <Modal center showCloseIcon={false} closeOnOverlayClick={false} classNames={{
                modal: "!bg-primary-carbon border border-primary-golden w-96"
            }} open={open_continue_modal_with_data.open} onClose={() => set_open_continue_modal_with_data({ open: false, loading: true, correct: 0, wrong: 0 })}>
                <h2 className="text-white">You have completed the daily goal.</h2>
                {open_continue_modal_with_data.loading ? (
                    <div className="text-white mb-4">
                        <span className="text-green">Loading</span>
                    </div>
                ) : (
                    <div className="text-white mb-4">
                        <span className="text-green">{open_continue_modal_with_data.correct} Correct</span> | <span className="text-red-500">{open_continue_modal_with_data.wrong} Wrong</span>
                    </div>
                )}
                <hr className="my-4 border-gray-500" />
                <div className="flex flex-col gap-4">
                    <Button className="w-full" type="primary" onClick={continue_handler}>Continue Learning With AI</Button>
                    <Button className="w-full" type="secondary" onClick={() => navigate("/dashboard")}>Go to Home</Button>
                </div>
            </Modal>
        </div>
    )
}