import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { TopicWithLesson } from '@clc-v2/utilities';
import { Glossary, Lesson } from '@prisma/client';

export interface TopicLessonQuestionState {
    topics?: Array<TopicWithLesson>;
    topics_updated_at?: number;
    mastered_lessons?: Array<string>;
    mastered_lessons_updated_at?: number;
    glossaries?: Record<string, string>;
    glossaries_updated_at?: string;
}

const defaultState: TopicLessonQuestionState = {
    topics: undefined,
    topics_updated_at: undefined,
    mastered_lessons: undefined,
    mastered_lessons_updated_at: undefined,
    glossaries: undefined,
    glossaries_updated_at: undefined,
}

export const topicLessonQuestionSlice = createSlice({
    name: 'topic-lesson-question',
    initialState: defaultState,
    reducers: {
        set_topics: (state: TopicLessonQuestionState, action: { payload: Array<TopicWithLesson> }) => {
            state.topics = action.payload;
            state.topics_updated_at = moment().unix();
        },
        set_mastered_lessons: (state: TopicLessonQuestionState, action: { payload: Array<string> }) => {
            state.mastered_lessons = action.payload;
            state.mastered_lessons_updated_at = moment().unix();
        },
        set_glossaries: (state: TopicLessonQuestionState, action: { payload: Array<Glossary> }) => {
            const tmp: Record<string, string> = {};
            for (let i = 0; i < action.payload.length; i++) {
                tmp[action.payload[i].word] = action.payload[i].definition;
            }
            state.glossaries = tmp;
            state.glossaries_updated_at = moment().format("YYYY-MM-DD HH:mm:ss");
        },
        clear_topic_lesson_question: (state: TopicLessonQuestionState) => {
            state = defaultState;
        }
    },
})

export const {
    set_topics,
    set_mastered_lessons,
    set_glossaries,
    clear_topic_lesson_question,
} = topicLessonQuestionSlice.actions
export const topicLessonQuestionReducer = topicLessonQuestionSlice.reducer