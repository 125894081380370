import React, { ReactNode, useEffect } from 'react';
import { Provider } from 'react-redux'
import { persistor, store } from './stores'
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as BusProvider } from 'react-bus';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';

interface ProviderProps {
  children: ReactNode;
}

const googleClientId = process.env.NX_GOOGLE_CLIENT_ID ?? '';

export const Providers: React.FC<ProviderProps> = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider clientId={googleClientId}>
          <BusProvider>
            {children}
            <ToastContainer />
          </BusProvider>
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  );
};