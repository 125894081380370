import axios, { type AxiosError } from 'axios';
import { Account, User } from "@prisma/client";
import { API_BACKEND_ENDPOINT } from '../config';
import { TokenResponse } from '@react-oauth/google';

class AccountService {
    async selfAccount(selfId: string) {
        return new Promise<User>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/user/${selfId}`).then((res: { data: User }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    async updateLastCheckedAtNewHands() {
        return new Promise<User>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/user/update-last-checked-at-new-hands`, undefined).then((res: { data: User }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    verifyEmail() {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/auth/send-verify-link`).then((res: { data: boolean }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getConnectedAccounts() {
        return new Promise<Account[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/user/get-connected-accounts`).then((res: { data: Account[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async disconnectAccount(account_id: string) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/user/disconnect-account`, { account_id }).then((res: { data: boolean }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async removeCLCCustomerRole() {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/user/remove-clc-customer-role`).then((res: { data: boolean }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getInvited() {
        return new Promise<User | null>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/user/get-invited`).then((res: { data: User | null }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async updatePassword(old_password: string, new_password: string) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/user/update-password`, { old_password, new_password }).then((res: { data: boolean }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async connectGoogleAccount(codeResponse: TokenResponse) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/auth/connect-google`, codeResponse).then((res: { data: boolean }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getMasteredTopicAndLessonIds() {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/user/mastered-topic-and-lesson-ids`).then((res: { data: boolean }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async updateRole() {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/auth/update-role`).then((res: { data: boolean }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async updateIntroSeen() {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/user/update-intro-seen`).then((res: { data: boolean }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async updateProfile(user_id: string, user_data: Partial<User>) {
        return new Promise<User>((resolve, reject) => {
            axios.patch(`${API_BACKEND_ENDPOINT}/user/${user_id}`, user_data).then((res: { data: User }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getDiscordStatus() {
        return new Promise<User>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/user/discord-status`).then((res: { data: User }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async updatePlayingCard(playing_card: string) {
        return new Promise<User>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/user/update-playing-card`, {
                playing_card
            }).then((res: { data: User }) => {
                resolve(res.data)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
}

export const accountService = new AccountService();
