import React, { useMemo } from 'react';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { DateRange } from 'react-day-picker';
import lodash from 'lodash';

import {
    Cross2Icon,
    CheckCircledIcon,
    CheckIcon,
    CrossCircledIcon,
    Half1Icon,
    CalendarIcon
} from '@radix-ui/react-icons';
import { ClcSpinner } from '@clc-v2/uis';
import { historyService } from '../../services/history.service';
import { Accordion, AccordionItem, AccordionContent, AccordionTrigger } from '../../components/general/accordion';
import { DatePickerWithRange } from '../../components/general/date-range-picker';
import { cn } from '../../libs/utils';
import classNames from 'classnames';

type LearningHistory = {
    id: string;
    userId: string;
    handNumber: number;
    result: boolean;
    date: string;
    type: string;
}

enum LearningHistoryMode {
    Daily = 'daily',
    Lifetime = 'lifetime'
}

export function LearnHistory(): JSX.Element {
    const [date, setDate] = React.useState<DateRange | undefined>({
        from: moment().subtract(7, 'days').toDate(),
        to: moment().toDate()
      })

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [, setError] = useState<string>('');

    const [learningHistories, setLearningHistories] = useState<Array<LearningHistory>>([]);

    const [viewMode, setViewMode] = useState<LearningHistoryMode>(LearningHistoryMode.Daily);

    useEffect(() => {
        if (!date) return;

        setIsLoading(true);
        
        const startDate = moment(date.from).format('YYYY-MM-DD');
        const endDate = moment(date.to).format('YYYY-MM-DD');

        historyService.getEarningHistory(startDate, endDate)
            .then((histories) => {
                const normalizedHistories = histories.map((history) => ({
                    id: history.id,
                    userId: history.user_id,
                    handNumber: history.hand_number,
                    date: history.date,
                    type: history.type,
                    result: history.result
                }));
                setLearningHistories(normalizedHistories);
            })
            .catch((error: unknown) => setError(error instanceof Error ? error.message : JSON.stringify(error)))
            .finally(() => setIsLoading(false));
    }, [date]);

    return (
        <div className='container mx-auto font-inter'>
            <h2 className='w-full font-inter font-normal text-white text-3xl text-center py-4'>Learning History</h2>

            <div className='flex justify-end items-center gap-2.5 mb-4'>
                <DatePickerWithRange
                    className='text-primary-golden'
                    date={date}
                    setDate={setDate}
                />

                <HistorySwitch
                    activeValue={viewMode}
                    options={[
                        { label: 'Daily', value: LearningHistoryMode.Daily },
                        { label: 'Lifetime', value: LearningHistoryMode.Lifetime },
                    ]}
                    onSwitchChangeHandler={(value) => new Promise((resolve, _) => {
                        setViewMode(value === LearningHistoryMode.Daily
                            ? LearningHistoryMode.Daily
                            : LearningHistoryMode.Lifetime
                        );
                        resolve(true);
                    })}
                />
            </div>

            {
                isLoading ? (
                    <LearningHistoryLoadingSpinner />
                ) : (
                    learningHistories.length === 0 ? (
                        <LearningHistoryEmptyMessage />
                    ) : (
                        viewMode === LearningHistoryMode.Daily ? (
                            <DailyView data={learningHistories} />
                        ) : (
                            <LifetimeView data={learningHistories} />
                        )
                    )
                )
            }
        </div>
    )
}

function LearningHistoryLoadingSpinner (): JSX.Element {
    return (
        <div className='w-full flex justify-center items-center py-20'>
            <ClcSpinner text='Loading' />
        </div>
    )
}

function LearningHistoryEmptyMessage (): JSX.Element {
    return (
        <div className='w-full flex justify-center items-center py-20'>
            <span className='text-white uppercase'>No History</span>
        </div>
    )
}


type LearningHistoryViewProps = {
    data: Array<LearningHistory>
}

type DailyLearningHistory = {
    handNumber: number
    date: string
    type: string
    correct: number
    incorrect: number
}

type ISODateString = string;

function DailyView ({ data }: LearningHistoryViewProps): JSX.Element {
    const learningHistoriesGroupped = useMemo(() => {
        const histories = data.reduce((prev, curr) => {
            const currentDate = moment(curr.date).format('MM-DD-YYYY');
            const currentHistories = prev.get(currentDate);

            const isCorrect = curr.result ? curr.result : false;

            if (currentHistories && currentHistories.length) {
                const existingHistory = currentHistories.find((history) => history.handNumber === curr.handNumber);
                const existingHistories = currentHistories.filter((currentHistory) => currentHistory.handNumber !== curr.handNumber);

                if (existingHistory) {
                    const updatedHistory = {
                        handNumber: existingHistory.handNumber,
                        date: existingHistory.date,
                        type: existingHistory.type,
                        correct: isCorrect ? existingHistory.correct + 1 : existingHistory.correct,
                        incorrect: !isCorrect ? existingHistory.incorrect + 1 : existingHistory.incorrect
                    };

                    prev.set(currentDate, [...existingHistories, updatedHistory]);
                } else {
                    const newHistory = {
                        handNumber: curr.handNumber,
                        date: curr.date,
                        type: curr.type,
                        correct: isCorrect ? 1 : 0,
                        incorrect: !isCorrect ? 1 : 0
                    };

                    prev.set(currentDate, [...existingHistories, newHistory]);
                }
            } else {
                const newHistory = {
                    handNumber: curr.handNumber,
                    date: curr.date,
                    type: curr.type,
                    correct: isCorrect ? 1 : 0,
                    incorrect: !isCorrect ? 1 : 0
                };
                prev.set(currentDate, [newHistory]);
            }

            return prev;
        }, new Map<ISODateString, Array<DailyLearningHistory>>);

        return Array.from(histories)
            .map(([key, value]) => ({ name: key, histories: value }))
            .sort((a, b) => moment(a.name, 'MM-DD-YYYY').valueOf() - moment(b.name, 'MM-DD-YYYY').valueOf());
    }, [data]);

    return (
        <div>
            {learningHistoriesGroupped.map((group) => (
                <Accordion type='single' collapsible className='w-full font-inter font-normal text-primary-golden'>
                    <AccordionItem value='item-3'>
                        <AccordionTrigger>
                            <p className='flex gap-1 items-center'>
                                <CalendarIcon />
                                {group.name}
                            </p>
                        </AccordionTrigger>
                        <AccordionContent>
                            <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-2'>
                            {group.histories.map((history) => (
                                <LearningHistoryCard
                                    handNumber={history.handNumber}
                                    correct={history.correct}
                                    incorrect={history.incorrect}
                                    date={history.date}
                                    displayDate={false}
                                />
                            ))}
                            </div>
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            ))}
        </div>
    )
}

type LearningHistoryBadge = {
    correct: number
    incorrect: number
}

function Badge ({ correct, incorrect }: LearningHistoryBadge): JSX.Element {
    return (
        <div className={cn(
            correct > incorrect ? 'bg-green' : '',
            correct < incorrect ? 'bg-red-500' : '',
            correct === incorrect ? 'bg-yellow-500' : '',
            'flex h-4 w-4 rounded-full items-center text-center')
        }>
            {correct === incorrect
                ? <Half1Icon className='h-4 w-4'/>
                : correct > incorrect
                    ? <CheckIcon className='h-4 w-4' />
                    : <Cross2Icon className='h-4 w-4'/>}
        </div>
    );
}

type ID = string;

type LifetimeLearningHistory = {
    id: ID
    handNumber: number
    date: string
    type: string
    correct: number
    incorrect: number
}

function LifetimeView ({ data }: LearningHistoryViewProps): JSX.Element {
    const learningHistories = useMemo(() => {
        const normalizedLearningHistories = data.reduce((prev, curr) => {
            const handNumber = curr.handNumber;
            const currentDate = moment(curr.date).format('MM-DD-YYYY');

            const existingElement = prev.get(`${currentDate}-${handNumber}`);
            const isCorrect = curr.result ? curr.result : false;
    
            if (existingElement && moment(existingElement.date).format('MM-DD-YYYY') === currentDate) {
                prev.set(curr.id, {
                    ...existingElement,
                    correct: isCorrect ? existingElement.correct + 1 : existingElement.correct,
                    incorrect: !isCorrect ? existingElement.incorrect + 1 : existingElement.incorrect
                });
            } else {
                prev.set(curr.id, {
                    id: `${currentDate}-${handNumber}`,
                    handNumber: curr.handNumber,
                    date: curr.date,
                    type: curr.type,
                    correct: isCorrect ? 1 : 0,
                    incorrect: !isCorrect ? 1 : 0
                });
            }
    
            return prev;
        }, new Map<ID, LifetimeLearningHistory>)

        return Array.from(normalizedLearningHistories.values()).sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())

    }, [data]);

    return (
        <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-2'>
            {learningHistories.map((history) => (
                <LearningHistoryCard
                    handNumber={history.handNumber}
                    correct={history.correct}
                    incorrect={history.incorrect}
                    date={history.date}
                />
            ))}
        </div>
    )
}

type LearningHistoryCardProps = {
    handNumber: number
    correct: number
    incorrect: number
    date: string
    displayDate?: boolean
}

function LearningHistoryCard ({
    handNumber,
    correct,
    incorrect,
    date,
    displayDate = true
}: LearningHistoryCardProps): JSX.Element {
    return (
        <div key={handNumber} className='min-w-[50px] p-5 border border-color-2 hover:border-primary-golden hover:bg-color-2 rounded-xl font-inter relative'>
            <div className='text-black absolute top-5 right-5'>
                <Badge correct={correct} incorrect={incorrect} />
            </div>

            {displayDate && (
                <div className='flex flex-row space-x-1 items-start text-primary-golden'>
                    <span className='text-xs text-center text-primary-golden'>{moment(date).format('MM-DD-YYYY')}</span>
                </div>
            )}

            <div className='flex flex-col text-primary-golden gap-1 items-start'>
                <p className='font-bold text-sm text-white tracking-wide'>{handNumber}</p>

                <div className='flex space-x-1 gap-1 items-start'>
                    <div className='flex flex-row space-x-1'>
                        <CheckCircledIcon className='h-4 w-4' />
                        <span className='text-xs text-center '>{correct}</span>
                    </div>

                    <div className='flex flex-row space-x-1'>
                        <CrossCircledIcon className='h-4 w-4' />
                        <span className='text-xs text-center '>{incorrect}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export interface HistorySwitchProps<T> {
  activeValue: T;
  options: Array<{
    label: React.ReactNode;
    value: T;
  }>,
  onSwitchChangeHandler: (value: T) => Promise<boolean>;
}

export function HistorySwitch(props: HistorySwitchProps<string>) {
  const [loading, setLoading] = useState<boolean>(false);
  const onSwitchChangeHandler = lodash.debounce((newTab: string) => {
    setLoading(true);
    props.onSwitchChangeHandler(newTab).then((value) => {
      setLoading(false);
    });
  }, 500);

  return (
    <div className='w-fit p-1 border border-solid border-color-2 rounded-full relative'>
      <div className={classNames(
        'flex flex-row items-center rounded-full',
        { 'bg-gradient-to-r from-color-2 to-color-3': loading }
      )}>
        {props.options.map((option, id) => (
          <button
            key={id}
            className={classNames(
              { 'bg-gradient-to-r from-color-2 to-color-3': props.activeValue === option.value && !loading },
              'flex px-1.5 md:px-3 lg:px-6 justify-center items-center rounded-full',
              { 'text-white': props.activeValue === option.value, 'text-secondary-grey': props.activeValue !== option.value },
              'text-[10px] leading-[12px] tracking-[2px] font-light'
            )}
            disabled={loading}
            onClick={() => onSwitchChangeHandler(option.value)}
          >
            <p className='font-inter font-light text-xs tracking-wide p-1'>{option.label}</p>
          </button>
        ))}
      </div>
    </div>
  );
}
