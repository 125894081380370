import { createSlice } from '@reduxjs/toolkit'

export interface TempState {
    // accessToken: string;
}

const defaultState: TempState = {
    // accessToken: '',
}

export const tempSlice = createSlice({
    name: 'temp',
    initialState: defaultState,
    reducers: {
        // setAccessToken: (state: TempState, action) => {
        //     state.accessToken = action.payload
        // },
    },
})

export const {
    // setAccessToken
} = tempSlice.actions
export const tempReducer = tempSlice.reducer