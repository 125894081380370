import { createSlice } from '@reduxjs/toolkit'
import moment, { Moment } from 'moment-timezone';
import { LeaderboardItemData } from '@clc-v2/uis';

export interface LeaderboardState {
    daily_leaders?: LeaderboardItemData[];
    daily_me?: LeaderboardItemData;
    // daily_updated_at?: string;
    weekly_leaders?: LeaderboardItemData[];
    weekly_me?: LeaderboardItemData;
    // weekly_updated_at?: string;
    monthly_leaders?: LeaderboardItemData[];
    monthly_me?: LeaderboardItemData;
    // monthly_updated_at?: string;
    yearly_leaders?: LeaderboardItemData[];
    yearly_me?: LeaderboardItemData;
    // yearly_updated_at?: string;
    lifetime_leaders?: LeaderboardItemData[];
    lifetime_me?: LeaderboardItemData;
    // lifetime_updated_at?: string;
}

// export function isLeaderboardState(obj: any): obj is LeaderboardState {
//     return typeof obj === 'object' &&
//         obj.hasOwnProperty('daily_leaders') &&
//         obj.hasOwnProperty('daily_me') &&
//         obj.hasOwnProperty('daily_updated_at') &&
//         obj.hasOwnProperty('weekly_leaders') &&
//         obj.hasOwnProperty('weekly_me') &&
//         obj.hasOwnProperty('weekly_updated_at') &&
//         obj.hasOwnProperty('monthly_leaders') &&
//         obj.hasOwnProperty('monthly_me') &&
//         obj.hasOwnProperty('monthly_updated_at') &&
//         obj.hasOwnProperty('yearly_leaders') &&
//         obj.hasOwnProperty('yearly_me') &&
//         obj.hasOwnProperty('yearly_updated_at') &&
//         obj.hasOwnProperty('lifetime_leaders') &&
//         obj.hasOwnProperty('lifetime_me') &&
//         obj.hasOwnProperty('lifetime_updated_at');
// }

const defaultState: LeaderboardState = {
    daily_leaders: undefined,
    daily_me: undefined,
    // daily_updated_at: undefined,
    weekly_leaders: undefined,
    // weekly_updated_at: undefined,
    monthly_leaders: undefined,
    // monthly_updated_at: undefined,
    yearly_leaders: undefined,
    // yearly_updated_at: undefined,
    lifetime_leaders: undefined,
    // lifetime_updated_at: undefined,
}

export const leaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState: defaultState,
    reducers: {
        set_daily_leaders: (
            state: LeaderboardState,
            action: {
                payload: {
                    users: LeaderboardItemData[],
                    me?: LeaderboardItemData,
                }
            }
        ) => {
            state.daily_leaders = action.payload.users;
            state.daily_me = action.payload.me;
            // state.daily_updated_at = moment().format('YYYY-MM-DD HH:mm:ss');
        },
        set_weekly_leaders: (
            state: LeaderboardState,
            action: {
                payload: {
                    users: LeaderboardItemData[],
                    me?: LeaderboardItemData,
                }
            }
        ) => {
            state.weekly_leaders = action.payload.users;
            state.weekly_me = action.payload.me;
            // state.weekly_updated_at = moment().format('YYYY-MM-DD HH:mm:ss');
        },
        set_monthly_leaders: (
            state: LeaderboardState,
            action: {
                payload: {
                    users: LeaderboardItemData[],
                    me?: LeaderboardItemData,
                }
            }
        ) => {
            state.monthly_leaders = action.payload.users;
            state.monthly_me = action.payload.me;
            // state.monthly_updated_at = moment().format('YYYY-MM-DD HH:mm:ss');
        },
        set_yearly_leaders: (
            state: LeaderboardState,
            action: {
                payload: {
                    users: LeaderboardItemData[],
                    me?: LeaderboardItemData,
                }
            }
        ) => {
            state.yearly_leaders = action.payload.users;
            state.yearly_me = action.payload.me;
            // state.yearly_updated_at = moment().format('YYYY-MM-DD HH:mm:ss');
        },
        set_lifetime_leaders: (
            state: LeaderboardState,
            action: {
                payload: {
                    users: LeaderboardItemData[],
                    me?: LeaderboardItemData,
                }
            }
        ) => {
            state.lifetime_leaders = action.payload.users;
            state.lifetime_me = action.payload.me;
            // state.lifetime_updated_at = moment().format('YYYY-MM-DD HH:mm:ss');
        },
        clear_leaderboard_data: (state: LeaderboardState) => {
            state = defaultState;
        }
    },
})

export const {
    set_daily_leaders,
    set_weekly_leaders,
    set_monthly_leaders,
    set_yearly_leaders,
    set_lifetime_leaders,
    clear_leaderboard_data,
} = leaderboardSlice.actions
export const leaderboardReducer = leaderboardSlice.reducer