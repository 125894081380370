import { Author, VideoItem } from "@clc-v2/uis";
import { useAppSelector } from "../../stores/hooks"
import { LibraryVideoWithTag, embedVideo } from "@clc-v2/utilities";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { LibraryAuthor, LibraryType } from "@prisma/client";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import pluralize from "pluralize";
import Player from '@vimeo/player';
import { libraryService } from "../../services/library.service";

export const VideoPlayer = (props: {
    type: LibraryType;
    authors: LibraryAuthor[];
    videos: LibraryVideoWithTag[];
    className?: string;
}) => {
    const { library_video_watched } = useAppSelector((state) => state.library);
    const [search_params, set_search_params] = useSearchParams();
    const [video_url, set_video_url] = useState<string>('');
    const vimeo_ref = useRef<HTMLIFrameElement>(null);
    const ref = useRef<HTMLElement>() as MutableRefObject<HTMLInputElement>;
    const { events } = useDraggable(ref);
    const [scrollable, set_scrollable] = useState<boolean>(false);
    const [loading, set_loading] = useState<boolean>(true);
    const [reward, set_reward] = useState<number>(0);
    const [show_reward, set_show_reward] = useState<boolean>(false);
    const on_change_active_video = (new_video_id: string) => {
        set_search_params((_prev) => {
            _prev.delete('video_id')
            _prev.append('video_id', new_video_id)
            return _prev
        })
    }
    useEffect(() => {
        const selected_video_id = search_params.get('video_id');
        if (selected_video_id) {
            const selected_video = props.videos.filter((video) => video.id === selected_video_id)?.[0];
            if (selected_video) {
                set_video_url(selected_video.video_url !== "" ? selected_video.video_url : selected_video.video_thumbnail);
            }
        }
    }, [props.videos, search_params.get('video_id')]);
    useEffect(() => {
        if (ref.current.clientHeight < ref.current.scrollHeight) {
            set_scrollable(true);
        } else {
            set_scrollable(false);
        }
    }, [ref.current?.clientHeight, ref.current?.scrollHeight]);
    useEffect(() => {
        if (video_url) {
            set_loading(true);
            setTimeout(() => {
                set_loading(false);
            }, 1000);
        }
    }, [video_url]);
    useEffect(() => {
        let player: Player;
        if (!loading) {
            const elVideoPlayer = document.getElementById("videoPlayer");
            if (elVideoPlayer) {
                player = new Player(elVideoPlayer);
                player.on('play', () => {
                    console.log('The video started playing');
                });
                player.on('ended', () => {
                    console.log('The video ended');
                    // You can add any additional logic here
                    const selected_video_id = search_params.get('video_id');
                    if (selected_video_id) {
                        libraryService.updateWatchedWithReward(selected_video_id).then((reward_chip) => {
                            console.log(reward_chip);
                            if (reward_chip) {
                                set_show_reward(true);
                                set_reward(reward_chip);
                                setTimeout(() => {
                                    set_show_reward(false);
                                    set_reward(0);
                                }, 5000);
                            }
                        }).catch((err_msg: string) => {
                            console.log(err_msg);
                        });
                    }
                });
            }
        }
        return () => {
            if (player) {
                player.off('play');
            }
        };
    }, [loading]);
    return (
        <div className={classNames(
            // "grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 items-start gap-4",
            "w-full flex flex-col md:flex-row items-start gap-4",
            props.className
        )}>
            <div className="w-full md:grow md:shrink bg-black flex justify-center items-center relative">
                {video_url ? (
                    loading ? (
                        <div className="w-full h-[420px] md:h-[540px] flex justify-center items-center">
                            <span>Loading</span>
                        </div>
                    ) : (
                        <>
                            <iframe
                                ref={vimeo_ref}
                                id="videoPlayer"
                                className="w-full h-[420px] md:h-[540px]"
                                width="100%"
                                height="100%"
                                style={{ backgroundColor: '#000' }}
                                src={embedVideo(video_url)}
                                // src={video_url}
                                allowFullScreen
                            />
                            {show_reward && <span className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-primary-golden text-3xl font-inter"><b>+{reward}</b></span>}
                        </>
                    )
                ) : (
                    <span className="text-white">Failed To Load</span>
                )}
            </div>
            <div className="grow-0 shrink-0 w-full md:w-[320px] h-[420px] md:h-[540px] col-span-1 flex flex-col">
                <p className="shrink-0 grow-0 uppercase text-secondary-grey text-xs font-light leading-[14px] tracking-[2px] font-inter">Instructed By</p>
                <div className="shrink-0 grow-0 w-full flex justify-between items-end flex-wrap py-2 border-b border-primary-dark-grey">
                    <div className="flex items-center gap-x-2 flex-wrap">
                        {(props.authors ?? []).map((author) => <Author key={author.id} author={{
                            given_name: author.last_name.toUpperCase() ?? '',
                            family_name: author.first_name.toUpperCase() ?? '',
                            picture: author.picture ?? '',
                            thumbnail_picture: author.thumbnail_picture ?? ''
                        }} />)}
                    </div>
                    <span className="text-secondary-grey text-[11px] leading-[14px] font-normal font-inter capitalize">{props.videos.length} {pluralize(props.type.replace('_', ' '))}</span>
                </div>
                <div className="shrink grow w-full h-[350px] md:h-[460px] relative mt-4">
                    <div
                        className={classNames(
                            "w-full max-h-full shrink grow flex flex-col gap-0 overflow-auto thin-scrollbar",
                            // {
                            //     "pb-4": scrollable
                            // }
                        )}
                        ref={ref}
                        {...events}
                    >
                        {props.videos.map((video) => (
                            <VideoItem
                                key={video.id}
                                video={{
                                    title: video.title,
                                    video_duration: video.video_duration
                                }}
                                active={video.id === search_params.get('video_id')}
                                seen={(library_video_watched ?? []).filter(({ watched }) => watched).map(({ library_video_id }) => library_video_id).indexOf(video.id) >= 0}
                                on_click={() => on_change_active_video(video.id)}
                            />
                        ))}
                    </div>
                    {scrollable && (
                        <div className="absolute left-0 bottom-0 w-full h-16 bg-gradient-to-b from-transparent to-color-1 pointer-events-none"></div>
                    )}
                </div>
            </div>
        </div>
    )
}