import { useEffect, useState } from "react"
import { LeftSidePanel, Topbar } from "@clc-v2/uis"
import { useAppDispatch, useAppSelector } from "../stores/hooks";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthCookieName, loginUrl } from "../app-routes";
import moment from "moment";
import { Footer } from "../components/layout/footer";
import Cookies from "js-cookie";
import { accountService } from "../services/account.service";
import storage from 'redux-persist/lib/storage';
import { STORE_KEY } from "../stores";
import classNames from "classnames";
import { expired } from "@clc-v2/utilities";
import Modal from "react-responsive-modal";
import { IntroVideo } from "../components/common/intro-video";
import { set_hidden_discord_message } from "../stores/data";
import { IcClose, IcClose1, IcClose2 } from "@clc-v2/icons";

const urls_without_footer = ['intro', 'learn-by-lesson', 'learn-by-ai', 'learn-by-hand-number', 'unplayed-new-handed'];

export const Layout2 = () => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.auth);
    const { subscription } = useAppSelector((state) => state.payment);
    const { current_time, hidden_discord_message } = useAppSelector((state) => state.data);
    const navigate = useNavigate();
    const location = useLocation();
    const [opened, set_opened] = useState<boolean>(false);
    const [confirming_subscription, set_confirming_subscription] = useState<boolean>(true);
    const [show_footer, set_show_footer] = useState<boolean>(true);
    const [hide_info_in_topbar, set_hide_info_in_topbar] = useState<boolean>(false);
    const [open_intro_video, set_open_intro_video] = useState<boolean>(false);
    useEffect(() => {
        console.log('Remove CLC Role')
        if (subscription === undefined) {
            // remove-clc-customer-role
            accountService.removeCLCCustomerRole().then((success) => { }).catch((err_msg: string) => console.error(err_msg));
            // navigate('/payment');
        }
        set_confirming_subscription(false);
    }, [subscription]);
    useEffect(() => {
        if (urls_without_footer.filter((_url) => location.pathname.includes(_url)).length > 0) {
            set_show_footer(false);
        } else {
            set_show_footer(true);
        }
        if (location.pathname.includes('intro')) {
            set_hide_info_in_topbar(true);
        } else {
            set_hide_info_in_topbar(false);
        }
    }, [location]);
    if (confirming_subscription) {
        return (
            <div></div>
        )
    } else {
        return (
            <div className="w-full overflow-hidden min-h-screen py-6 px-4 md:px-10 bg-black-dark font-inter">
                <div className="fixed left-0 top-6 w-full px-10 z-50">
                    <Topbar
                        hideMenu={hide_info_in_topbar}
                        hideUser={hide_info_in_topbar}
                        openHandler={() => set_opened((_prev) => !_prev)}
                        opened={opened}
                        user={user}
                        current_time={current_time}
                        logoClickHandler={() => navigate('/dashboard')}
                    />
                </div>
                <div className="relative w-full" onClick={() => set_opened(false)}>
                    <div className={`fixed ml-10 transition-all transform ${opened ? 'left-0 translate-x-0' : '-left-10 -translate-x-full'} z-50`}>
                        <LeftSidePanel
                            accountHandler={() => {
                                navigate('/account');
                                set_opened(false);
                            }}
                            aiLearningHandler={() => {
                                navigate('/learning/learn-by-ai');
                                set_opened(false);
                            }}
                            clcStoreHandler={() => {
                                window.open('https://chip-leader-coaching-store.myshopify.com/', '_blank')
                            }}
                            homeHandler={() => {
                                navigate('/dashboard');
                                set_opened(false);
                            }}
                            logoutHandler={() => {
                                storage.removeItem(`persist:${STORE_KEY}`);
                                Cookies.remove(AuthCookieName);
                                setTimeout(() => {
                                    window.location.href = loginUrl;
                                }, 500);
                            }}
                            performanceHandler={() => {
                                navigate('/performance/me');
                                set_opened(false);
                            }}
                            pickYourPathHandler={() => {
                                navigate('/learning/pick-your-path');
                                set_opened(false);
                            }}
                            studyByHandNumberHandler={() => {
                                navigate('/learning/learn-by-hand-number');
                                set_opened(false);
                            }}
                            videoLibraryHandler={() => {
                                navigate('/library/list/webinars');
                                set_opened(false);
                            }}
                            newHandsHandler={() => {
                                navigate('/learning/unplayed-new-handed');
                            }}
                            learningHistoryHandler={() => {
                                navigate('/learning/history');
                            }}
                            tutorialHandler={() => {
                                set_open_intro_video(true);
                            }}
                        />
                    </div>
                    <div className={classNames(`w-full mt-20 relative`, {
                        'min-h-[calc(100vh-80px-300px)]': show_footer,
                        'min-h-[calc(100vh-80px-48px)]': !show_footer,
                    })} onClick={() => {
                        if (opened) {
                            set_opened(false);
                        }
                    }}>
                        {location.pathname !== '/account' && !hidden_discord_message && user && !user.discord_joint && (
                            <div className="w-full py-2 my-2 rounded-full flex justify-center gap-1 relative cursor-pointer" onClick={() => navigate('/account?active_tab=connected-accounts')}>
                                <p className="font-inter text-white text-center">Join our Discord to earn more XP and connect with like-minded players to improve your game.</p>
                                <button className="text-primary-golden transform -translate-y-1" onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                        set_hidden_discord_message(true)
                                    )
                                }}>
                                    x
                                </button>
                            </div>
                        )}
                        {(subscription !== undefined && expired(subscription.next_billing_at, 12 * 60)) && <div className="w-full flex justify-center bg-secondary-grey mb-2">
                            <p className="text-white">Your subscription has been expired on {moment.unix(subscription.next_billing_at).format('YYYY-MM-DD')}</p>
                        </div>}
                        <Outlet />
                    </div>
                    {show_footer && <Footer opened={false} set_opened={() => { }} />}
                </div>
                <Modal classNames={{
                    modal: "!bg-primary-carbon border border-primary-golden"
                }} center open={open_intro_video} onClose={() => set_open_intro_video(false)} showCloseIcon={false}>
                    <div className="w-full md:w-[748px] h-[350px] md:h-[460px] max-h-[calc(100vh-4.8rem)]">
                        <IntroVideo />
                    </div>
                </Modal>
            </div>
        )
    }
}