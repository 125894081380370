import { ProgressChart, Switch } from "@clc-v2/uis";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import moment from "moment";
import { historyService } from "../../services/history.service";
import { set_progress_monthly_history, set_progress_weekly_history } from "../../stores/performance";
import { expired } from "@clc-v2/utilities";

export const ProgressChartContainer = () => {
    const dispatch = useAppDispatch();
    const { progress_monthly_history, progress_monthly_history_updated_at, progress_weekly_history, progress_weekly_history_updated_at } = useAppSelector((state) => state.performance);
    const [graph_active_tab, set_graph_active_tab] = useState<string>('month');
    const [loading, set_loading] = useState<boolean>(false);

    useEffect(() => {
        if (graph_active_tab === 'month' && (progress_monthly_history_updated_at === undefined || expired(progress_monthly_history_updated_at, 0))) {
            set_loading(true);
            historyService.getMonthlyProgress().then((_daily_history) => {
                const _progress_chart_data: Record<string, { question: number; ticket: number; }> = {};
                const len = moment().daysInMonth();
                for (let i = 0; i < len; i++) {
                    const date = moment().date(i + 1).format('YYYY-MM-DD');
                    _progress_chart_data[date] = {
                        question: 0,
                        ticket: 0
                    };
                }
                for (const data of _daily_history) {
                    // const date = moment.utc(data.date).local().format('YYYY-MM-DD');
                    const date = data.user_date ?? data.date
                    if (_progress_chart_data[date]) {
                        _progress_chart_data[date].question += data.correct;
                        _progress_chart_data[date].ticket += data.earned_tickets;
                    } else {
                        _progress_chart_data[date] = {
                            question: data.correct,
                            ticket: data.earned_tickets
                        };
                    }
                }
                dispatch(
                    set_progress_monthly_history(Object.keys(_progress_chart_data).sort((date1, date2) => date1 < date2 ? -1 : 1).map((date) => ({ date, ..._progress_chart_data[date] })))
                );
                set_loading(false);
            }).catch((err_msg: string) => {
                console.error(err_msg);
            });
        }
    }, [progress_monthly_history_updated_at, graph_active_tab]);

    useEffect(() => {
        if (graph_active_tab === 'week' && (progress_weekly_history_updated_at === undefined || expired(progress_weekly_history_updated_at, 0))) {
            set_loading(true);
            historyService.getWeeklyProgress().then((_daily_history) => {
                const _progress_chart_data: Record<string, { question: number; ticket: number; }> = {};
                const len = 7;
                for (let i = 0; i < len; i++) {
                    const date = moment().local().weekday(i + 1).format('YYYY-MM-DD');
                    _progress_chart_data[date] = {
                        question: 0,
                        ticket: 0
                    };
                }
                for (const data of _daily_history) {
                    // const date = moment(data.user_date).local().format('YYYY-MM-DD');
                    const date = data.user_date ?? data.date;
                    if (_progress_chart_data[date]) {
                        _progress_chart_data[date].question += data.correct;
                        _progress_chart_data[date].ticket += data.earned_tickets;
                    } else {
                        _progress_chart_data[date] = {
                            question: data.correct,
                            ticket: data.earned_tickets
                        };
                    }
                }
                dispatch(
                    set_progress_weekly_history(Object.keys(_progress_chart_data).sort((date1, date2) => date1 < date2 ? -1 : 1).map((date) => ({ date, ..._progress_chart_data[date] })))
                );
                set_loading(false);
            }).catch((err_msg: string) => {
                console.error(err_msg);
            });
        }
    }, [progress_weekly_history_updated_at, graph_active_tab]);

    return (
        <>
            <div className="w-full flex justify-between items-center mb-4">
                <div className="flex items-start flex-wrap gap-2 font-inter font-light">
                    <span className="text-primary-golden uppercase text-xs leading-[14px] tracking-[2px]">Progress</span>
                    <span className="text-white uppercase text-xs leading-[14px] tracking-[2px]">This Month</span>
                </div>
                <Switch
                    activeValue={graph_active_tab}
                    options={[
                        { label: "WEEKLY", value: "week" },
                        { label: "MONTHLY", value: "month" },
                    ]}
                    onSwitchChangeHandler={(newTab) => new Promise<boolean>((resolve, reject) => {
                        set_graph_active_tab(newTab);
                        resolve(true);
                    })}
                />
            </div>
            {loading ? (
                <div>Loading</div>
            ) : (
                <ProgressChart
                    data={graph_active_tab === 'week' ? (progress_weekly_history ?? []) : (graph_active_tab === 'month' ? (progress_monthly_history ?? []) : (progress_monthly_history ?? []))}
                    mode={graph_active_tab as 'month' | 'week'}
                />
            )}
        </>
    )
}