import axios, { AxiosError } from "axios";
import { API_BACKEND_ENDPOINT } from "../config";
import { LibraryAuthor, LibraryVideo, LibraryVideoTag, LibraryVideoWatched, User } from "@prisma/client";
import { ILibraryVideoWithTagWithAuthor, LibraryAuthorWithUser, LibraryVideoWithTag } from "@clc-v2/utilities";

class LibraryService {
    async getWebinars(search_word: string) {
        return new Promise<Array<ILibraryVideoWithTagWithAuthor>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/library-video/webinars?search=${search_word}`).then((res: { data: ILibraryVideoWithTagWithAuthor[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getLessons(search_word: string) {
        return new Promise<Array<ILibraryVideoWithTagWithAuthor>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/library-video/lessons?search=${search_word}`).then((res: { data: ILibraryVideoWithTagWithAuthor[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getOnlineSessions(search_word: string) {
        return new Promise<Array<ILibraryVideoWithTagWithAuthor>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/library-video/online-sessions?search=${search_word}`).then((res: { data: ILibraryVideoWithTagWithAuthor[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getQuizzes(search_word: string) {
        return new Promise<Array<ILibraryVideoWithTagWithAuthor>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/library-video/quizzes?search=${search_word}`).then((res: { data: ILibraryVideoWithTagWithAuthor[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getVideoTags() {
        return new Promise<Array<LibraryVideoTag>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/video-tag`).then((res: { data: LibraryVideoTag[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getFeaturedVideos() {
        return new Promise<Array<ILibraryVideoWithTagWithAuthor>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/library-video/featured`).then((res: { data: ILibraryVideoWithTagWithAuthor[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getWatchedVideos(user_id: string) {
        return new Promise<LibraryVideoWatched[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/library-video-watched?user_id=${user_id}`).then((res: { data: LibraryVideoWatched[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async updateWatched(library_video_id: string, watched: boolean) {
        return new Promise<LibraryVideoWatched>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/library/library-video-watched`, {
                library_video_id,
                watched,
            }).then((res: { data: LibraryVideoWatched }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async updateWatchedWithReward(library_video_id: string) {
        return new Promise<number | null>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/library/library-video-watched/with-reward`, {
                library_video_id
            }).then((res: { data: number | null }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getLibraryAuthors() {
        return new Promise<LibraryAuthorWithUser[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/library-author`).then((res: { data: LibraryAuthorWithUser[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getGroupVideos(video_id: string) {
        return new Promise<{
            library_videos: LibraryVideoWithTag[],
            library_authors: LibraryAuthor[],
        }>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/library-video/group?video_id=${video_id}`).then((res: {
                data: {
                    library_videos: LibraryVideoWithTag[],
                    library_authors: LibraryAuthor[],
                }
            }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getRelatedVideos(video_id: string) {
        return new Promise<LibraryVideoWithTag[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/library-video/related?video_id=${video_id}`).then((res: {
                data: LibraryVideoWithTag[]
            }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    // async getDashboardLibraryVideo() {
    //     return new Promise<ILibraryVideoWithTagWithAuthor>((resolve, reject) => {
    //         axios.get(`${API_BACKEND_ENDPOINT}/library/library-video/dashboard`).then((res: { data: ILibraryVideoWithTagWithAuthor }) => {
    //             resolve(res.data);
    //         }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
    //             if (err.response) {
    //                 reject(err.response.data.message);
    //             } else {
    //                 reject(err.message);
    //             }
    //         });
    //     })
    // }
    async getDashboardLatestVideos() {
        return new Promise<ILibraryVideoWithTagWithAuthor[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/library-video/dashboard-latest-videos`).then((res: { data: ILibraryVideoWithTagWithAuthor[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
}

export const libraryService = new LibraryService();
