import * as React from 'react';
import moment from 'moment';
import { Button } from './button';
import { Calendar } from './calendar';
import { CalendarIcon } from '@radix-ui/react-icons';
import { DateRange } from 'react-day-picker';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from './popover';
import { cn } from '../../libs/utils';
import { format } from 'date-fns';

interface DatePickerWithRangeProps {
  className: string
  date: DateRange | undefined
  setDate: React.Dispatch<React.SetStateAction<DateRange | undefined>>
}

export function DatePickerWithRange({ className, date, setDate }: DatePickerWithRangeProps) {
  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id='date'
            variant={'outline'}
            className={cn(
              'w-[250px] h-fit justify-between group:date-picker border border-color-2 hover:border-primary-golden rounded-full font-inter font-light text-left text-xs',
              !date && 'text-muted-foreground'
            )}
          >
            {date?.from ? (
              date.to ? (
                <span className='font-inter font-light'>
                  {moment(date.from).format('MMM DD, YYYY')} -{' '}
                  {moment(date.to).format('MMM DD, YYYY')}
                </span>
              ) : (
                <span className='font-inter font-light'>{moment(date.from).format('MMM DD, YYYY')}</span>
              )
            ) : (
              <span>Pick a date</span>
            )}
            <CalendarIcon className='h-4 w-4' />
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-auto p-0' align='start'>
          <Calendar
            initialFocus
            mode='range'
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={1}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}
