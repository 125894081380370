import { LeaderboardGroup, Switch } from "@clc-v2/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import { leaderboardService } from "../../services/leaderboard.service";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import { set_daily_leaders, set_lifetime_leaders, set_monthly_leaders, set_weekly_leaders, set_yearly_leaders } from "../../stores/leaderboard";
import moment from "moment";
import { CACHE_TIME } from "../../config";
import lodash from "lodash";

interface PropsLeaderboardContainer {
    periods: Array<'daily' | 'weekly' | 'monthly' | 'yearly' | 'lifetime'>;
}

export const LeaderboardContainer = (props: PropsLeaderboardContainer) => {
    const {
        daily_leaders, daily_me,
        weekly_leaders, weekly_me,
        monthly_leaders, monthly_me,
        yearly_leaders, yearly_me,
        lifetime_leaders, lifetime_me,
    } = useAppSelector((state) => state.leaderboard);
    const { user } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const [active_period, set_active_period] = useState<string>('');
    const [loading1, set_loading1] = useState<boolean>(true);
    const refresh_daily_leaderboard = (_active_period: string) => {
        set_loading1(true);
        leaderboardService.getTop10UsersByPeriod(_active_period).then(({ top_10_users, self_user }) => {
            dispatch(
                set_daily_leaders({
                    users: top_10_users,
                    me: self_user,
                })
            );
        }).catch((err_msg: string) => {

        }).finally(() => {
            set_loading1(false);
        })
    }
    const throttled_refresh_daily_leaderboard = useRef(lodash.throttle(refresh_daily_leaderboard, 60 * 1000)).current;
    const refresh_weekly_leaderboard = (_active_period: string) => {
        set_loading1(true);
        leaderboardService.getTop10UsersByPeriod(_active_period).then(({ top_10_users, self_user }) => {
            dispatch(
                set_weekly_leaders({
                    users: top_10_users,
                    me: self_user,
                })
            );
        }).catch((err_msg: string) => {

        }).finally(() => {
            set_loading1(false);
        })
    }
    const throttled_refresh_weekly_leaderboard = useRef(lodash.throttle(refresh_weekly_leaderboard, 60 * 1000)).current;
    const refresh_monthly_leaderboard = (_active_period: string) => {
        set_loading1(true);
        leaderboardService.getTop10UsersByPeriod(_active_period).then(({ top_10_users, self_user }) => {
            dispatch(
                set_monthly_leaders({
                    users: top_10_users,
                    me: self_user,
                })
            );
        }).catch((err_msg: string) => {

        }).finally(() => {
            set_loading1(false);
        })
    }
    const throttled_refresh_monthly_leaderboard = useRef(lodash.throttle(refresh_monthly_leaderboard, 60 * 1000)).current;
    const refresh_yearly_leaderboard = (_active_period: string) => {
        set_loading1(true);
        leaderboardService.getTop10UsersByPeriod(_active_period).then(({ top_10_users, self_user }) => {
            dispatch(
                set_yearly_leaders({
                    users: top_10_users,
                    me: self_user,
                })
            );
        }).catch((err_msg: string) => {

        }).finally(() => {
            set_loading1(false);
        })
    }
    const throttled_refresh_yearly_leaderboard = useRef(lodash.throttle(refresh_yearly_leaderboard, 60 * 1000)).current;
    const refresh_lifetime_leaderboard = (_active_period: string) => {
        set_loading1(true);
        leaderboardService.getTop10UsersByPeriod(_active_period).then(({ top_10_users, self_user }) => {
            dispatch(
                set_lifetime_leaders({
                    users: top_10_users,
                    me: self_user,
                })
            );
        }).catch((err_msg: string) => {

        }).finally(() => {
            set_loading1(false);
        })
    }
    const throttled_refresh_lifetime_leaderboard = useRef(lodash.throttle(refresh_lifetime_leaderboard, 60 * 1000)).current;
    useEffect(() => {
        set_active_period(props.periods.slice(-1)[0]);
        return () => {
            throttled_refresh_daily_leaderboard.cancel();
            throttled_refresh_weekly_leaderboard.cancel();
            throttled_refresh_monthly_leaderboard.cancel();
            throttled_refresh_yearly_leaderboard.cancel();
            throttled_refresh_lifetime_leaderboard.cancel();
        }
    }, []);
    useEffect(() => {
        switch (active_period) {
            case 'daily':
                throttled_refresh_daily_leaderboard(active_period);
                break;
            case 'weekly':
                throttled_refresh_weekly_leaderboard(active_period);
                break;
            case 'monthly':
                throttled_refresh_monthly_leaderboard(active_period);
                break;
            case 'yearly':
                throttled_refresh_yearly_leaderboard(active_period);
                break;
            case 'lifetime':
                throttled_refresh_lifetime_leaderboard(active_period);
                break;
        }
    }, [active_period]);

    return (
        <div className="w-full flex flex-col items-end gap-4 font-inter">
            <div className="w-full flex items-center justify-between">
                <div className="px-4 font-inter text-xs text-primary-golden font-light leading-[14px] tracking-[2px] uppercase">Chip Leaders</div>
                <Switch
                    activeValue={active_period}
                    options={props.periods.map((period) => ({ label: period.toUpperCase(), value: period }))}
                    onSwitchChangeHandler={(newValue: string) => new Promise<boolean>((resolve, reject) => {
                        set_active_period(newValue);
                        resolve(true);
                    })}
                />
            </div>
            {active_period === 'daily' && <LeaderboardGroup
                leaders={(daily_leaders ?? [])}
                self={daily_me ? { ...daily_me, user } : daily_me}
                refresh_handler={() => refresh_daily_leaderboard('daily')}
            />}
            {active_period === 'weekly' && <LeaderboardGroup
                leaders={(weekly_leaders ?? [])}
                self={weekly_me ? { ...weekly_me, user } : weekly_me}
                refresh_handler={() => refresh_weekly_leaderboard('weekly')}
            />}
            {active_period === 'monthly' && <LeaderboardGroup
                leaders={(monthly_leaders ?? [])}
                self={monthly_me ? { ...monthly_me, user } : monthly_me}
                refresh_handler={() => refresh_monthly_leaderboard('monthly')}
            />}
            {active_period === 'yearly' && <LeaderboardGroup
                leaders={(yearly_leaders ?? [])}
                self={yearly_me ? { ...yearly_me, user } : yearly_me}
                refresh_handler={() => refresh_yearly_leaderboard('yearly')}
            />}
            {active_period === 'lifetime' && <LeaderboardGroup
                leaders={(lifetime_leaders ?? [])}
                self={lifetime_me ? { ...lifetime_me, user } : lifetime_me}
                refresh_handler={() => refresh_lifetime_leaderboard('lifetime')}
            />}
        </div>
    )
}