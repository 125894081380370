import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function scrollToTop(speed: number) {
  const startingY = window.pageYOffset;
  const targetY = 0;
  const distance = Math.abs(startingY - targetY);
  const duration = distance / speed;

  function scrollStep(timestamp: number) {
    const timeElapsed = timestamp - startTime;
    const progress = Math.min(timeElapsed / duration, 1);
    window.scrollTo(0, startingY * (1 - progress));

    if (timeElapsed < duration) {
      requestAnimationFrame(scrollStep);
    }
  }

  const startTime = performance.now();
  requestAnimationFrame(scrollStep);
}

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    scrollToTop(50);
  }, [pathname]);
  return <></>
}

export default ScrollToTop;