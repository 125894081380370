import { Divider, PickPathTopicCard } from "@clc-v2/uis";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import moment from "moment";
import { topicLessonQuestionService } from "../../services/topic-lesson-question.service";
import { CACHE_TIME } from "../../config";
import { set_mastered_lessons, set_topics } from "../../stores/topic-lesson-question";
import { useNavigate } from "react-router-dom";
import { expired } from "@clc-v2/utilities";
import { LearnMore } from "../../components/common/learn-more";

export const PickYourPath = () => {
    const { topics, topics_updated_at, mastered_lessons, mastered_lessons_updated_at } = useAppSelector((state) => state.topicLessonQuestion);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (
            topics_updated_at === undefined || expired(topics_updated_at, 0)
        ) {
            topicLessonQuestionService.getAllTopics().then((_topics) => {
                dispatch(
                    set_topics(_topics)
                );
            }).catch((err_msg: string) => {
                console.error(err_msg);
            });
        }
    }, [topics_updated_at]);
    useEffect(() => {
        if (
            mastered_lessons_updated_at === undefined || expired(mastered_lessons_updated_at, 0)
        ) {
            topicLessonQuestionService.getMasteredLessons().then((_mastered_lessons) => {
                dispatch(
                    set_mastered_lessons(_mastered_lessons.map(({ lesson_uid }) => lesson_uid))
                );
            }).catch((err_msg: string) => {
                console.error(err_msg);
            });
        }
    }, [mastered_lessons_updated_at]);
    return (
        <div className="container mx-auto pt-10 flex flex-col items-center relative">
            <p className="text-primary-golden uppercase font-light text-base leading-[19px] tracking-[2px] font-inter">Lesson Library</p>
            <h3 className="text-white text-3xl font-medium leading-[57px] font-inter">Pick Your Path</h3>
            <div className="border border-color-2 h-4"></div>
            <div className="w-full">
                <Divider />
            </div>
            <div className="border border-color-2 h-10"></div>
            <div className="w-full flex flex-col gap-0 relative">
                <div className="absolute left-1/2 top-0 transform -translate-x-1/2 border border-color-2 h-full z-0"></div>
                {
                    (topics ?? []).map((topic, id) => {
                        const _topic_lessons = topic.lessons ?? []
                        const _topic_lesson_uids = _topic_lessons.map((lesson) => lesson.uid)
                        const _topic_mastered_lesson_uids = _topic_lesson_uids.filter((lesson_uid) => (mastered_lessons ?? []).indexOf(lesson_uid) >= 0)
                        const lessons = [...topic.lessons]
                        const _lessons = lessons.length > 1 ? lessons.sort((a, b) => a.order > b.order ? 1 : -1) : lessons;
                        return (
                            <div className="w-full flex flex-col justify-center items-center overflow-auto mb-10 z-10" key={id}>
                                <PickPathTopicCard
                                    index={id + 1}
                                    title={topic.name}
                                    required_master_level={topic.mastered_require}
                                    lessons={_lessons}
                                    mastered_lesson_uids={_topic_mastered_lesson_uids}
                                    on_lesson_click_handler={(lesson_uid) => new Promise<boolean>((resolve, reject) => {
                                        navigate(`/learning/learn-by-lesson?lesson_uid=${lesson_uid}`);
                                        resolve(true);
                                    })}
                                />
                            </div>
                        )
                    })
                }
                {
                    (topics !== undefined && topics.length === 0) && (
                        <div className="text-white">No paths</div>
                    )
                }
            </div>
            <LearnMore />
        </div>
    )
}