import { CLCAvatars, Level, PrizeSliceText } from "@prisma/client";
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export interface DataState {
    prize_slice_texts?: PrizeSliceText[];
    prize_slice_texts_updated_at?: string;
    current_time?: number;
    count_daily_questions?: number;
    clc_avatars?: CLCAvatars[];
    levels?: Level[];
    levels_updated_at?: number;
    hidden_discord_message?: boolean;
}

// export function isDataState(obj: any): obj is DataState {
//     return typeof obj === 'object' &&
//         obj.hasOwnProperty('prize_slice_texts') &&
//         obj.hasOwnProperty('prize_slice_texts_updated_at');
// }

const defaultState: DataState = {
    prize_slice_texts: undefined,
    prize_slice_texts_updated_at: undefined,
    current_time: undefined,
    count_daily_questions: undefined,
    clc_avatars: undefined,
    levels: undefined,
    levels_updated_at: undefined,
    hidden_discord_message: undefined,
}

export const dataSlice = createSlice({
    name: 'data',
    initialState: defaultState,
    reducers: {
        set_prize_slice_texts: (state: DataState, action: { payload: PrizeSliceText[] }) => {
            state.prize_slice_texts = action.payload;
            state.prize_slice_texts_updated_at = moment().format('YYYY-MM-DD HH:mm:ss');
        },
        set_current_server_time: (state: DataState, action: { payload: number }) => {
            state.current_time = action.payload;
        },
        set_count_daily_question: (state: DataState, action: { payload: number }) => {
            state.count_daily_questions = action.payload;
        },
        set_clc_avatars: (state: DataState, action: { payload: CLCAvatars[] }) => {
            state.clc_avatars = action.payload;
        },
        set_levels: (state: DataState, action: { payload: Level[] }) => {
            state.levels = action.payload;
            state.levels_updated_at = moment().unix();
        },
        set_hidden_discord_message: (state: DataState, action: { payload: boolean }) => {
            state.hidden_discord_message = action.payload;
        }
    },
});

export const {
    set_prize_slice_texts,
    set_current_server_time,
    set_count_daily_question,
    set_clc_avatars,
    set_levels,
    set_hidden_discord_message,
} = dataSlice.actions;
export const dataReducer = dataSlice.reducer;
