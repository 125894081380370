export const API_BACKEND_ENDPOINT = process.env.NX_API_BACKEND ?? ''
export const NX_DOMAIN = process.env.NX_DOMAIN ?? ''
export const NX_MICRO_FRONT_DOMAIN = process.env.NX_MICRO_FRONT_DOMAIN ?? ''
export const TIMEZONE = process.env.NX_TZ ?? 'UTC'
export const CACHE_TIME = parseInt(process.env.NX_CACHE_TIME ?? '-1')
export const NX_DISCORD_INVITE_LINK = process.env.NX_DISCORD_INVITE_LINK ?? ''
export const NX_DISCORD_VIP_ROLE = process.env.NX_DISCORD_VIP_ROLE ?? ''
export const NX_AUTH_REDIRECT_CACHE_TIME = parseInt(process.env.NX_AUTH_REDIRECT_CACHE_TIME ?? '500')
export const NX_AUTH_DOMAIN = process.env.NX_AUTH_DOMAIN ?? ''
export const NX_INTERCOM_ID = process.env.NX_INTERCOM_ID ?? ''
export const NX_PIXEL_ID = process.env.NX_PIXEL_ID ?? ''