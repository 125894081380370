import { LibraryVideoWithTag, dateFormatter } from "@clc-v2/utilities";
import { useAppSelector } from "../../stores/hooks";
import { Author, Tags } from "@clc-v2/uis";
import classNames from "classnames";
import { LibraryAuthor } from "@prisma/client";

export const VideoDetail = (props: {
    active_video: LibraryVideoWithTag;
    authors: LibraryAuthor[];
    className?: string;
}) => {
    const { library_authors, tags } = useAppSelector((state) => state.library);
    return (
        <div className={classNames(
            "grid grid-cols-1 md:grid-cols-2 gap-8",
            props.className,
        )}>
            <div className="col-span-1 flex flex-col">
                <span className="text-secondary-grey text-xs font-light leading-[14px] tracking-[2px] font-inter">{dateFormatter(props.active_video.date)}</span>
                <h3 className="text-white text-2xl font-medium leading-[28px] tracking-[-0.2px] font-inter py-4">{props.active_video.title}</h3>
                <p className="text-white/70 text-lg font-normal leading-[27px]">{props.active_video.description}</p>
            </div>
            <div className="col-span-1 flex flex-col gap-4">
                <span className="text-secondary-grey text-xs font-light leading-[14px] tracking-[2px] uppercase">Hosted By</span>
                <div className="flex items-center gap-2 mb-1">
                    {(props.authors ?? []).map((author) => (
                        <Author key={author.id} author={{
                            given_name: author.first_name.toUpperCase() ?? '',
                            family_name: author.last_name.toUpperCase() ?? '',
                            picture: author.picture ?? '',
                            thumbnail_picture: author.thumbnail_picture ?? '',
                        }} />
                    ))}
                </div>
                <span className="text-secondary-grey text-xs font-light leading-[14px] tracking-[2px] uppercase">Tags</span>
                <Tags
                    tags={(tags ?? []).filter((_tag) => props.active_video.library_video_and_video_tag.map(({ video_tag_id }) => video_tag_id).indexOf(_tag.id) >= 0)}
                    onTagHandler={() => { }}
                />
            </div>
        </div>
    )
}